import { ApartmentRounded, Diversity1Outlined } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClearFiltersButton from '../../common/ClearFilters';
import FilterChipAutocomplete from '../../common/FilterChipAutocomplete';
import FilterFooter from '../../common/FilterFooter';
import StyledIcon from '../../common/StyledIcon';
import { ReactComponent as ActiveInteraction } from '../../common/icons/activeInteraction.svg';
import { ReactComponent as ActiveContract } from '../../common/icons/activeContract.svg';
import { ReactComponent as Teams } from '../../common/icons/teams.svg';
import { appSlice } from '../../store/appSlice';
import { diversityLabels } from '../../utils/constants';

const SupplierFilters = () => {
  const dispatch = useDispatch();
  const [industries, setIndustries] = useState([]);

  const { filtersSuppliers, supplierIndustries, procurementFilterData } =
    useSelector((state) => state.appSlice);
  const { setFiltersSuppliers } = appSlice.actions;

  useEffect(() => {
    const sortedIndustries = [...supplierIndustries].sort((a, b) =>
      a.localeCompare(b),
    );
    setIndustries(
      sortedIndustries.map((industry) => ({
        label: industry,
        value: industry,
      })),
    );
  }, [supplierIndustries]);

  const handleFilters = (newFilters) => {
    dispatch(setFiltersSuppliers(newFilters));
  };

  const handleIndustriesChange = (newValue) => {
    handleFilters({
      ...filtersSuppliers,
      industries: newValue.map((item) => (item?.value ? item.value : item)),
    });
  };

  const handleDiversitySpotlightsChange = (newValue) => {
    handleFilters({
      ...filtersSuppliers,
      diversity_spotlights: newValue.map((item) =>
        item?.value ? item.value : item,
      ),
    });
  };

  const handleProcurementTeamsChange = (newValue) => {
    handleFilters({
      ...filtersSuppliers,
      department_ids: newValue.map((item) => (item?.value ? item.value : item)),
    });
  };

  return (
    <Box
      sx={{
        maxHeight: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '42px',
        gap: '24px',
        border: '1px solid #DEDBEC',
        borderRadius: '12px',
        margin: '24px 0 32px 24px',
        padding: '16px',
        background: 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          paddingBottom: '24px',
          borderBottom: '1px solid #79747E29',
        }}
      >
        <Box
          color={(theme) => theme.palette.text.main}
          fontSize={(theme) => theme.typography.h6}
        >
          Filters
        </Box>
        {Boolean(industries && industries.length) && (
          <Box
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'stretch',
                gap: '8px',
              }}
            >
              <Box
                fontSize={(theme) => theme.typography.body1}
                color={(theme) => theme.palette.text.medium}
                paddingBottom={'8px'}
              >
                Industries
              </Box>
              <StyledIcon component={ApartmentRounded} color="icon" />
            </Box>
            <Box>
              <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                <FilterChipAutocomplete
                  options={industries}
                  label="Industries"
                  placeholder="Industries"
                  onSelectionChange={handleIndustriesChange}
                  procurementMembers={filtersSuppliers.industries}
                  showInitials={false}
                  restrictValues
                />
              </Box>
            </Box>
          </Box>
        )}
        <Box
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'stretch',
              gap: '8px',
            }}
          >
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.medium}
              paddingBottom={'8px'}
            >
              Diversity spotlight
            </Box>
            <StyledIcon component={Diversity1Outlined} color="icon" />
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
              <FilterChipAutocomplete
                options={diversityLabels}
                label="Diversity spotlight"
                placeholder="Diversity spotlight"
                onSelectionChange={handleDiversitySpotlightsChange}
                procurementMembers={filtersSuppliers.diversity_spotlights}
                showInitials={false}
                restrictValues
              />
            </Box>
          </Box>
        </Box>
        <Box
        >
          <Stack
            marginBottom={'2px'}
            direction={'row'}
            spacing={'6px'}
            alignItems={'center'}
          >
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.medium}
            >
              Active in last 7 days
            </Box>
            <StyledIcon component={ActiveInteraction} color="icon_success" />
          </Stack>
          <Box>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={filtersSuppliers.is_active || null}
                onChange={(event) =>
                  handleFilters({
                    ...filtersSuppliers,
                    is_active: event.target.value,
                  })
                }
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio color={'secondary'} />}
                  label="Yes"
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio color={'secondary'} />}
                  label="No"
                />{' '}
                <FormControlLabel
                  value={'null'}
                  control={<Radio color={'secondary'} />}
                  label={'Both'}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box
        >
          <Stack
            marginBottom={'2px'}
            direction={'row'}
            spacing={'6px'}
            alignItems={'center'}
          >
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.medium}
            >
              Active contract
            </Box>
            <StyledIcon component={ActiveContract} color="icon_success" />
          </Stack>
          <Box>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={filtersSuppliers.is_active_contract || 'null'}
                onChange={(event) =>
                  handleFilters({
                    ...filtersSuppliers,
                    is_active_contract: event.target.value,
                  })
                }
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio color={'secondary'} />}
                  label="Yes"
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio color={'secondary'} />}
                  label="No"
                />{' '}
                <FormControlLabel
                  value={'null'}
                  control={<Radio color={'secondary'} />}
                  label={'Both'}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        {Boolean(
          procurementFilterData?.departments &&
            procurementFilterData?.departments.length,
        ) && (
          <Box
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'stretch',
                gap: '8px',
              }}
            >
              <Box
                fontSize={(theme) => theme.typography.body1}
                color={(theme) => theme.palette.text.medium}
                paddingBottom={'8px'}
              >
                Teams
              </Box>
              <Box>
                <Teams />
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                <FilterChipAutocomplete
                  options={procurementFilterData?.departments}
                  label="Team"
                  placeholder="Team"
                  onSelectionChange={handleProcurementTeamsChange}
                  procurementMembers={filtersSuppliers.department_ids}
                  showInitials={false}
                  restrictValues
                />
              </Box>
            </Box>
          </Box>
        )}
        <Box>
          <ClearFiltersButton
            type={'big'}
            variant={'filtersSuppliers'}
          ></ClearFiltersButton>
        </Box>
      </Box>
      <FilterFooter />
    </Box>
  );
};

export default SupplierFilters;
