import React, { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { appSlice } from "../../store/appSlice";
import { updateFiltersInteractions } from "../../store/localStorage";

import CustomCard from "../../common/CustomCard";
import EngagementStageBlock from "../../common/EngagementStageBlock";
import InfoMessage from "../../common/InfoMessage";
import { stageNames, maxStageInteractions, stageNumRows } from "../../utils/constants";
import { createPipelineFilterView } from "../../utils/functions";

const MAX_INTERACTIONS = maxStageInteractions;
const STAGE_NUM_ROWS = stageNumRows;

const EngagementStage = () => {
  const dispatch = useDispatch();
  const { interactionsOverview, filtersOverview } = useSelector(
    (state) => state.appSlice
  );

  const { setPage, setFiltersInteractions } = appSlice.actions;

  const engagementStageRef = useRef(null);

  const [stage1Width, setStage1Width] = useState(33);
  const [stage2Width, setStage2Width] = useState(33);
  const [stage3Width, setStage3Width] = useState(33);

  const [engagementStage, setEngagementStage] = useState({
    exploration: {
      items: [],
    },
    negotiation: {
      items: [],
    },
    closing: {
      items: [],
    },
  });

  const calculateColumnSizes = (itemsLen1, itemsLen2, itemsLen3) => {
    const cols1 = Math.ceil(itemsLen1 / STAGE_NUM_ROWS);
    const cols2 = Math.ceil(itemsLen2 / STAGE_NUM_ROWS);
    const cols3 = Math.ceil(itemsLen3 / STAGE_NUM_ROWS);

    const totalCols = cols1 + cols2 + cols3;

    const width1 = Math.floor((cols1 / totalCols) * 100);
    const width2 = Math.floor((cols2 / totalCols) * 100);
    const width3 = Math.floor((cols3 / totalCols) * 100);

    return [width1, width2, width3];
  };

  useEffect(() => {
    let interactions = interactionsOverview
      .slice()
      .filter((item) => item?.stage_name);

    const { explorationItems, negotiationItems, closingItems } =
      createPipelineFilterView(interactions);

    setEngagementStage({
      exploration: {
        items: explorationItems,
      },
      negotiation: {
        items: negotiationItems,
      },
      closing: {
        items: closingItems,
      },
    });

    const [w1, w2, w3] = calculateColumnSizes(
      explorationItems.length,
      negotiationItems.length,
      closingItems.length
    );

    setStage1Width(w1);
    setStage2Width(w2);
    setStage3Width(w3);
  }, [interactionsOverview]);

  const emptyStages = () => {
    let count = 0;

    if (engagementStage.negotiation.items.length === 0) {
      count++;
    }

    if (engagementStage.closing.items.length === 0) {
      count++;
    }

    if (engagementStage.exploration.items.length === 0) {
      count++;
    }

    return count;
  }

  let minWidth = '25px';
  if (emptyStages() > 2) {
    minWidth = '33%';
  }
  else if (emptyStages() > 1) {
    minWidth = '120px';

  }

  return (
    <>
      <CustomCard>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 16px",
          }}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            fontSize={(theme) => theme.typography.h6}
          >
            Pipeline
            <InfoMessage
              message={
                <Stack
                  direction={"column"}
                  justifyContent={"start"}
                  alignItems={"start"}
                  gap={"20px"}
                >
                  <Box>
                    Use your pipeline of prospective deals to prioritize
                    your engagement.
                  </Box>
                  <Box>
                    Pipeline stages are assigned by keywords and language
                    pattern recognition.
                  </Box>
                  <Box>
                    <Box>
                      <Box component={"span"} sx={{ fontWeight: "bold" }}>
                        Exploration
                      </Box>
                      : Employees or teams have asked suppliers for product or
                      service information.
                    </Box>
                    <Box>
                      <Box component={"span"} sx={{ fontWeight: "bold" }}>
                        Negotiation
                      </Box>
                      : An employee or team is discussing pricing or service
                      agreements.
                    </Box>
                    <Box>
                      <Box component={"span"} sx={{ fontWeight: "bold" }}>
                        Closing
                      </Box>
                      : A deal for products or services is being finalized.
                    </Box>
                  </Box>
                </Stack>
              }
            />
          </Box>
          <Stack
            direction={"row"}
            justifyContent={"start"}
            alignItems={"center"}
          >
            <InfoMessage
              positionVariant={"bottom-right"}
              message={
                `Recommended interactions are evaluated and ranked according to date,
                message frequency, employees involved, and deal progression.`
              }
            />
            <Box>
              <Box
                component={"span"}
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.subtitle1}
              >
                Top {
                  engagementStage.exploration.items.length +
                  engagementStage.negotiation.items.length +
                  engagementStage.closing.items.length
                }{" "}
              </Box>
              <Box
                fontSize={(theme) => theme.typography.body1}
                component={"span"}
                color={(theme) => theme.palette.text.medium}
              >
                out of{" "}
              </Box>
              <Box
                onClick={() => {
                  updateFiltersInteractions(filtersOverview);
                  dispatch(setFiltersInteractions(filtersOverview));
                  dispatch(setPage("interactions"));
                }}
                component={"span"}
                sx={{ cursor: "pointer" }}
                fontSize={(theme) => theme.typography.subtitle1}
                color={(theme) => theme.palette.secondary.main}
              >
                {interactionsOverview.length}
              </Box>
            </Box>
          </Stack>
        </Box>
        <Box sx={{ padding: '0px 10px 4px 4px'}}>
          <Box
            sx={{
              borderRadius: "8px",
              display: "flex",
              width: "100%",
              minHeight: "358px",
              paddingLeft: "4px",
            }}
            ref={engagementStageRef}
          >
            <EngagementStageBlock
              isShowEmpty={Boolean(
                !interactionsOverview || interactionsOverview.length === 0
              )}
              items={engagementStage.exploration.items}
              totalItems={
                interactionsOverview.filter((item) => item?.stage_name).length
              }
              engagementStage={engagementStage}
              engagementStageRef={engagementStageRef}
              variant={stageNames[0].label}
              colWidth={stage1Width}
              minWidth={minWidth}
            />
            <EngagementStageBlock
              isShowEmpty={Boolean(
                !interactionsOverview || interactionsOverview.length === 0
              )}
              items={engagementStage.negotiation.items}
              totalItems={
                interactionsOverview.filter((item) => item?.stage_name).length
              }
              engagementStage={engagementStage}
              engagementStageRef={engagementStageRef}
              variant={stageNames[1].label}
              colWidth={stage2Width}
              minWidth={minWidth}
            />
            <EngagementStageBlock
              isShowEmpty={Boolean(
                !interactionsOverview || interactionsOverview.length === 0
              )}
              items={engagementStage.closing.items}
              totalItems={
                interactionsOverview.filter((item) => item?.stage_name).length
              }
              engagementStage={engagementStage}
              engagementStageRef={engagementStageRef}
              variant={stageNames[2].label}
              colWidth={stage3Width}
              minWidth={minWidth}
            />
          </Box>
        </Box>
      </CustomCard>
    </>
  );
};

export default EngagementStage;
