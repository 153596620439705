import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { TreeItem, TreeView } from "@mui/lab";
import { Checkbox, Box } from "@mui/material";
import { TaskRounded } from "@mui/icons-material";
import StyledIcon from "../../common/StyledIcon";

const getAllChildrenIds = (obj) => {
  let ids = [];

  if (obj.id) {
    ids.push(obj.id);
  }

  if (obj.children && obj.children.length > 0) {
    obj.children.forEach((child) => {
      ids = ids.concat(getAllChildrenIds(child));
    });
  }

  return ids;
};

const isCheckedFunc = (array1, array2) => {
  const hasCommonElement = array1.some((item) => array2.includes(item));
  const allElementsInArray2 = array1.every((item) => array2.includes(item));

  if (hasCommonElement) {
    return allElementsInArray2 ? "all" : "some";
  } else {
    return "clear";
  }
};

const buildTree = (data) => {
  const tree = [];
  const map = {};

  data.forEach((node) => {
    map[node.id] = {
      ...node,
      children: [],
      expanded: false,
    };
  });

  data.forEach((node) => {
    const parent = map[node.parent_id];
    if (parent) {
      parent.children.push(map[node.id]);
    } else {
      tree.push(map[node.id]);
    }
  });

  return tree;
};

const CheckboxTree = ({
  data,
  handleChange,
  target_department_ids,
  disabled,
}) => {
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    setTreeData(buildTree(data));
  }, [data]);

  const handleCheck = (event, nodeIds) => {
    handleChange(event, nodeIds);
    setTreeData((prevData) => {
      const updatedData = [...prevData];

      const updateCheckedState = (nodes) => {
        nodes.forEach((node) => {
          if (nodeIds.includes(node.id)) {
            node.checked = event.target.checked;
          }
          if (node.children && node.children.length > 0) {
            updateCheckedState(node.children);
          }
        });
      };

      updateCheckedState(updatedData);

      return updatedData;
    });
  };

  const handleToggle = (event, nodeId) => {
    setTreeData((prevData) => {
      const updatedData = [...prevData];

      const updateExpandedState = (nodes) => {
        nodes.forEach((node) => {
          if (node.id === nodeId) {
            node.expanded = !node.expanded;
          }
          if (node.children && node.children.length > 0) {
            updateExpandedState(node.children);
          }
        });
      };

      updateExpandedState(updatedData);

      return updatedData;
    });
  };

  const renderTreeItems = (nodes, isMarginLeft) =>
    nodes.map((node) => {
      const hasChildren = node.children && node.children.length > 0;

      return (
        <TreeItem
          onClick={(event) => handleToggle(event, node.id)}
          key={node.id}
          sx={{
            marginLeft: isMarginLeft && "7px", // Set marginLeft for children
            borderBottom: !isMarginLeft && "1.5px solid #E5E5E5",
            ":last-of-type": {
              borderBottom: "none",
            },

            "& .MuiTreeItem-content.Mui-selected": {
              backgroundColor: "transparent",
            },
            "& .MuiTreeItem-content.Mui-selected.Mui-focused": {
              backgroundColor: "transparent",
            },
            "& .MuiTreeItem-content.Mui-selected:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
            "& .MuiTreeItem-content": {
              padding: "0",
            },
            "& .MuiTreeItem-label": {
              padding: "0 !important",
            },
            "& .MuiTreeItem-iconContainer": {
              display: "none !important",
            },
          }}
          nodeId={node.id}
          label={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  {hasChildren && node.expanded && (
                    <Box
                      component="div"
                      sx={{
                        position: "absolute",
                        top: "calc(100% - 12px)",
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: 2,
                        height: `${node?.children?.length * 27}px`,
                        backgroundColor: "#E5E5E5",
                      }}
                    />
                  )}
                  {isMarginLeft && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "calc(50% - 17px)",
                        left: "calc(-50% + 13px)",
                        height: "17px",
                        width: "16px",
                        borderBottomLeftRadius: "16px",
                        borderLeft: "2px solid #E5E5E5",
                        borderBottom: "2px solid #E5E5E5",
                        float: "left",
                      }}
                    ></Box>
                  )}
                  <Checkbox
                    disabled={disabled}
                    size={"small"}
                    checked={
                      hasChildren
                        ? isCheckedFunc(
                            getAllChildrenIds(node),
                            target_department_ids
                          ) === "all"
                        : !!target_department_ids.includes(node.id)
                    }
                    indeterminate={
                      hasChildren &&
                      isCheckedFunc(
                        getAllChildrenIds(node),
                        target_department_ids
                      ) === "some"
                    }
                    onChange={(event) =>
                      handleCheck(event, getAllChildrenIds(node))
                    }
                    color="secondary"
                  />
                </Box>
                <span>{node.name}</span>
              </Box>
              {hasChildren && (
                <Box
                  component="div"
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {node.expanded ? (
                    <StyledIcon
                      component={KeyboardArrowUpRoundedIcon}
                      color={"icon"}
                    />
                  ) : (
                    <StyledIcon component={ExpandMoreIcon} color={"icon"} />
                  )}
                </Box>
              )}
            </Box>
          }
          expanded={node.expanded ? "true" : undefined}
          icon={null}
        >
          {renderTreeItems(node.children, true)}
        </TreeItem>
      );
    });

  return (
    <TreeView
      defaultCollapseIcon={
        <KeyboardArrowUpRoundedIcon sx={{ display: "none" }} />
      }
      defaultExpandIcon={<ExpandMoreIcon sx={{ display: "none" }} />}
    >
      {renderTreeItems(treeData)}
    </TreeView>
  );
};

export default CheckboxTree;
