import React, { useEffect } from "react";
import { Box, Button, IconButton, Popover, Stack } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import StyledIcon from "./StyledIcon";

const PopupMessage = ({ open, message, close }) => {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        close();
      }, 10000);
    }
  }, [open]);

  if (!open) {
    return <></>;
  }

  return (
    <Popover
      open={open}
      onClose={close}
      disableScrollLock={true}
      sx={{ pointerEvents: "none" }}
      anchorPosition={{
        left: 24,
        top: window.innerHeight - 24,
      }}
      PaperProps={{
        sx: {
          width: "456px",
          padding: "8px 16px",
          borderRadius: "4px",
          pointerEvents: "all",
        },
      }}
      anchorReference={"anchorPosition"}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
      >
        <Box
          color={(theme) => theme.palette.text.main}
          fontSize={(theme) => theme.typography.body2}
        >
          {message.message}
        </Box>
        <Stack direction="row" alignItems="center" spacing={4}>
          {message.isShowUndo && (
            <Box>
              <Button
                onClick={message.undoFunc}
                variant={"text"}
                color={"secondary"}
                sx={{ fontSize: "13px", fontWeight: "500" }}
              >
                Undo
              </Button>
            </Box>
          )}
          <Box>
            <IconButton sx={{ padding: "8px" }} onClick={close}>
              <StyledIcon
                component={CloseRounded}
                color="icon"
                sx={{ fontSize: 20 }}
              />
            </IconButton>
          </Box>
        </Stack>
      </Stack>
    </Popover>
  );
};

export default PopupMessage;
