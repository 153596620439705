import {
  ArrowBackIosRounded,
  CloseRounded,
  Grading,
} from '@mui/icons-material';
import { Box, Chip, IconButton, Skeleton, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { appSlice } from '../../store/appSlice';

import { stageVariant } from '../../common/CustomChip';
import { getBundleData } from '../../common/DataHelper';
import BackofficeDetailsNavigation from '../../common/BackofficeDetailsNavigation';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import Logo from '../../common/Logo';
import ReviewButtonGroup from '../../common/ReviewBtnGroup';
import StyledIcon from '../../common/StyledIcon';
import BundleMessage from './BundleMessage';

const BundleMessages = ({ close, prevLocation, hideDetailsNav }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { bundleIdsBackoffice } = useSelector((state) => state.appSlice);

  const [interaction, setInteraction] = useState({});
  const [bundle, setBundle] = useState({});
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reviewStatus, setReviewStatus] = useState('New');
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [fetchingMore, setFetchingMore] = useState(false);

  const observerRef = useRef();

  useEffect(() => {
    if (isLoading || fetchingMore || !hasMore) return;
    const targetElement = observerRef.current;
    if (!targetElement) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries.length && entries[0].isIntersecting && !fetchingMore)
        setPage((prevPage) => prevPage + 1);
    });

    observer.observe(targetElement);

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, [isLoading, observerRef.current]);

  const fetchData = async () => {
    try {
      const bundleData = await getBundleData(id);
      if (bundleData) {
        setBundle(bundleData.bundle);

        if (bundleData.bundle?.interaction) {
          setInteraction(bundleData.bundle.interaction);
        }

        if (bundleData.bundle?.supplier_manual !== null) {
          setReviewStatus('Reviewed');
        } else {
          setReviewStatus('New');
        }
      }
      await fetchMessages(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessages([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMessages = async (reset) => {
    const data = await getData(reset);
    if (data && data?.messages && Array.isArray(data.messages)) {
      const messagesArray = data.messages.filter((item) => Boolean(item.body));
      setHasMore(data?.meta?.has_more || false);
      setMessages(reset ? messagesArray : [...messages, ...messagesArray]);
    } else {
      setMessages([...messages]);
      setHasMore(false);
    }
  };

  useEffect(() => {
    setPage(0);
    setIsLoading(true);
    setMessages([]);
    fetchData().then(() => setIsLoading(false));
  }, [id]);

  useEffect(() => {
    if (page > 0 && hasMore && !fetchingMore) {
      setFetchingMore(true);
      fetchMessages().then(() => setFetchingMore(false));
    }
  }, [page]);

  const navigateBack = () => {
    if (prevLocation && prevLocation?.pathname) {
      navigate(prevLocation.pathname);
    } else {
      navigate('/backoffice');
    }
  };

  const getData = async (reset) => {
    const response = await fetch(
      `${
        process.env.REACT_APP_BACKOFFICE_BASE_URL
      }/bundles/${id}/messages?page=${reset ? 0 : page}&page_size=10`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const handleReviewUpdate = (supplierManualValue) => {
    setReviewStatus('Reviewed');
    setBundle((prevBundle) => ({
      ...prevBundle,
      supplier_manual: supplierManualValue,
    }));
    dispatch(
      appSlice.actions.updateReviewStatus({
        bundleId: id,
        isSupplierManual: supplierManualValue,
      }),
    );
  };

  if (isLoading && !interaction) {
    return <LoadingSkeleton variant={'interactionDetails'} close={close} />;
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '720px',
          height: 'calc(100vh - 88px)',
          overflowY: 'auto',
          padding: '16px 24px 72px 24px',
          background: 'white',
          '@media (max-width: 768px)': {
            width: 'calc(100vw - 48px)',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1.5px solid #694FA31A',
            paddingBottom: '20px',
            marginBottom: '24px',
          }}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.subtitle1}
          >
            <IconButton
              type="button"
              sx={{ padding: '0', marginRight: '8px' }}
              onClick={() => navigateBack()}
            >
              <StyledIcon component={ArrowBackIosRounded} color={'icon'} />
            </IconButton>
            Messages
          </Box>
          {!hideDetailsNav && (
            <Box display={'flex'} alignItems={'center'}>
              <BackofficeDetailsNavigation
                itemId={Number(id)}
                idArray={bundleIdsBackoffice}
                navigationLink={'/backoffice/details/messages/'}
              />
              <Box>
                <IconButton
                  type="button"
                  sx={{ padding: '8px' }}
                  onClick={close}
                >
                  <CloseRounded sx={{ color: '#2F236D73', fontSize: 24 }} />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '16px',
          }}
        >
          <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
            <Logo size={44} img={interaction.supplier_image_url} />
            <Box>
              <Box
                onClick={() => {
                  if (interaction?.supplier_id)
                    navigate(
                      `/backoffice/details/supplier/${interaction?.supplier_id}`,
                    );
                }}
                sx={{
                  lineHeight: '160%',
                  cursor: 'pointer',
                }}
                color={(theme) => theme.palette.secondary.main}
                fontSize={(theme) => theme.typography.h6}
              >
                {interaction?.supplier_name
                  ? interaction?.supplier_name
                  : bundle?.external_domain}
              </Box>
              {interaction?.uid && (
                <Box
                  fontSize={(theme) => theme.typography.caption}
                  color={(theme) => theme.palette.text.medium}
                >
                  ID: {interaction?.uid}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            borderBottom: '1.5px solid #694FA31A',
            paddingBottom: '16px',
            marginBottom: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '6px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Stack flexDirection={'row'} gap={'5px'}>
              <Box
                color={(theme) => theme.palette.text.medium}
                fontSize={(theme) => theme.typography.body1}
                sx={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <StyledIcon component={Grading} color={'icon'} />
              </Box>
              <Chip
                label={reviewStatus}
                size={'small'}
                sx={{
                  padding: '4px 10px',
                  span: { padding: 0 },
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  ...stageVariant(reviewStatus),
                }}
              />
            </Stack>

            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-end'}
            >
              <Box marginBottom={'8px'}>Prospective supplier</Box>

              <ReviewButtonGroup
                reviewStatus={bundle?.supplier_manual}
                id={id}
                onReviewUpdate={handleReviewUpdate}
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            height: '100%',
            border: '1px solid',
            borderRadius: '4px',
            padding: '16.5px 14px',
            overflowY: 'auto',
          }}
        >
          {!isLoading && messages.length > 0 && (
            <Box display={'flex'} gap={'24px'} flexDirection={'column'}>
              {messages.map((message, index) => (
                <BundleMessage key={message.id} message={message} />
              ))}
              {hasMore && !fetchingMore && <div ref={observerRef}></div>}
              {fetchingMore && <Box>Loading more...</Box>}
            </Box>
          )}

          {!isLoading && messages.length === 0 && (
            <Box display="flex" justifyContent="center" alignItems="center">
              No messages to show
            </Box>
          )}

          {isLoading && (
            <>
              <Skeleton variant={'text'} width={'100%'} />
              <br />
              <Skeleton variant={'text'} width={'100%'} />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BundleMessages;
