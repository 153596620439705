import React from 'react';
import moment from 'moment/moment';

import StyledIcon from '../../common/StyledIcon';

import { Box, Stack } from '@mui/material';
import {
  PersonOutlineRounded,
  CheckCircleRounded,
  ViewTimelineOutlined,
  ChangeCircleRounded,
  VisibilityOffRounded,
  LabelOutlined,
  TaskRounded,
} from '@mui/icons-material';

const InteractionLogs = ({ interaction }) => {
  const iconLogVariant = (type) => {
    switch (type) {
      case 'detected':
        return <StyledIcon component={TaskRounded} color={'icon'} />;
      case 'stage_changed':
        return <StyledIcon component={ViewTimelineOutlined} color={'icon'} />;
      case 'procurement_involved':
        return (
          <StyledIcon component={CheckCircleRounded} color={'icon_success'} />
        );
      case 'undo_procurement_involved':
        return <StyledIcon component={CheckCircleRounded} color={'icon'} />;
      case 'deal_started':
        return <StyledIcon component={TaskRounded} color={'icon'} />;
      case 'savings_changed':
        return <StyledIcon component={TaskRounded} color={'icon'} />;
      case 'deal_closed':
        return <StyledIcon component={TaskRounded} color={'icon'} />;
      case 'undo_deal_closed':
        return <StyledIcon component={TaskRounded} color={'icon'} />;
      case 'internal_stakeholder_detected':
        return <StyledIcon component={TaskRounded} color={'icon'} />;
      case 'external_stakeholder_detected':
        return <StyledIcon component={TaskRounded} color={'icon'} />;
      case 'keyword_detected':
        return <StyledIcon component={LabelOutlined} color={'icon'} />;
      case 'deprioritized':
        return <StyledIcon component={VisibilityOffRounded} color={'icon'} />;
      case 'undo_deprioritized':
        return <StyledIcon component={VisibilityOffRounded} color={'icon'} />;
      case 'renewal':
        return (
          <StyledIcon component={ChangeCircleRounded} color={'icon_warning'} />
        );
      case 'undo_renewal':
        return <StyledIcon component={ChangeCircleRounded} color={'icon'} />;
      default:
        return <StyledIcon component={TaskRounded} color={'icon'} />;
    }
  };

  const matchDate = (text) => {
    // Regular expression to match the date format
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/;

    // Test if the date string matches the regular expression
    return dateRegex.test(text);
  };

  const formatText = (text) => {
    let parts = [];
    let match;

    while ((match = /\*(.*?)\*/g.exec(text)) !== null) {
      parts.push(text.slice(0, match.index));
      parts.push(
        <Box component={'span'} sx={{ fontWeight: '500' }} key={match.index}>
          {matchDate(match[1])
            ? moment(new Date(match[1])).format('MMM DD, YYYY')
            : match[1]}
        </Box>,
      );
      text = text.slice(match.index + match[0].length);
    }

    if (text) {
      parts.push(text);
    }

    return parts;
  };

  return (
    <Box
      sx={{
        border: '1px solid #694FA31A',
        borderRadius: '12px',
        marginBottom: '24px',
        width: '100%',
      }}
    >
      <Box
        color={(theme) => theme.palette.text.main}
        fontSize={(theme) => theme.typography.h6}
        sx={{ padding: '16px',  borderBottom: '1px solid rgba(188, 184, 197, 0.2)'}}
      >
        Interaction history
      </Box>
      <Box sx={{ padding: '16px', paddingBottom: 0 }}>
        {interaction?.logs?.map((log, index) => (
          <Stack key={log.id} direction={'row'} gap={'16px'} width={'100%'}>
            <Stack
              direction={'column'}
              alignItems={'center'}
              justifyContent={'start'}
            >
              {iconLogVariant(log?.log_type)}
              {index < interaction?.logs?.length - 1 && (
                <Box
                  sx={{
                    width: '2px',
                    height: '100%',
                    background: '#DEDBEC',
                  }}
                />
              )}
            </Stack>
            <Box
              width={'100%'}
              sx={{
                borderBottom:
                  index < interaction?.logs?.length - 1 &&
                  '1px solid rgba(188, 184, 197, 0.2)',
                marginBottom: '16px',
                paddingBottom: '16px',
              }}
            >
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Box
                  color={(theme) => theme.palette.text.main}
                  fontSize={(theme) => theme.typography.body1}
                  sx={{ maxWidth: '80%' }}
                >
                  {formatText(log?.text)}
                </Box>
                <Box
                  color={(theme) => theme.palette.text.medium}
                  fontSize={(theme) => theme.typography.body2}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {moment(log?.created_at).format('MMMM DD, HH:mm')}
                </Box>
              </Stack>
              <Stack
                direction={'row'}
                sx={{ color: '#6558B173' }}
                alignItems={'center'}
              >
                {log?.issued_by === 'Sync' ? (
                  <Box
                    color={(theme) => theme.palette.text.medium}
                    fontSize={(theme) => theme.typography.body2}
                  >
                    {log?.issued_by}
                  </Box>
                ) : (
                  <>
                    <StyledIcon
                      component={PersonOutlineRounded}
                      sx={{ fontSize: 20 }}
                    />
                    <Box
                      color={(theme) => theme.palette.text.medium}
                      fontSize={(theme) => theme.typography.body2}
                    >
                      by {log?.issued_by}
                    </Box>
                  </>
                )}
              </Stack>
            </Box>
          </Stack>
        ))}
      </Box>
    </Box>
  );
};

export default InteractionLogs;
