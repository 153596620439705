import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appSlice from "./appSlice";
import userLoginMiddleware from "./userLoginListener";

const rootReducer = combineReducers({
  appSlice,
});
export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(userLoginMiddleware.middleware),
  });
};
