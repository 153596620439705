import { Box, Table, TableBody } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReportTable from './ReportTable';
import {
  calculateCrossTeamSuppliersColumns,
  calculatedDuplicateSuppliersColumns,
  CROSS_TEAM_VENDORS_HEADERS,
  DUPLICATE_VENDORS_HEADERS,
  generateQueryParams,
} from './reportHelpers';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import { encodeQuery } from '../../store/storeFunctions';
import { FetchWithCancel } from '../../common/FetchWithCancel';

const SupplierTrends = ({ filters }) => {
  const [crossTeamSuppliers, setCrossTeamSuppliers] = useState([]);
  const [duplicateSuppliers, setDuplicateSuppliers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const crossTeamTableOptions = {
    type: 'supplier',
    includeBorder: false,
    includeHeader: false,
    modal: true,
    plural: 'suppliers',
    arrayValue: ['vendor_name_unique', 'department_name_unique'],
  };

  const duplicateSupplierTableOptions = {
    type: 'duplicate',
    includeBorder: false,
    includeHeader: false,
    plural: 'duplicates',
    modal: true,
  };

  const getStats = (reportType) => {
    const query = generateQueryParams(filters);

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/report/${reportType}`;
    const queryString = encodeQuery(query);
    const urlWithQuery = `${apiUrl}?${queryString}`;
    return FetchWithCancel(urlWithQuery);
  };

  useEffect(() => {
    setIsLoading(true);
    const { fetchPromise: sharedSupplierFetch, cancel: sharedSupplierCancel } =
      getStats('shared_supplier');
    const {
      fetchPromise: duplicateSupplierFetch,
      cancel: duplicateSupplierCancel,
    } = getStats('duplicate_supplier');

    sharedSupplierFetch.then((crossTeamData) => {
      if (crossTeamData !== 'error') {
        const collection = calculateCrossTeamSuppliersColumns(
          crossTeamData?.report || [],
        );
        setCrossTeamSuppliers(collection);
      } else {
        setCrossTeamSuppliers([]);
      }
      setIsLoading(false);
    });

    duplicateSupplierFetch.then((duplicateSupplierData) => {
      if (duplicateSupplierData !== 'error') {
        const collection = calculatedDuplicateSuppliersColumns(
          duplicateSupplierData?.report || [],
        );
        setDuplicateSuppliers(collection);
      } else {
        setDuplicateSuppliers([]);
      }
    });

    return () => {
      sharedSupplierCancel && sharedSupplierCancel();
      duplicateSupplierCancel && duplicateSupplierCancel();
    };
  }, [filters]);

  if (isLoading)
    return (
      <Table>
        <TableBody>
          <LoadingSkeleton variant={'adminLogs'} />
        </TableBody>
      </Table>
    );

  return (
    <Box>
      <Box
        sx={{
          border: '1.5px solid #694FA31A',
          borderRadius: '12px',
          marginBottom: '24px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            borderBottom: '1.5px solid #694FA31A',
          }}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
            paddingBottom={'4px'}
          >
            Cross-team suppliers
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.medium}
            >
              {`There are ${crossTeamSuppliers?.length} suppliers that are used by other teams.`}
            </Box>
          </Box>
        </Box>
        <ReportTable
          tableHeaders={CROSS_TEAM_VENDORS_HEADERS}
          collection={crossTeamSuppliers}
          options={crossTeamTableOptions}
        />
      </Box>
      <Box
        sx={{
          border: '1.5px solid #694FA31A',
          borderRadius: '12px',
          marginBottom: '24px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            borderBottom: '1.5px solid #694FA31A',
          }}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
            paddingBottom={'4px'}
          >
            Potential duplicates
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.medium}
            >
              {`There are ${duplicateSuppliers?.length} suppliers used under different names. Referencing an existing supplier will save up to 3 days of new vendor setup.`}
            </Box>
          </Box>
        </Box>
        <ReportTable
          tableHeaders={DUPLICATE_VENDORS_HEADERS}
          collection={duplicateSuppliers}
          options={duplicateSupplierTableOptions}
        />
      </Box>
    </Box>
  );
};

export default SupplierTrends;
