import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, InputBase, Stack, Tab, Tabs } from '@mui/material';
import {
  CloseRounded,
  HelpOutlineRounded,
  SettingsOutlined,
  SearchRounded,
} from '@mui/icons-material';
import StyledIcon from '../common/StyledIcon';
import { LoginButton, LogoutButton } from './auth/login';
import { appSlice } from '../store/appSlice';
import useDebounce from '../hooks/useDebounce';
import logo from '../logo.svg';
import {
  hasReports,
  isBackofficePage,
  isInteractionsPage,
  isOutreachPage,
  isProductionDomain,
  isReportsPage,
  isSettingsOrHelpPage,
  isSuppliersPage,
} from '../utils/functions';

const Header = ({ handleOnClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { page, search, user } = useSelector((state) => state.appSlice);
  const { setPage, setPreviousSearch, setSearch } = appSlice.actions;

  const [searchValue, setSearchValue] = useState('');

  const debounce = useDebounce(searchValue, 500);

  useEffect(() => {
    dispatch(setSearch(debounce));
  }, [debounce]);

  useEffect(() => {
    setSearchValue(search);
    search && handleOnClick && handleOnClick();
  }, [search]);

  useEffect(() => {
    if (isSettingsOrHelpPage(location)) {
      dispatch(setPage(false));
    }

    if (isBackofficePage(location)) {
      dispatch(setPage('backoffice'));
    }

    if (isInteractionsPage(location)) {
      dispatch(setPage('interactions'));
    }

    if (isSuppliersPage(location)) {
      dispatch(setPage('suppliers'));
    }

    if (isOutreachPage(location)) {
      dispatch(setPage('outreach'));
    }

    if (isReportsPage(location)) {
      dispatch(setPage('reports'));
    }
  }, []);

  const handleChangePage = (event, newValue) => {
    dispatch(setSearch(''));
    dispatch(setPreviousSearch(''));
    dispatch(setPage(newValue));
    if (newValue === 'overview') navigate('/');
    else navigate(`/${newValue}`);
    window.scrollTo(0, 0);
    handleOnClick && handleOnClick(false, true);
  };

  return (
    <Box
      sx={{
        background: 'white',
        display: 'flex',
        minWidth: 'calc(100vw - 17px)',
        width: '100%',
        height: '60px',
        justifyContent: 'space-between',
        borderBottom: '1px solid #DEDBEC',
      }}
    >
      <Stack gap={'24px'} direction={'row'}>
        <Box
          onClick={() => {
            handleChangePage('', 'overview');
          }}
          sx={{
            cursor: 'pointer',
            padding: '16px 96px 16px 40px',
          }}
        >
          {' '}
          <img src={logo} alt={'Logo'} />
        </Box>
        <Box
          color={(theme) => theme.palette.secondary}
          sx={{
            width: 'calc(100vw - 896px)',
            height: '100%',
            display: 'flex',
            alignItems: 'end',
            marginLeft: '-40px',
          }}
        >
          <Tabs
            value={search || isSettingsOrHelpPage(location) ? false : page}
            textColor={'inherit'}
            sx={{ height: '100%' }}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              sx: {
                color: 'secondary',
              },
            }}
            TabScrollButtonProps={{
              sx: {
                color: 'rgba(101, 88, 177, 0.45)',
              },
            }}
            indicatorColor={'secondary'}
          >
            <Tab
              label="Dashboard"
              value={'overview'}
              sx={{
                fontSize: '20px',
                fontWeight: '500',
                color:
                  !Boolean(search || isSettingsOrHelpPage(location)) &&
                  page === 'overview'
                    ? '#694FA3'
                    : '#00000099',
                opacity: '1',
                padding: '14px 0',
                height: '60px',
                marginRight: '20px',
                textTransform: 'none',
                span: { background: 'transparent' },
              }}
              onClick={() => {
                handleChangePage('', 'overview');
              }}
            />
            <Tab
              label="Interactions"
              value={'interactions'}
              sx={{
                fontSize: '20px',
                fontWeight: '500',
                color:
                  !Boolean(search || isSettingsOrHelpPage(location)) &&
                  page === 'interactions'
                    ? '#694FA3'
                    : '#00000099',
                opacity: '1',
                padding: '14px 0',
                height: '60px',
                marginRight: '20px',
                textTransform: 'none',
                span: { background: 'transparent' },
              }}
              onClick={() => {
                handleChangePage('', 'interactions');
              }}
            />
            <Tab
              label="Suppliers"
              value={'suppliers'}
              sx={{
                fontSize: '20px',
                fontWeight: '500',
                color:
                  !Boolean(search || isSettingsOrHelpPage(location)) &&
                  page === 'suppliers'
                    ? '#694FA3'
                    : '#00000099',
                opacity: '1',
                padding: '14px 0',
                height: '60px',
                marginRight: '20px',
                textTransform: 'none',
                span: { background: 'transparent' },
              }}
              onClick={() => {
                handleChangePage('', 'suppliers');
              }}
            />
            {!isProductionDomain(user?.organization) && (
              <Tab
                label="Outreach"
                value={'outreach'}
                sx={{
                  fontSize: '20px',
                  fontWeight: '500',
                  color:
                    !Boolean(search || isSettingsOrHelpPage(location)) &&
                    page === 'outreach'
                      ? '#694FA3'
                      : '#00000099',
                  opacity: '1',
                  padding: '14px 0',
                  height: '60px',
                  marginRight: '20px',
                  textTransform: 'none',
                  span: { background: 'transparent' },
                }}
                onClick={() => {
                  handleChangePage('', 'outreach');
                }}
              />
            )}
            {hasReports(user) && (
              <Tab
                label="Reports"
                value={'reports'}
                sx={{
                  fontSize: '20px',
                  fontWeight: '500',
                  color:
                    !Boolean(search || isSettingsOrHelpPage(location)) &&
                    page === 'reports'
                      ? '#694FA3'
                      : '#00000099',
                  opacity: '1',
                  padding: '14px 0',
                  height: '60px',
                  marginRight: '20px',
                  textTransform: 'none',
                  span: { background: 'transparent' },
                }}
                onClick={() => {
                  handleChangePage('', 'reports');
                }}
              />
            )}
            {user?.backoffice && (
              <Tab
                label="Backoffice"
                value={'backoffice'}
                sx={{
                  fontSize: '20px',
                  fontWeight: '500',
                  color:
                    !Boolean(search || isSettingsOrHelpPage(location)) &&
                    page === 'backoffice'
                      ? '#694FA3'
                      : '#00000099',
                  opacity: '1',
                  padding: '0',
                  textTransform: 'none',
                  span: { background: 'transparent' },
                }}
                onClick={() => {
                  handleChangePage('', 'backoffice');
                }}
              />
            )}
          </Tabs>
        </Box>
      </Stack>
      <Stack
        direction={'row'}
        gap={'24px'}
        alignItems={'center'}
        marginRight={'16px'}
      >
        <Stack
          direction={'row'}
          bgcolor={'#F5F5F5'}
          alignItems={'center'}
          width={'384px'}
          minWidth={'384px'}
          borderRadius={'28px'}
          padding={'5px 20px'}
          height={'34px'}
        >
          <InputBase
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            sx={{
              flex: 1,
              background: '#F5F5F5',
            }}
            placeholder="Search"
          />
          {searchValue && (
            <IconButton
              type="button"
              sx={{ padding: '0', marginRight: '4px' }}
              onClick={() => {
                setSearchValue('');
              }}
            >
              <StyledIcon component={CloseRounded} color="icon" />
            </IconButton>
          )}
          <IconButton type="button" sx={{ padding: '0' }}>
            <StyledIcon component={SearchRounded} color="icon" />
          </IconButton>
        </Stack>
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <IconButton
            sx={{ padding: '8px' }}
            onClick={() => {
              navigate('/help');
              setSearchValue('');
              handleOnClick && handleOnClick();
            }}
          >
            <StyledIcon component={HelpOutlineRounded} color={'icon'} />
          </IconButton>
          <IconButton
            sx={{ padding: '8px' }}
            onClick={() => {
              navigate('/settings');
              setSearchValue('');
              handleOnClick && handleOnClick();
            }}
          >
            <StyledIcon component={SettingsOutlined} color={'icon'} />
          </IconButton>
          <>
            {!user && <LoginButton />}
            {user && <LogoutButton />}
          </>
        </Box>
      </Stack>
    </Box>
  );
};

export default Header;
