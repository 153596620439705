import React, { useEffect, useState } from "react";
import CustomCard from "../../common/CustomCard";
import {
    Avatar,
    Box,
    Button,
    Stack,
    Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import InteractionStageGraph from "./InteractionStageGraph";
import InteractionsByMembersModal from "./InteractionsByMembersModal";
import { getInitials } from "../../utils/functions";

const InteractionsByMembers = ({ selectedMembers, procurementMembers }) => {
    const { interactionsOverview } = useSelector((state) => state.appSlice);

    const [people, setPeople] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const getPeopleList = () => {
        const peopleMap = {};

        interactionsOverview.forEach((interaction) => {
            let members = interaction.internal_stakeholders.slice();

            if (interaction?.procurement_owner?.email) {
                members.push(interaction.procurement_owner);
            }

            if (procurementMembers.length) {
                members = members.filter((stakeholder) => procurementMembers.includes(stakeholder.email));
            }

            if (selectedMembers.length) {
                members = members.filter((stakeholder) => selectedMembers.includes(stakeholder.email));
            }

            members.forEach((stakeholder) => {
                const filteredName = stakeholder.name.replace(/['"]+/g, '');
                const key = filteredName;
                if (!peopleMap[key]) {
                    peopleMap[key] = {
                        name: key,
                        stages: {
                            exploration_stage: 0,
                            negotiation_stage: 0,
                            closing_stage: 0,
                            total_interactions: 0,
                        },
                    };
                }

                peopleMap[key].stages[interaction.stage_name.toLowerCase().replace(' ', '_') + '_stage'] += 1;
                peopleMap[key].stages.total_interactions += 1;
            });
        });

        return Object.values(peopleMap);
    };

    useEffect(() => {
        setPeople(getPeopleList());
    }, [interactionsOverview, selectedMembers, procurementMembers]);

    if (people.length === 0) {
        return <></>;
    }

    return (
      <>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Stack
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              marginBottom={'10px'}
            >
              <Box
                fontSize={(theme) => theme.typography.h6}
                color={(theme) => theme.palette.text.main}
              >
                Interactions by procurement members
              </Box>
              <Button
                variant={'text'}
                color={'secondary'}
                sx={{ textTransform: 'none' }}
                onClick={() => setIsOpenModal(true)}
              >
                View All
              </Button>
            </Stack>

            {people
              .sort(
                (a, b) =>
                  b.stages.total_interactions - a.stages.total_interactions,
              )
              .map((person, index) => (
                <Box key={index}>
                  <Box>
                    <Stack
                      fontSize={'13px'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      gap={'4px'}
                      marginBottom={'-4px'}
                    >
                      <Avatar
                        sx={{
                          bgcolor: '#e3e1ea',
                          color: '#514689',
                          marginRight: '4px',
                          fontSize: '13px',
                          width: '24px',
                          height: '24px',
                        }}
                      >
                        {getInitials(person.name)}
                      </Avatar>
                      {person.name}
                    </Stack>
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    height={'40px'}
                  >
                    <InteractionStageGraph item={person.stages} />

                    <Typography
                      sx={{ whiteSpace: 'nowrap' }}
                      marginLeft={'24px'}
                    >
                      {person.stages.total_interactions} interactions
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
        </CustomCard>
        <InteractionsByMembersModal
          open={isOpenModal}
          handleClose={() => setIsOpenModal(false)}
          total={people.length}
          people={people}
        />
      </>
    );
};

export default InteractionsByMembers;
