import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { ReactComponent as InfoIcon } from "../common/icons/notification.svg";

import { deleteNotification, getNotifications } from "../store/storeFunctions";
import CustomAlert from "./CustomAlert";
import StyledIcon from "./StyledIcon";

const Notifications = ({ id }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { notifications } = useSelector((state) => state.appSlice);

  useEffect(() => {
    dispatch(getNotifications());
  }, []);
  const notificationVariant = (notification) => {
    switch (notification.level) {
      case "info":
      case "tip":
        return {
          icon: <InfoIcon />,
          color: theme.palette.alert.main,
        };
      case "warning":
        return { icon: null, color: theme.palette.alert_warning };
      default:
        return { icon: null, color: theme.palette.alert.main };
    }
  };

  if (!Boolean(notifications || notifications.length > 0)) {
    return <></>;
  }

  return (
    <>
      {notifications.filter(notification => notification.id === id).map((notification) => {
        const variantData = notificationVariant(notification);
        return (
          <Box key={notification.id} marginBottom={"16px"}>
            <CustomAlert
              key={notification.id}
              severity={
                notification?.level === "tip" ? "info" : notification.level
              }
              variant={"standard"}
              button={
                <Stack direction={"row"} gap={"20px"} alignItems={"center"}>
                  {notification.link &&
                    location.pathname !== notification.link && (
                      <Button
                        color="inherit"
                        onClick={() => {
                          navigate(notification.link);
                        }}
                        variant={"text"}
                        sx={{ textTransform: "none", padding: "4px 5px" }}
                      >
                        {notification.link_label}
                      </Button>
                    )}
                  {notification.dismiss_label ? (
                    <Button
                      color="inherit"
                      onClick={() => {
                        dispatch(deleteNotification(notification.id)).then(() =>
                          dispatch(getNotifications())
                        );
                      }}
                      variant={"text"}
                      sx={{ textTransform: "none", padding: "4px 5px" }}
                    >
                      {notification.dismiss_label}
                    </Button>
                  ) : (
                    <IconButton
                      onClick={() => {
                        dispatch(deleteNotification(notification.id));
                      }}
                      sx={{ padding: "4px" }}
                    >
                      <StyledIcon
                        component={CloseRounded}
                        color={"icon"}
                        sx={{ fontSize: 20 }}
                      />
                    </IconButton>
                  )}
                </Stack>
              }
              message={
                <Box color={variantData.color}>
                  {notification.title && (
                    <Box fontSize={(theme) => theme.typography.subtitle1}>
                      {notification.title}
                    </Box>
                  )}
                  {notification.text && (
                    <Box fontSize={(theme) => theme.typography.body2}>
                      {notification.text}
                    </Box>
                  )}
                </Box>
              }
              icon={variantData.icon}
            />
          </Box>
        );
      })}
    </>
  );
};

export default Notifications;
