import { Box, Stack } from '@mui/material';
import React from 'react';

const PlaceholderCostSavings = () => {
  const ideas = [
    {
      label: 'Using existing vendors',
      text: 'Automatic recommendations of existing vendors',
    },
    {
      label: 'Reducing the time waiting on vendors',
      text: 'Early communication of Samsara requirements to the vendor contact',
    },
    {
      label: 'Price benchmarks',
      text: `e.g. price range for conference booths or marketing agencies within the\n
      country and across countries.`,
    },
  ];
  return (
    <Stack flexDirection={'column'} gap={'16px'}>
      {ideas.map((idea) => {
        return (
          <Stack
            key={idea.label}
            alignItems={'center'}
            justifyContent={'center'}
            width={'100%'}
            maxWidth={'806px'}
            height={'216px'}
            padding={'0 137px'}
            sx={{
              backgroundColor: '#F2F2F2',
              color: '#00000099',
              fontSize: '24px',
              fontWeight: '400',
            }}
            textAlign={'center'}
          >
            <Box sx={{ textDecoration: 'underline' }}>{idea.label}</Box>
            <Box>{idea.text}</Box>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default PlaceholderCostSavings;
