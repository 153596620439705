import { Box, Button, styled } from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { BoldText, ResultsBox } from './Blocks';
import ClearFiltersButton from './ClearFilters';

const typeWords = {
  interactions: {
    single: 'interaction',
    plural: 'interactions',
    variant: 'filtersInteractions',
  },
  suppliers: {
    single: 'supplier',
    plural: 'suppliers',
    variant: 'filtersSuppliers',
  },
};

const StyledClearButton = styled(Button)({
  textTransform: 'none',
});

const ListSummary = ({ type, count }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isSearchResult = searchParams.get('q');
  const resultCountText = `${count} ${
    count === 1 ? typeWords[type].single : typeWords[type].plural
  }
    found${isSearchResult ? '' : '.'}`;
  const subHeaderSearchText = `There ${count === 1 ? 'is' : 'are'} ${count}
    ${
      count === 1 ? typeWords[type].single : typeWords[type].plural
    } matching “${searchParams.get('q')}”.`;

  const clearSearch = () => {
    setSearchParams((params) => {
      params.delete('q');
      return params;
    });
  };
  return (
    <Box>
      <ResultsBox>
        <BoldText isBold={isSearchResult}>{resultCountText}</BoldText>
        <ClearFiltersButton
          variant={typeWords[type].variant}
        ></ClearFiltersButton>
      </ResultsBox>

      {isSearchResult && (
        <Box>
          <span
            color={(theme) => theme.palette.text.caption}
            fontSize={(theme) => theme.typography.body1}
          >
            {subHeaderSearchText}
          </span>
          <StyledClearButton onClick={clearSearch} color="secondary">
            Clear search
          </StyledClearButton>
        </Box>
      )}
    </Box>
  );
};

export default ListSummary;
