import React, { useEffect, useState } from "react";
import { TextField, Chip, Autocomplete } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Target = ({
  disabled,
  setTags,
  tags,
  fullWidth,
  color,
  variant,
  placeholder,
  label,
}) => {
  const theme = useTheme();
  const [tagsArray, setTagsArray] = useState([]);

  useEffect(() => {
    setTagsArray(tags);
  }, [tags]);
  const handleChange = (event, chips) => {
    setTags(chips);
  };

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={[]}
      freeSolo
      disabled={disabled}
      onChange={handleChange}
      value={tags}
      renderTags={(value, getTagProps) =>
        tags.map((option, index) => (
          <Chip label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            fieldset: { borderRadius: "10px" },
            ".Mui-disabled": {
              span: { color: theme.palette.text.main },
              opacity: "1 !important",
              color: theme.palette.text.medium,
            },
          }}
          variant={variant}
          color={color}
          label={label}
          disabled={disabled}
          placeholder={!disabled && placeholder}
        />
      )}
    />
  );
};
export default Target;
