import React from 'react';
import { isActiveInteraction } from '../components/suppliers/SupplierItem';
import { ReactComponent as ActiveInteraction } from './icons/activeInteraction.svg';
import { ReactComponent as ActiveContract } from './icons/activeContract.svg';
import { Box } from '@mui/material';

const SupplierBadges = ({ supplier, gap }) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={gap || '16px'}>
      {isActiveInteraction(supplier) && (
        <Box gap={'6px'} alignItems={'center'} display={'flex'}>
          <ActiveInteraction />
          <Box
            fontSize={(theme) => theme.typography.body2}
            color={(theme) => theme.palette.text.secondary}
          >
            Active interaction
          </Box>
        </Box>
      )}
      {supplier?.active_contract && (
        <Box
          display={'flex'}
          marginBottom={'2px'}
          gap={'6px'}
          alignItems={'center'}
        >
          <ActiveContract />
          <Box
            fontSize={(theme) => theme.typography.body2}
            color={(theme) => theme.palette.text.secondary}
          >
            Active contract
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SupplierBadges;
