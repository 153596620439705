import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appSlice } from "../../store/appSlice";
import { getBundles, getInteractionsBackoffice } from "../../store/storeFunctions";
import { Box, Stack, Button } from "@mui/material";
import NoResultCard from "../../common/NoResultCard";
import LoadingSkeleton from "../../common/LoadingSkeleton";
import ClearFiltersButton from "../../common/ClearFilters";
import { ResultsBox, BoldText } from '../../common/Blocks';
import BundleItem from "../../common/BundleItem";
import { FormControl, Pagination, InputLabel, MenuItem, Select, Checkbox } from "@mui/material";
import {
  updateFiltersBundles,
} from "../../store/localStorage";
import { formatBatchPublishQuery } from "../../utils/functions";
import BackofficeTabs, { BACKOFFICE_TABS } from "./BackofficeTabs";

const Interactions = ({ onTabChange, tab }) => {
  const dispatch = useDispatch();
  const { filtersBundles, bundles, bundles_pagination, interactionsBackoffice, interactionsBackofficePagination, isLoading, sortBy, sortDirection } =
    useSelector((state) => state.appSlice);

  const selectedSort = `${sortBy}-${sortDirection}`;

  const [currentPage, setCurrentPage] = useState(1);

  const [currentTab, setCurrentTab] = useState(tab);
  const [totalItemsText, setTotalItemsText] = useState('');
  const [selectAllInteractions, setSelectAllInteractions] = useState(false);

  const [selectedInteractions, setSelectedInteractions] = useState(new Set());

  const { setFiltersBundles } = appSlice.actions;

  const toggleSelectAll = () => {
    if (selectedInteractions.size === interactionsBackoffice.length) {
      setSelectedInteractions(new Set());
      setSelectAllInteractions(false);
    } else {
      const newSelected = new Set(
        interactionsBackoffice.map(interaction => interaction.uuid)
      );
      setSelectedInteractions(newSelected);
    }
  };

  const isInteractionSelected = (id) => {
    return selectAllInteractions || selectedInteractions.has(id);
  }

  const toggleInteractionSelection = (id) => {
    const newSelected = new Set(selectedInteractions);
    if (newSelected.has(id)) {
      newSelected.delete(id);
      setSelectAllInteractions(false);
    } else {
      newSelected.add(id);
    }
    setSelectedInteractions(newSelected);
  };

  const handleSortChange = (event) => {
    const value = event.target.value;
    const [sortBy, sortDirection] = value.split("-");

    dispatch(appSlice.actions.setSortBy(sortBy));
    dispatch(appSlice.actions.setSortDirection(sortDirection || 'desc'));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const publishSelectedInteractions = async () => {
    const interactionUUIDs = Array.from(selectedInteractions);
    const body = selectAllInteractions
      ? null
      : JSON.stringify({ uuids: interactionUUIDs });
    let url = `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/interactions/batch_publish`;
    if (selectAllInteractions)
      url = `${url}?${formatBatchPublishQuery(filtersBundles)}`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      if (selectAllInteractions) toggleSelectAll();
      else setSelectedInteractions(new Set());

      dispatch(
        appSlice.actions.updatePublishedInteractionStatus(interactionUUIDs),
      );
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const metaTotalCount = (newTab) => {
    if (newTab === BACKOFFICE_TABS.INTERACTIONS)
      return interactionsBackofficePagination?.total_count;
    return bundles_pagination?.total_count;
  };

  const calculateItemsText = (newTab) => {
    const entity =
      newTab === BACKOFFICE_TABS.INTERACTIONS ? 'interaction' : 'bundle';
    const totalCount = metaTotalCount(newTab);
    return `${totalCount} ${totalCount === 1 ? entity : `${entity}s`} found`;
  };

  const hasItems = () => {
    if (currentTab === BACKOFFICE_TABS.INTERACTIONS)
      return Boolean(interactionsBackoffice.length)
    else
      return Boolean(bundles.length);
  }

  const getBundlesAction = () => {
    return dispatch(
      getBundles({
        timeframe: filtersBundles.timeframe,
        reviewed: filtersBundles.reviewStatus.length === 1 ? filtersBundles.reviewStatus : '',
        sort_by: sortBy,
        sort_direction: sortDirection,
        supplier_deal: filtersBundles.supplierDeal.length === 3 ? '' : filtersBundles.supplierDeal,
        page: currentPage - 1,
        interaction_present: 'false',
        organization_id: filtersBundles.organizationId,
        search_query: filtersBundles.search_query,
      })
    );
  };

  const getInteractionsAction = () => {
    return dispatch(
      getInteractionsBackoffice(
        {
          isBackoffice: true,
          timeframe: filtersBundles.timeframe,
          reviewed:
            filtersBundles.reviewStatus.length === 1
              ? filtersBundles.reviewStatus
              : '',
          sort_by: sortBy,
          sort_direction: sortDirection,
          supplier_deal:
            filtersBundles.supplierDeal.length === 3
              ? ''
              : filtersBundles.supplierDeal,
          published: filtersBundles.published,
          has_contract: filtersBundles.hasContract,
          page: currentPage - 1,
          organization_id: filtersBundles.organizationId,
          search_query: filtersBundles.search_query,
        }
      ),
    );
  };

  useEffect(() => {
    setCurrentPage(1);
    if (currentTab === BACKOFFICE_TABS.BUNDLES) getBundlesAction();
    if (currentTab == BACKOFFICE_TABS.INTERACTIONS) getInteractionsAction();
  }, [filtersBundles, sortBy, sortDirection]);

  useEffect(() => {
    if (currentTab === BACKOFFICE_TABS.BUNDLES) getBundlesAction();
    if (currentTab == BACKOFFICE_TABS.INTERACTIONS) getInteractionsAction();
  }, [currentPage, currentTab]);

  useEffect(() => {
    if (!selectAllInteractions) setSelectedInteractions(new Set());
  }, [currentPage]);

  useEffect(() => {
    setTotalItemsText(calculateItemsText(currentTab));
  }, [bundles_pagination, interactionsBackofficePagination])

  useEffect(() => {
    setCurrentTab(tab);
    const newFilters = { ...filtersBundles, interactionPresent: tab === BACKOFFICE_TABS.INTERACTIONS };
    updateFiltersBundles(newFilters);
    dispatch(setFiltersBundles(newFilters));
  }, [tab]);

  return (
    <Box width={'100%'}>
      <Box
        marginBottom={'24px'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'flex-end'}
      >
       <BackofficeTabs currentTab={currentTab} handleChangeTab={onTabChange} />

        {currentTab === BACKOFFICE_TABS.INTERACTIONS &&
          Boolean(interactionsBackoffice.length) && (
            <Box>
              <Button
                variant={'contained'}
                color={'secondary'}
                sx={{ borderRadius: '10px' }}
                disabled={selectedInteractions.size === 0}
                onClick={publishSelectedInteractions}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    gap: '8px',
                  }}
                >
                  <Box
                    sx={{
                      lineHeight: '24px',
                      textTransform: 'none',
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    Publish selected{' '}
                    {selectAllInteractions ?
                      `(${interactionsBackofficePagination.total_count})`
                      : `${selectedInteractions.size ? '(' + selectedInteractions.size + ')' : ''}`
                    }
                  </Box>
                </Box>
              </Button>
            </Box>
          )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '16px',
        }}
      >
        <Box>
          {isLoading && !hasItems() ? (
            <Box height={'36px'}>&nbsp;</Box>
          ) : (
            <ResultsBox>
              <BoldText>{totalItemsText}</BoldText>
              <ClearFiltersButton
                variant={'filtersBundles'}
              ></ClearFiltersButton>
            </ResultsBox>
          )}
        </Box>
      </Box>

      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        marginBottom={'16px'}
      >
        <Box
          marginLeft={'-12px'}
          display={'flex'}
          alignItems={'center'}
          gap={'5px'}
        >
          {currentTab === BACKOFFICE_TABS.INTERACTIONS &&
            Boolean(interactionsBackoffice.length) && (
              <Box>
                <Checkbox
                  checked={
                    selectedInteractions.size > 0 &&
                    selectedInteractions.size ===
                    interactionsBackoffice.length
                  }
                  indeterminate={
                    selectedInteractions.size > 0 &&
                    selectedInteractions.size < interactionsBackoffice.length
                  }
                  onChange={toggleSelectAll}
                />
                Select all{' '}
                {interactionsBackofficePagination.total_count &&
                  !selectAllInteractions
                  ? 'on page'
                  : ''}
              </Box>
            )}
          <Box>
            {currentTab === BACKOFFICE_TABS.INTERACTIONS &&
              Boolean(interactionsBackoffice.length) &&
              !selectAllInteractions &&
              selectedInteractions.size === interactionsBackoffice.length &&
              selectedInteractions.size <
              interactionsBackofficePagination.total_count && (
                <Button
                  onClick={() => setSelectAllInteractions(true)}
                  variant={'text'}
                  color={'secondary'}
                  sx={{ textTransform: 'none' }}
                >
                  Select all {interactionsBackofficePagination.total_count} in
                  this filter.
                </Button>
              )}
          </Box>
        </Box>

        <Stack flexDirection={'row'} gap={'16px'} alignItems={'center'}>
          {!isLoading && hasItems() &&
            (<Pagination
              page={currentPage}
              onChange={handlePageChange}
              count={
                currentTab === BACKOFFICE_TABS.INTERACTIONS
                  ? interactionsBackofficePagination?.total_pages
                  : bundles_pagination?.total_pages
              }
              color="primary"
            />)}

          {!isLoading && hasItems() &&
            (<Box sx={{ width: '160px' }}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel htmlFor="sortedBy" color={'secondary'}>
                  Sort by
                </InputLabel>
                <Select
                  sx={{ borderRadius: '10px' }}
                  color={'secondary'}
                  size={'small'}
                  id={'sortedBy'}
                  label={'Sort by'}
                  value={selectedSort}
                  onChange={handleSortChange}
                >
                  <MenuItem value={'urgency-desc'}>Recommended</MenuItem>
                  <MenuItem value={'start_time-desc'}>Recent date</MenuItem>
                  <MenuItem value={'start_time-asc'}>Earliest date</MenuItem>
                  {currentTab === BACKOFFICE_TABS.INTERACTIONS && (
                    <MenuItem value={'supplier_name-asc'}>
                      Supplier: A-Z
                    </MenuItem>
                  )}
                  {currentTab === BACKOFFICE_TABS.INTERACTIONS && (
                    <MenuItem value={'supplier_name-desc'}>
                      Supplier: Z-A
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>)}
        </Stack>
      </Stack>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {isLoading ? (
          [0, 1, 2, 3].map((_, index) => (
            <LoadingSkeleton variant={'interactionItem'} key={index} />
          ))
        ) : (bundles.length === 0 &&
          currentTab === BACKOFFICE_TABS.BUNDLES) ||
          (interactionsBackoffice.length === 0 &&
            currentTab === BACKOFFICE_TABS.INTERACTIONS) ? (
          <Stack display={'flex'} width={'100%'}>
            <Box sx={{ height: '422px' }}>
              <NoResultCard variant={'filtersBundles'} />
            </Box>
          </Stack>
        ) : currentTab === BACKOFFICE_TABS.INTERACTIONS ? (
          interactionsBackoffice.map((interaction) => (
            <BundleItem
              key={interaction.id}
              interaction={interaction}
              selected={isInteractionSelected(interaction.uuid)}
              onSelect={() => toggleInteractionSelection(interaction.uuid)}
            />
          ))
        ) : (
          bundles.map((bundle) => (
            <BundleItem key={bundle.id} bundle={bundle} disabled />
          ))
        )}
      </Box>

      <Stack marginTop={'16px'} alignItems={'flex-end'}>
        {!isLoading && hasItems() &&
          (<Pagination
            page={currentPage}
            onChange={handlePageChange}
            count={
              currentTab === BACKOFFICE_TABS.INTERACTIONS
                ? interactionsBackofficePagination?.total_pages
                : bundles_pagination?.total_pages
            }
            color="primary"
          />)}
      </Stack>
    </Box>
  );
};

export default Interactions;
