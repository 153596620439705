import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete, Box, TextField } from '@mui/material';


const SupplierDropdown = ({ value, onChange }) => {
  const [suppliers, setSuppliers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [localValue, setLocalValue] = useState();

  const { filtersBundles } = useSelector((state) => state.appSlice);

  const getSuppliers = async () => {
    const apiUrl = `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/organizations/${filtersBundles.organizationId}/suppliers`;
    const response = await fetch(apiUrl, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
      credentials: 'include',
    });
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getSuppliers().then((res) => {
      if (res !== 'error') {
        setSuppliers(res?.suppliers || []);
      }
      setIsLoading(false);
    });
  }, [filtersBundles.organizationId]);

  useEffect(() => {
    if (!isLoading) {
      const filter = suppliers?.find(
        (supplier) => supplier.id === value,
      );
      
      setLocalValue(filter);
      onChange(filter);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!value && !isLoading) {
      setLocalValue(null);
    }
  }, [value]);

  if (!suppliers.length || isLoading) {
    return <></>;
  }

  return (
    <>
      <Autocomplete
        id="autocomplete-supplier"
        options={suppliers}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {option.name} ({option.id})
          </Box>
        )}
        value={localValue || null}
        onChange={(_, value) => {
          setLocalValue(value);
          onChange(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            label="Supplier"
            sx={{
              '& .MuiOutlinedInput-root': {
                padding: '2px 8px 2px 8px',
                borderRadius: '8px',
              },
              '& .MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
                top: '-5px',
              }
            }}
          />
        )}
      />
    </>
  );
};

export default SupplierDropdown;
