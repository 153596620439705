import {
  Box,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import BackofficeTabs, { BACKOFFICE_TABS } from '../BackofficeTabs';
import ClearFiltersButton from '../../../common/ClearFilters';
import { BoldText, ResultsBox } from '../../../common/Blocks';
import { encodeQuery } from '../../../store/storeFunctions';
import { useDispatch, useSelector } from 'react-redux';
import ContractNameEditor from './ContractNameEditor';
import ContractTypeEditor from './ContractTypeEditor';
import { useNavigate } from 'react-router-dom';
import ContractManualLabel from './ContractManualLabel';
import { appSlice } from '../../../store/appSlice';

const Contracts = ({ onTabChange }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [totalItemsText, setTotalItemsText] = useState();

  const { filtersBundles, backofficeContracts } = useSelector(
    (state) => state.appSlice,
  );

  const { setBackofficeContracts } = appSlice.actions;

  useEffect(() => {
    setCurrentPage(1);
    getContracts().then(processContracts);
  }, [filtersBundles]);

  useEffect(() => {
    getContracts().then(processContracts);
  }, [currentPage]);

  const metaTotalCount = (meta) => {
    return meta?.total_count;
  };

  const calculateItemsText = (meta) => {
    const entity = 'contract';
    const totalCount = metaTotalCount(meta);
    return `${totalCount} ${totalCount === 1 ? entity : `${entity}s`} found`;
  };

  const getContracts = async () => {
    const query = {
      page: currentPage - 1,
      organization_id: filtersBundles.organizationId,
      supplier_id: filtersBundles.supplierId,
      contract_types: filtersBundles.contractTypes || [],
      type_tagged: filtersBundles.contractTypesTagged,
      contract_type_manual:
        filtersBundles?.contractTypeManual?.length === 4
          ? ''
          : filtersBundles.contractTypeManual || [],
    };

    const apiUrl = `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/contracts`;
    const queryString = encodeQuery(query);
    const urlWithQuery = `${apiUrl}?${queryString}`;
    const response = await fetch(urlWithQuery, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
      credentials: 'include',
    });
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const onSave = (updatedContract) => {
    const updatedContracts = backofficeContracts.map((contract) => {
      if (updatedContract.id === contract.id) return updatedContract;
      return contract;
    });
    dispatch(setBackofficeContracts(updatedContracts));
  };

  const processContracts = (response) => {
    if (response !== 'error') {
      setPagination(response?.meta || {});
      dispatch(setBackofficeContracts(response?.contracts || []));
      setTotalItemsText(calculateItemsText(response?.meta || {}));
    }
    setIsLoading(false);
  };

  const handlePageChange = (_, value) => {
    setCurrentPage(value);
  };

  const tableHeaders = ['Title', 'Type', 'Labeled'];

  return (
    <Box width={'100%'}>
      <Box
        marginBottom={'24px'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'flex-end'}
      >
        <BackofficeTabs
          currentTab={BACKOFFICE_TABS.CONTRACTS}
          handleChangeTab={onTabChange}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '16px',
        }}
      >
        <Box>
          {isLoading ? (
            <Box height={'36px'}>&nbsp;</Box>
          ) : (
            <ResultsBox>
              <BoldText>{totalItemsText}</BoldText>
              <ClearFiltersButton
                variant={'filtersBundles'}
              ></ClearFiltersButton>
            </ResultsBox>
          )}
        </Box>
      </Box>
      <Stack marginTop={'16px'} alignItems={'flex-end'}>
        {!isLoading && Boolean(backofficeContracts.length) && (
          <Pagination
            page={currentPage}
            onChange={handlePageChange}
            count={pagination?.total_pages}
            color="primary"
          />
        )}
      </Stack>
      <Table
        sx={{
          borderCollapse: 'separate !important',
          border: '1px solid #DEDBEC',
          borderRadius: '12px',
          width: '100%',
          tableLayout: 'auto',
        }}
      >
        <TableHead>
          <TableRow>
            {tableHeaders.map((label) => (
              <TableCell key={label}>
                <Box
                  color={(theme) => theme.palette.text.main}
                  fontSize={(theme) => theme.typography.subtitle2}
                >
                  {label}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {backofficeContracts?.map((contract) => (
            <TableRow
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                navigate(`/backoffice/details/contract/${contract.id}`)
              }
              key={contract.id}
            >
              <TableCell>
                <ContractNameEditor contract={contract} onSave={onSave} />
                <Box
                  color={(theme) => theme.palette.text.medium}
                  fontSize={(theme) => theme.typography.caption}
                >
                  Raw name: {contract.raw_name}
                </Box>
              </TableCell>
              <TableCell>
                <ContractTypeEditor contract={contract} onSave={onSave} />
                <Box
                  color={(theme) => theme.palette.text.medium}
                  fontSize={(theme) => theme.typography.caption}
                >
                  Raw types: {contract.contract_type_tags.join(', ') || contract.raw_contract_type}
                </Box>
              </TableCell>
              <TableCell>
                <ContractManualLabel contract={contract} onSave={onSave} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Stack marginTop={'16px'} alignItems={'flex-end'}>
        {!isLoading && Boolean(backofficeContracts.length) && (
          <Pagination
            page={currentPage}
            onChange={handlePageChange}
            count={pagination?.total_pages}
            color="primary"
          />
        )}
      </Stack>
    </Box>
  );
};

export default Contracts;
