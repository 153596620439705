import { Box } from "@mui/material";
import styled from '@emotion/styled';

export const ResultsBox = styled(Box)({
    fontSize: (theme) => theme.typography.body1,
    color: (theme) => theme.palette.text.medium,
});

export const BoldText = styled.span`
    font-size: ${props => props.theme.typography.h6};
    font-weight: ${props => props.isBold ? 'bold' : 'normal'};
  `;