import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CustomCard from "../../common/CustomCard";
import StyledIcon from "../../common/StyledIcon";
import {
  ModeEditOutlineOutlined,
  DescriptionOutlined,
} from "@mui/icons-material";
import RolePopup from "./RolePopup";
import LoadingSkeleton from "../../common/LoadingSkeleton";
import { getUsers } from "../../common/DataHelper";

const Roles = ({ role, user }) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const getData = () => {
    getUsers().then((res) => {
      setUsers(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    document.title = "PartnerElement | Settings > Team roles";
    getData();
  }, []);

  return (
    <>
      <Box sx={{ width: "100%", height: "fit-content" }}>
        <CustomCard>
          <Box>
            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ borderBottom: "1px solid #694FA31A", padding: "16px" }}
            >
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.h6}
              >
                Teams roles
              </Box>
            </Stack>
            <Box>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      th: {
                        borderBottom:
                          users?.length === 0
                            ? "none"
                            : "1.5px solid #694FA31A",
                        padding: "16px",
                        fontWeight: "subtitle2",
                        fontSize: "subtitle2",
                        color: "#000000DE",
                      },
                    }}
                  >
                    <TableCell width={"25%"}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        {!isLoading && "Name"}
                      </Box>
                    </TableCell>
                    <TableCell width={"25%"}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        {!isLoading && "Email"}
                      </Box>
                    </TableCell>
                    <TableCell width={"25%"}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        {!isLoading && "Role"}
                      </Box>
                    </TableCell>
                    <TableCell width={"25%"}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        {!isLoading && "Last access"}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading
                    ? [0, 1, 2, 3].map((_, index) => (
                      <LoadingSkeleton variant={"users"} key={index} />
                    ))
                    : users?.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          borderBottom: "1.5px solid #694FA31A",
                          td: {
                            border: "none",
                            padding: "16px",
                            color: "#000000DE",
                            fontWeight: "body2",
                            fontSize: "body2",
                            whiteSpace: "nowrap",
                          },
                          ":last-of-type": { borderBottom: "none" },
                        }}
                      >
                        <TableCell>{item?.name}</TableCell>

                        <TableCell>
                          <Box
                            sx={{
                              whiteSpace: "normal",
                            }}
                          >
                            {item?.email}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              whiteSpace: "normal",
                            }}
                          >
                            {item?.role}
                          </Box>
                        </TableCell>
                        <TableCell sx={{ paddingRight: "16px" }}>
                          <Stack
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignItems={"start"}
                          >
                            <Box>{item?.last_access_at}</Box>
                            {role === "admin" && item.email != user.email && <IconButton
                              sx={{
                                padding: "8px",
                                position: "relative",
                                top: "-8px",
                              }}
                              onClick={() => {
                                setOpenPopup(true);
                                setCurrentUser(item);
                              }}
                            >
                              <StyledIcon
                                component={ModeEditOutlineOutlined}
                                color={"secondary"}
                              />
                            </IconButton>}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </CustomCard>
      </Box>
      {openPopup && (
        <RolePopup
          open={openPopup}
          handleClose={() => setOpenPopup(false)}
          user={currentUser}
          getData={getData}
        />
      )}
    </>
  );
};

export default Roles;
