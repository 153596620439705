import { Box, IconButton, Modal, Stack } from '@mui/material';
import React from 'react';
import StyledIcon from '../../common/StyledIcon';
import { CloseRounded } from '@mui/icons-material';
import SpendBarGraph from './SpendBarGraph';

const SpendByStatusModal = ({ open, handleClose, collection }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 'fit-content',
          minWidth: '830px',
          maxWidth: '1080px',
          maxHeight: '640px',
          background: 'white',
          position: 'absolute',
          marginTop: '35px',
          top: '0',
          left: 'calc(50% - 415px)',
          borderRadius: '12px',
          padding: '24px',
          overflow: 'auto',
        }}
      >
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginBottom={'4px'}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
          >
            Spend by status
          </Box>
          <IconButton padding={'8px'} onClick={handleClose}>
            <StyledIcon component={CloseRounded} color={'icon'} />
          </IconButton>
        </Stack>
        <Stack
          paddingBottom={'16px'}
          width={'100%'}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'end'}
        >
          <Box color={(theme) => theme.palette.text.medium}>
            {collection.length} teams
          </Box>
        </Stack>
        <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
          {collection.map((spend, index) => (
            <Box key={`by-status-${spend.label}-${index}`}>
              <SpendBarGraph label={spend.label} spend={spend} />
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default SpendByStatusModal;
