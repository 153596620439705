import { createSlice } from "@reduxjs/toolkit";
import { getFiltersInteractions, getFiltersOverview, getFiltersBundles } from "./localStorage";
import { login } from "../components/auth/login";

import {
  getUser,
  getInteractions,
  getBundles,
  getNewInteractions,
  getDepartments,
  getNotifications,
  deleteNotification,
  getInteractionsOverview,
  getProcurementFilterData,
  getInteractionsBackoffice,
  getSuppliers,
  getDealValueRanges,
} from './storeFunctions';
import { defaultFiltersSuppliers } from "../utils/constants";

const initialState = {
  interactions: [],
  interactionsOverview: [],
  interactionsBackoffice: [],
  interactionIdsBackoffice: [],
  bundleIdsBackoffice: [],
  newInteractions: [],
  suppliers: [],
  supplierIndustries: [],
  bundles: [],
  backofficeContracts: [],
  departments: [],
  detailsFullscreen: false,
  notifications: [],
  user: null,
  isOpenSupplierDetails: false,
  isOpenInteractionDetails: false,
  isOpenReviewInteractionDetails: false,
  filtersOverview: getFiltersOverview(),
  filtersInteractions: getFiltersInteractions(),
  filtersBundles: getFiltersBundles(),
  filtersSuppliers: defaultFiltersSuppliers,
  filtersReport: {
    includeDealClosed: 30,
    savings: "percentage",
    departments: [],
  },
  search: "",
  previousSearch: "",
  page: "overview",
  sortBy: "urgency",
  sortDirection: "desc",
  isLoading: false,
  isLoadingOverview: false,
  isLoadingNewInteractions: false,
  isLoadingUser: false,
  isLoadingSuppliers: false,
  error: "",
  procurementFilterData: {
    specific_emails: [],
    departments: [],
  },
  dealValueRanges: [],
  detailsNavigation: {
    previous: [],
    next: [],
  }
};
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsOpenSupplierDetails: (state, action) => {
      state.isOpenSupplierDetails = action.payload;
    },
    setIsOpenInteractionDetails: (state, action) => {
      state.isOpenInteractionDetails = action.payload;
    },
    setIsOpenReviewInteractionDetails: (state, action) => {
      state.isOpenReviewInteractionDetails = action.payload;
    },
    setFiltersOverview: (state, action) => {
      state.filtersOverview = action.payload;
    },
    setFiltersInteractions: (state, action) => {
      state.filtersInteractions = action.payload;
    },
    setBackofficeContracts: (state, action) => {
      state.backofficeContracts = action.payload;
    },
    clearRangesFilterOverview: (state) => {
      return {
        ...state,
        filtersOverview: {
          ...state.filtersOverview,
          dealValueRanges: [],
        },
      };
    },
    clearRangesFilterInteractions: (state) => {
      return {
        ...state,
        filtersInteractions: {
          ...state.filtersInteractions,
          dealValueRanges: [],
        },
      };
    },
    setFiltersBundles: (state, action) => {
      state.filtersBundles = action.payload;
    },
    setFiltersSuppliers: (state, action) => {
      state.filtersSuppliers = action.payload;
    },
    setFiltersReport: (state, action) => {
      state.filtersReport = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setPreviousSearch: (state, action) => {
      state.previousSearch = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setSortDirection: (state, action) => {
      state.sortDirection = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    updatePublishedInteractionStatus: (state, action) => {
      const updatedInteractions = state.interactionsBackoffice.map(interaction => {
        if (action.payload.includes(interaction.uuid)) {
          return {
            ...interaction,
            status: "published"
          };
        }
        return interaction;
      });

      return { ...state, interactionsBackoffice: updatedInteractions };
    },
    updateReviewStatus: (state, action) => {
      const updatedBundles = state.bundles.map(bundle => {
        if (action.payload.bundleId.includes(bundle.id)) {
          return {
            ...bundle,
            supplier_manual: action.payload.isSupplierManual
          };
        }
        return bundle;
      });

      const updatedInteractions = state.interactionsBackoffice.map(interaction => {
        if (action.payload.bundleId.includes(interaction.bundle?.id)) {
          return {
            ...interaction,
            bundle: {
              ...interaction.bundle,
              supplier_manual: action.payload.isSupplierManual
            }
          };
        }
        return interaction;
      });

      return { ...state, bundles: updatedBundles, interactionsBackoffice: updatedInteractions };
    },
    toggleDetailsFullscreen: (state) => {
      return { ...state, detailsFullscreen: !state.detailsFullscreen };
    },
    addPreviousDetailsNavigation: (state, action) => {
      return {...state, detailsNavigation: { ...state.detailsNavigation, previous: [...state.detailsNavigation.previous, action.payload] }}

    },
    setPreviousDetailsNavigation: (state, action) => {
      return {...state, detailsNavigation: { ...state.detailsNavigation, previous: action.payload}}
    },
    setNextDetailsNavigation: (state, action) => {
      return {...state, detailsNavigation: { ...state.detailsNavigation, next: action.payload}}
    },
    clearDetailsNavigation: (state) => {
      return {...state, detailsNavigation: { previous: [], next: []}}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInteractions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInteractions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.interactions = action.payload;
      })
      .addCase(getInteractions.rejected, (state) => {
        state.isLoading = false;
        state.interactions = [];
      })
      .addCase(getBundles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBundles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bundles = action.payload?.bundles || [];
        state.bundles_pagination = action.payload?.meta || {};
        state.bundleIdsBackoffice = state.bundles
          .map(b => b?.id)
          .filter(Boolean);
      })
      .addCase(getBundles.rejected, (state) => {
        state.isLoading = false;
        state.bundles = [];
        state.bundles_pagination = {};
        state.bundleIdsBackoffice = [];
      })
      .addCase(getNewInteractions.pending, (state) => {
        state.isLoadingNewInteractions = true;
      })
      .addCase(getNewInteractions.fulfilled, (state, action) => {
        state.isLoadingNewInteractions = false;
        state.newInteractions = action.payload;
      })
      .addCase(getNewInteractions.rejected, (state) => {
        state.isLoadingNewInteractions = false;
        state.newInteractions = [];
      })
      .addCase(getDepartments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.departments = action.payload;
      })
      .addCase(getDepartments.rejected, (state) => {
        state.isLoading = false;
        state.departments = [];
      })
      .addCase(getProcurementFilterData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProcurementFilterData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.procurementFilterData = action.payload;
      })
      .addCase(getProcurementFilterData.rejected, (state) => {
        state.isLoading = false;
        state.procurementFilterData = {
          specific_emails: [],
          departments: [],
        };
      })
      .addCase(getDealValueRanges.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDealValueRanges.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dealValueRanges = action.payload;
      })
      .addCase(getDealValueRanges.rejected, (state) => {
        state.isLoading = false;
        state.dealValueRanges = [];
      })
      .addCase(getUser.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoadingUser = false;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoadingUser = false;
        state.user = null;
        login(action.payload.authenticity_token, action.payload.auth0_org_id);
      })
      .addCase(getNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notifications = action.payload;
      })
      .addCase(getNotifications.rejected, (state) => {
        state.isLoading = false;
        state.notifications = [];
      })
      .addCase(deleteNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notifications = action.payload;
      })
      .addCase(deleteNotification.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getInteractionsOverview.pending, (state) => {
        state.isLoadingOverview = true;
      })
      .addCase(getInteractionsOverview.fulfilled, (state, action) => {
        state.isLoadingOverview = false;
        state.interactionsOverview = action.payload;
      })
      .addCase(getInteractionsOverview.rejected, (state) => {
        state.isLoadingOverview = false;
      })
      .addCase(getInteractionsBackoffice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInteractionsBackoffice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.interactionsBackoffice = action.payload?.interactions || [];
        state.interactionsBackofficePagination = action.payload?.meta || {};
        state.interactionIdsBackoffice = state.interactionsBackoffice
          .map(i => i?.bundle?.id)
          .filter(Boolean);
      })
      .addCase(getInteractionsBackoffice.rejected, (state) => {
        state.isLoading = false;
        state.interactionsBackoffice = [];
        state.interactionsBackofficePagination = {};
        state.interactionIdsBackoffice = [];
      })
      .addCase(getSuppliers.pending, (state) => {
        state.isLoadingSuppliers = true;
      })
      .addCase(getSuppliers.fulfilled, (state, action) => {
        const page = action.payload?.meta?.current_page || 0;
        const newSuppliers = action.payload?.suppliers || [];
        state.isLoadingSuppliers = false;
        state.suppliers = page > 0 ? [...state.suppliers, ...newSuppliers] : newSuppliers;
        state.supplierIndustries = action.payload?.meta?.supplier_industries || [];
        state.supplierPagination = action.payload?.meta || {};
      })
      .addCase(getSuppliers.rejected, (state) => {
        state.isLoadingSuppliers = false;
        state.suppliers = [];
        state.supplierIndustries = [];
        state.supplierPagination = {};
      });
  },
});
export default appSlice.reducer;
