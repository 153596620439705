import { ShareOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import PopupMessage from './PopupMessage';

const ShareLink = () => {
  const [popupMessage, setPopupMessage] = useState('');

  const handleCopy = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setPopupMessage({
          message: 'Link copied to clipboard',
          isShowUndo: false,
          undoFunc: () => null,
        });
      })
      .catch(() => {
        setPopupMessage({
          message: 'Something went wrong',
          isShowUndo: false,
          undoFunc: () => null,
        });
      });
  };
  return (
    <>
      <IconButton type="button" sx={{ padding: '8px' }} onClick={handleCopy}>
        <ShareOutlined sx={{ color: '#2F236D73', fontSize: 24 }} />
      </IconButton>
      <PopupMessage
        open={Boolean(popupMessage.message)}
        message={popupMessage}
        close={() => setPopupMessage('')}
      />
    </>
  );
};

export default ShareLink;
