import React, { useEffect, useState } from 'react';
import { Box, Stack, Switch } from '@mui/material';
import CustomCard from '../../common/CustomCard';
import { getNotificationSettings, updateUser } from '../../common/DataHelper';

const Notifications = ({ role, user }) => {
  const [newInteractionUpdates, setNewInteractionUpdates] = useState(false);
  const [newTeamUpdates, setNewTeamUpdates] = useState(false);

  useEffect(() => {
    document.title = 'PartnerElement | Settings > Notifications';

    if (user?.email) {
      getNotificationSettings(user.email).then(settings => {
        setNewInteractionUpdates(settings.new_interaction_updates);
        setNewTeamUpdates(settings.new_team_updates);
      }).catch(error => {
        console.error('Error fetching notification settings:', error);
      });
    }
  }, [user?.email]);

  const handleToggleNewInteractionClick = (event) => {
    updateUser({
      email: user?.email,
      new_interaction_updates: event.target.checked,
    }).then(res => {
      setNewInteractionUpdates(res?.new_interaction_updates);
    }).catch(error => {
      console.error('Error updating new interaction updates:', error);
    });
  };

  const handleToggleNewTeamUpdatesClick = (event) => {
    updateUser({
      email: user?.email,
      new_team_updates: event.target.checked,
    }).then(res => {
      setNewTeamUpdates(res?.new_team_updates);
    }).catch(error => {
      console.error('Error updating new team updates:', error);
    });
  };

  return (
    <Box sx={{ width: '100%', height: 'fit-content' }}>
      <CustomCard>
        <Stack gap={'16px'} sx={{ padding: '16px' }}>
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
          >
            Email notifications
          </Box>
          <Box>
            <Stack direction={'row'} alignItems={'center'}>
              <Switch color={'purple'} onClick={handleToggleNewInteractionClick} checked={newInteractionUpdates} />
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.body1}
              >
                New interaction updates
              </Box>
            </Stack>
            <Box
              color={(theme) => theme.palette.text.medium}
              fontSize={(theme) => theme.typography.caption}
            >
              Get a weekly email about new interactions in your company.
            </Box>
          </Box>
          {/* TODO: Add procurement admin role to allowed */}
          {role === 'Admin' && (
            <Box>
              <Stack direction={'row'} alignItems={'center'}>
                <Switch color={'purple'} onClick={handleToggleNewTeamUpdatesClick} checked={newTeamUpdates} />
                <Box
                  color={(theme) => theme.palette.text.main}
                  fontSize={(theme) => theme.typography.body1}
                >
                  New team updates
                </Box>
              </Stack>
              <Box
                color={(theme) => theme.palette.text.medium}
                fontSize={(theme) => theme.typography.caption}
              >
                Get a weekly email about new teams in your company.
              </Box>
            </Box>
          )}
        </Stack>
      </CustomCard>
    </Box>
  );
};

export default Notifications;
