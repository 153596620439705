import React, { useState } from "react";
import moment from "moment/moment";

import { Box, Button, Popover, Stack } from "@mui/material";
import { ModeEditOutlineOutlined } from "@mui/icons-material";

import CustomDateCalendar from "./CustomDateCalendar";
import StyledIcon from "./StyledIcon";

const CalendarPopup = ({
  isOpen,
  setIsOpen,
  value,
  setValue,
  minDate,
  maxDate,
  minDateErrorMessage,
  maxDateErrorMessage,
}) => {
  const [error, setError] = useState("");

  return (
    <Popover
      open={isOpen}
      onClose={() => setIsOpen(false)}
      anchorPosition={{
        left: window.innerWidth / 2,
        top: window.innerHeight / 2,
      }}
      PaperProps={{
        sx: {
          width: "320px",
          borderRadius: "12px",
        },
      }}
      anchorReference={"anchorPosition"}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      <Box>
        <Box sx={{ padding: "15px 25px" }}>
          <Box
            sx={{ letterSpacing: "1px" }}
            fontSize={(theme) => theme.typography.subtitle1}
            color={(theme) => theme.palette.text.main}
          >
            Select date
          </Box>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box
              fontSize={(theme) => theme.typography.h4}
              color={(theme) => theme.palette.text.main}
            >
              {moment(value).format("DD MMMM")}
            </Box>
            <StyledIcon component={ModeEditOutlineOutlined} color={"icon"} />
          </Stack>
        </Box>
        <Box>
          <CustomDateCalendar
            minDate={minDate}
            maxDate={maxDate}
            value={value}
            setValue={setValue}
            error={error}
            setError={setError}
            minDateErrorMessage={minDateErrorMessage}
            maxDateErrorMessage={maxDateErrorMessage}
          />
        </Box>
        {error && (
          <Box
            sx={{ marginY: "8px", paddingX: "24px" }}
            color={(theme) => theme.palette.error.text}
            fontSize={(theme) => theme.typography.caption}
          >
            {error}
          </Box>
        )}
        <Stack
          direction={"row"}
          justifyContent={"end"}
          gap={"8px"}
          marginBottom={"8px"}
          marginRight={"8px"}
        >
          <Button
            onClick={() => setIsOpen(false)}
            variant={"text"}
            color={"secondary"}
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => setIsOpen(false)}
            variant={"text"}
            color={"secondary"}
            sx={{
              textTransform: "none",
              width: "fit-content",
              minWidth: "fit-content",
            }}
          >
            Ok
          </Button>
        </Stack>
      </Box>
    </Popover>
  );
};

export default CalendarPopup;
