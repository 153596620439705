import { Box, IconButton, Stack } from "@mui/material";
import StyledIcon from "./StyledIcon";
import {
  CheckCircleRounded,
  Flag,
  ModeEditOutlineOutlined,
  TaskRounded,
} from "@mui/icons-material";
import moment from "moment/moment";
import CalendarPopup from "./CalendarPopup";
import React from "react";

const lineStyle = {
  background: "#DEDBEC",
  width: "100%",
  height: "2px",
};

const TimeLine = ({
  interaction,
  isOpenProcurement,
  setIsOpenProcurement,
  isOpenDealClose,
  setIsOpenDealClose,
  timeProcurement,
  setTimeProcurement,
  timeDealClosed,
  setTimeDealClosed,
  withDates,
}) => {
  if (!interaction) {
    return <></>;
  }

  if (interaction?.procurement_involved) {
    return (
      <>
        {!withDates && (
          <Stack
            direction={"column"}
            alignItems={"center"}
            marginBottom={"16px"}
          >
            <Box
              fontSize={(theme) => theme.typography.subtitle1}
              color={(theme) => theme.palette.text.main}
            >
              {moment(timeDealClosed)
                .utc()
                .diff(moment(interaction?.start_time), "days")}{" "}
              days
            </Box>
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.medium}
            >
              Deal closure time
            </Box>
          </Stack>
        )}
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ paddingX: "55px" }}
        >
          <StyledIcon component={Flag} color={"icon"} />
          <Box sx={lineStyle} />
          <StyledIcon component={CheckCircleRounded} color={"icon_success"} />
          <Box sx={lineStyle} />
          <StyledIcon component={TaskRounded} color={"secondary"} />
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          paddingRight={"30px"}
        >
          <Box sx={{ width: "fit-content", textAlign: "center" }}>
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.main}
            >
              Interaction started
            </Box>
            <Box
              fontSize={(theme) => theme.typography.caption}
              color={(theme) => theme.palette.text.medium}
            >
              {moment(interaction?.start_time).format("MMM, DD")}
            </Box>
          </Box>
          <Box sx={{ width: "fit-content", textAlign: "center" }}>
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.main}
            >
              Procurement engaged
            </Box>
            <Stack
              width={"100%"}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                fontSize={(theme) => theme.typography.caption}
                color={(theme) => theme.palette.text.medium}
              >
                {moment(timeProcurement).format("MMM, DD")}
              </Box>
              <IconButton
                padding={"8px"}
                onClick={() => setIsOpenProcurement(true)}
              >
                <StyledIcon
                  component={ModeEditOutlineOutlined}
                  color={"secondary"}
                  sx={{ fontSize: "16px" }}
                />
              </IconButton>
              <CalendarPopup
                isOpen={isOpenProcurement}
                value={timeProcurement}
                setIsOpen={setIsOpenProcurement}
                setValue={setTimeProcurement}
                maxDate={timeDealClosed}
                minDate={moment(interaction?.start_time)}
                minDateErrorMessage={
                  "Please pick a date after the interaction start date."
                }
                maxDateErrorMessage={
                  "You can't use future dates. Select another date."
                }
              />
            </Stack>
          </Box>
          <Box sx={{ width: "fit-content", textAlign: "center" }}>
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.main}
            >
              Deal closed
            </Box>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                fontSize={(theme) => theme.typography.caption}
                color={(theme) => theme.palette.text.medium}
              >
                {moment(timeDealClosed).format("MMM, DD")}
              </Box>
              <IconButton
                padding={"8px"}
                onClick={() => setIsOpenDealClose(true)}
              >
                <StyledIcon
                  component={ModeEditOutlineOutlined}
                  color={"secondary"}
                  sx={{ fontSize: "16px" }}
                />
              </IconButton>
              <CalendarPopup
                isOpen={isOpenDealClose}
                value={timeDealClosed}
                setIsOpen={setIsOpenDealClose}
                setValue={setTimeDealClosed}
                minDate={timeProcurement}
                minDateErrorMessage={
                  "Please pick a date after the Procurement engaged date."
                }
              />
            </Stack>
          </Box>
        </Stack>
      </>
    );
  } else {
    return (
      <>
        {!withDates && (
          <Stack
            direction={"column"}
            alignItems={"center"}
            marginBottom={"16px"}
          >
            <Box
              fontSize={(theme) => theme.typography.subtitle1}
              color={(theme) => theme.palette.text.main}
            >
              {moment(timeDealClosed)
                .utc()
                .diff(moment(interaction?.start_time), "days")}{" "}
              days
            </Box>
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.medium}
            >
              Deal closure time
            </Box>
          </Stack>
        )}
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ paddingX: "55px" }}
        >
          <StyledIcon component={Flag} color={"icon"} />
          <Stack
            direction={"row"}
            alignItems={"center"}
            width={"100%"}
            gap={"4px"}
          >
            <Box sx={lineStyle} />
          </Stack>
          {withDates && (
            <Box
              sx={{ whiteSpace: "nowrap", paddingX: "4px" }}
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.medium}
            >
              {moment(timeDealClosed)
                .utc()
                .diff(moment(interaction?.start_time), "days")}{" "}
              days
            </Box>
          )}
          <Stack
            direction={"row"}
            alignItems={"center"}
            width={"100%"}
            gap={"4px"}
          >
            <Box sx={lineStyle} />
          </Stack>
          <StyledIcon component={TaskRounded} color={"secondary"} />
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          paddingRight={"30px"}
        >
          <Box sx={{ width: "fit-content", textAlign: "center" }}>
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.main}
            >
              Interaction started
            </Box>
            <Box
              fontSize={(theme) => theme.typography.caption}
              color={(theme) => theme.palette.text.medium}
            >
              {moment(interaction?.start_time).format("MMM, DD")}
            </Box>
          </Box>
          <Box sx={{ width: "fit-content", textAlign: "center" }}>
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.main}
            >
              Deal closed
            </Box>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                fontSize={(theme) => theme.typography.caption}
                color={(theme) => theme.palette.text.medium}
              >
                {moment(timeDealClosed).format("MMM, DD")}
              </Box>
              <IconButton
                padding={"8px"}
                onClick={() => setIsOpenDealClose(true)}
              >
                <StyledIcon
                  component={ModeEditOutlineOutlined}
                  color={"secondary"}
                  sx={{ fontSize: "16px" }}
                />
              </IconButton>
              <CalendarPopup
                minDate={moment(interaction?.start_time)}
                isOpen={isOpenDealClose}
                value={timeDealClosed}
                setIsOpen={setIsOpenDealClose}
                setValue={setTimeDealClosed}
                minDateErrorMessage={
                  "Please pick a date after the interaction start date."
                }
              />
            </Stack>
          </Box>
        </Stack>
      </>
    );
  }
};
export default TimeLine;
