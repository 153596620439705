import { Box } from '@mui/material';
import React from 'react';

const FilterFooter = () => {
  return (
    <Box
      fontSize={(theme) => theme.typography.subtitle2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Box
        component={'a'}
        href={'mailto:support@partnerelement.com?subject=Assistance'}
        sx={{
          color: 'secondary.main',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
      >
        Need help?
      </Box>
      <Box
        component={'a'}
        href={'https://partnerelement.com/privacy'}
        sx={{
          cursor: 'pointer',
          textDecoration: 'none',
          ':hover': { textDecoration: 'underline' },
        }}
        color={(theme) => theme.palette.text.main}
      >
        Privacy policy
      </Box>
      <Box
        component={'a'}
        href={'https://partnerelement.com/security'}
        sx={{
          cursor: 'pointer',
          textDecoration: 'none',
          ':hover': { textDecoration: 'underline' },
        }}
        color={(theme) => theme.palette.text.main}
      >
        Security policy
      </Box>
      <Box
        component={'a'}
        href={'https://partnerelement.com/terms'}
        sx={{
          cursor: 'pointer',
          textDecoration: 'none',
          ':hover': { textDecoration: 'underline' },
        }}
        color={(theme) => theme.palette.text.main}
      >
        Terms & Conditions
      </Box>
    </Box>
  );
};

export default FilterFooter;
