import { ArrowForwardIosRounded, ChangeCircleRounded, CheckCircleRounded, DoneRounded, MoreVert, TaskRounded, ViewTimelineOutlined, VisibilityOffRounded } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react'
import StyledIcon from '../../common/StyledIcon';
import { stageNames } from '../../utils/constants';

const InteractionsMoreMenu = ({interaction, engagementStage, updateInteraction, setPopupMessage, setIsOpenDealClosed}) => {
  const [openMenu, setOpenMenu] = useState(null);
  const [openDeprioritize, setOpenDeprioritize] = useState(null);
  const [openEngagementStage, setOpenEngagementStage] = useState(null);

  const closeMenu = () => {
    setOpenMenu(null);
    setOpenDeprioritize(null);
  };

  const onClickDeprioritized = (value, message) => {
    setOpenDeprioritize(null);
    updateInteraction(
      {
        deprioritization_reason: value,
      },
      interaction.id,
    )
      .then(() =>
        setPopupMessage({
          message: interaction?.procurement_involved
            ? 'Unmarked as Deprioritized'
            : `Deprioritized: ${message}`,
          isShowUndo: true,
          undoFunc: () => {
            updateInteraction(
              {
                deprioritization_reason: null,
              },
              interaction.id,
            ).then();
            setPopupMessage('');
          },
        }),
      )
      .catch();
  };

  return (
    <Box>
    <IconButton
      type="button"
      sx={{ color: '#2F236D73', fontSize: 24 }}
      onClick={(event) => setOpenMenu(event.currentTarget)}
    >
      <MoreVert />
    </IconButton>
    <Menu
      PaperProps={{
        sx: {
          width: '230px',
          borderRadius: '12px',
          paddingY: '12px',
        },
      }}
      open={Boolean(openMenu)}
      anchorEl={openMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={closeMenu}
    >
      <MenuItem
        onClick={() => {
          closeMenu();
          interaction?.deal_closed
            ? updateInteraction(
                {
                  deal_closed: false,
                  deal_closure_notes: '',
                  savings_amount: '',
                  savings_percentage: '',
                },
                interaction?.id,
              )
                .then(() =>
                  setPopupMessage({
                    message: 'Unmarked as Deal closed',
                    isShowUndo: true,
                    undoFunc: () => {
                      updateInteraction(
                        {
                          deal_closed: true,
                          deal_closure_notes:
                            interaction?.deal_closure_notes,
                          savings_amount:
                            interaction?.savings_amount,
                          savings_percentage:
                            interaction?.savings_percentage,
                        },
                        interaction?.id,
                      );
                      setPopupMessage('');
                    },
                  }),
                )
                .catch()
            : setIsOpenDealClosed(true);
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <StyledIcon
          component={TaskRounded}
          color={interaction?.deal_closed ? 'secondary' : 'icon'}
        />
        <Box
          color={(theme) => theme.palette.text.main}
          fontSize={(theme) => theme.typography.body2}
        >
          {interaction?.deal_closed ? 'Unmark' : 'Mark'} as Deal
          closed
        </Box>
      </MenuItem>
      {!interaction?.deal_closed && (
        <MenuItem
          onClick={() => {
            closeMenu();
            updateInteraction(
              {
                procurement_involved:
                  !interaction?.procurement_involved,
              },
              interaction?.id,
            )
              .then(() =>
                setPopupMessage({
                  message: interaction?.procurement_involved
                    ? 'Unmarked as Procurement engaged'
                    : 'Marked as Procurement engaged',
                  isShowUndo: true,
                  undoFunc: () => {
                    updateInteraction(
                      {
                        procurement_involved:
                          interaction?.procurement_involved,
                      },
                      interaction?.id,
                    ).then();
                    setPopupMessage('');
                  },
                }),
              )
              .catch();
          }}
          sx={{
            display: 'flex',
            alignItems: 'start',
            gap: '10px',
          }}
        >
          <CheckCircleRounded
            sx={{
              color: interaction?.procurement_involved
                ? '#4CAF50'
                : '#2F236D73',
              fontSize: 24,
            }}
          />
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.body2}
          >
            {interaction?.procurement_involved
              ? 'Unmark'
              : 'Mark'}{' '}
            as Procurement
            <Box>engaged</Box>
          </Box>
        </MenuItem>
      )}

      <MenuItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
        onClick={() => {
          closeMenu();
          updateInteraction(
            {
              renewal: !interaction?.renewal,
            },
            interaction?.id,
          )
            .then(() =>
              setPopupMessage({
                message: interaction?.renewal
                  ? 'Unmarked as Renewal'
                  : 'Marked as Renewal',
                isShowUndo: true,
                undoFunc: () => {
                  updateInteraction(
                    {
                      renewal: interaction?.renewal,
                    },
                    interaction?.id,
                  ).then();
                  setPopupMessage('');
                },
              }),
            )
            .catch();
        }}
      >
        <StyledIcon
          component={ChangeCircleRounded}
          color={interaction?.renewal ? 'icon_warning' : 'icon'}
        />
        <Box
          color={(theme) => theme.palette.text.main}
          fontSize={(theme) => theme.typography.body2}
        >
          {interaction?.renewal ? 'Unmark' : 'Mark'} as Renewal
        </Box>
      </MenuItem>
      {!interaction?.deal_closed && (
        <MenuItem
          onClick={(event) =>
            setOpenEngagementStage(event.currentTarget)
          }
          sx={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              gap: '10px',
            }}
          >
            <StyledIcon
              component={ViewTimelineOutlined}
              color={'icon'}
            />
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.body2}
            >
              Change stage
            </Box>
          </Box>
          <StyledIcon
            component={ArrowForwardIosRounded}
            color={'icon'}
            sx={{ fontSize: 16 }}
          />
        </MenuItem>
      )}
      <Menu
        PaperProps={{
          sx: {
            width: '230px',
            borderRadius: '12px',
            paddingY: '10px',
            ul: { padding: '0' },
          },
        }}
        anchorEl={openEngagementStage}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(openEngagementStage)}
        onClose={() => setOpenEngagementStage(null)}
        sx={{
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <MenuItem
          onClick={() => {
            updateInteraction(
              {
                stage: stageNames[0].label,
              },
              interaction?.id,
            )
              .then(() =>
                setPopupMessage({
                  message: (
                    <Box component={'span'}>
                      Stage changed to{' '}
                      <Box
                        component={'span'}
                        sx={{ fontWeight: 'bold' }}
                      >
                        {stageNames[0].label}
                      </Box>
                    </Box>
                  ),
                  isShowUndo: true,
                  undoFunc: () => {
                    updateInteraction(
                      {
                        stage: engagementStage,
                      },
                      interaction?.id,
                    ).then();
                    setPopupMessage('');
                  },
                }),
              )
              .catch();
            setOpenEngagementStage(null);
          }}
          value={stageNames[0].label}
          sx={{
            padding: '5px 20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '14px',
            color: '#000000DE',
          }}
        >
          <Box>{stageNames[0].label}</Box>
          {engagementStage === stageNames[0].label && (
            <StyledIcon component={DoneRounded} color="icon" />
          )}
        </MenuItem>
        <MenuItem
          onClick={() => {
            updateInteraction(
              {
                stage: stageNames[1].label,
              },
              interaction?.id,
            )
              .then(() =>
                setPopupMessage({
                  message: (
                    <Box component={'span'}>
                      Stage changed to{' '}
                      <Box
                        component={'span'}
                        sx={{ fontWeight: 'bold' }}
                      >
                        {stageNames[1].label}
                      </Box>
                    </Box>
                  ),
                  isShowUndo: true,
                  undoFunc: () => {
                    updateInteraction(
                      {
                        stage: engagementStage,
                      },
                      interaction?.id,
                    ).then();
                    setPopupMessage('');
                  },
                }),
              )
              .catch();
            setOpenEngagementStage(null);
          }}
          value={'Negotiation'}
          sx={{
            padding: '5px 20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '14px',
            color: '#000000DE',
          }}
        >
          <Box>{stageNames[1].label}</Box>
          {engagementStage === stageNames[1].label && (
            <StyledIcon component={DoneRounded} color="icon" />
          )}
        </MenuItem>
        <MenuItem
          onClick={() => {
            updateInteraction(
              {
                stage: stageNames[2].label,
              },
              interaction?.id,
            )
              .then(() =>
                setPopupMessage({
                  message: (
                    <Box component={'span'}>
                      Stage changed to{' '}
                      <Box
                        component={'span'}
                        sx={{ fontWeight: 'bold' }}
                      >
                        {stageNames[2].label}
                      </Box>
                    </Box>
                  ),
                  isShowUndo: true,
                  undoFunc: () => {
                    updateInteraction(
                      {
                        stage: engagementStage,
                      },
                      interaction?.id,
                    ).then();
                    setPopupMessage('');
                  },
                }),
              )
              .catch();
            setOpenEngagementStage(null);
          }}
          value={stageNames[2].label}
          sx={{
            padding: '5px 20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '14px',
            color: '#000000DE',
          }}
        >
          <Box>{stageNames[2].label}</Box>
          {engagementStage === stageNames[2].label && (
            <StyledIcon component={DoneRounded} color="icon" />
          )}
        </MenuItem>
      </Menu>
      <Box
        sx={{
          background: '#694FA31A',
          height: '1px',
          marginY: '8px',
          marginLeft: '50px',
        }}
      />

      <MenuItem
        onClick={(event) =>
          interaction?.deprioritization_reason
            ? updateInteraction(
                {
                  deprioritization_reason: null,
                },
                interaction?.id,
              ).then(() => {
                setPopupMessage({
                  message: 'Unmarked as Deprioritized',
                  isShowUndo: false,
                });
              })
            : setOpenDeprioritize(event.currentTarget)
        }
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <StyledIcon
            component={VisibilityOffRounded}
            color={
              interaction?.deprioritization_reason
                ? 'icon_error'
                : 'icon'
            }
          />
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.body2}
          >
            {interaction?.deprioritization_reason && 'Undo '}
            Deprioritize
          </Box>
        </Box>
        {!interaction?.deprioritization_reason && (
          <StyledIcon
            component={ArrowForwardIosRounded}
            color={'icon'}
            sx={{ fontSize: 16 }}
          />
        )}
      </MenuItem>
      <Menu
        open={Boolean(openDeprioritize)}
        anchorEl={openDeprioritize}
        PaperProps={{
          sx: {
            width: '246px',
            borderRadius: '12px',
            paddingY: '12px',
            ul: { padding: '0' },
            li: {
              paddingY: '4px 16px',
              fontSize: '14px',
              color: '#000000DE',
            },
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setOpenDeprioritize(null)}
      >
        <MenuItem
          onClick={() =>
            onClickDeprioritized(
              'irrelevant',
              'Irrelevant for procurement',
            )
          }
        >
          Irrelevant for procurement
        </MenuItem>
        <MenuItem
          onClick={() =>
            onClickDeprioritized('low_priority', 'Low priority')
          }
        >
          Low priority
        </MenuItem>
        <MenuItem
          onClick={() =>
            onClickDeprioritized(
              'not_a_supplier',
              'Not a supplier',
            )
          }
        >
          Not a supplier
        </MenuItem>
        <MenuItem
          onClick={() => onClickDeprioritized('other', 'Other')}
        >
          Other
        </MenuItem>
      </Menu>
    </Menu>
  </Box>)
}

export default InteractionsMoreMenu