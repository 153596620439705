import React from "react";
import { Alert } from "@mui/material";

const CustomAlert = ({ variant, message, severity, button, icon }) => {
  return (
    <Alert
      sx={{ borderRadius: "12px" }}
      variant={variant}
      severity={severity}
      icon={icon}
      action={button}
    >
      {message}
    </Alert>
  );
};

export default CustomAlert;
