import React from "react";
import {
  Box,
  Button,
  Dialog,
  Stack,
} from "@mui/material";

const WarningDeleteTemplate = ({ handleClose, handleConfirm, open }) => {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          width: "444px",
          top: "8%",
          position: "fixed",
          margin: "0",
        },
      }}
    >
      <Box>
        <Box sx={{ padding: "16px 24px 20px 24px" }}>
          <Box
            fontSize={(theme) => theme.typography.h6}
            color={(theme) => theme.palette.text.main}
            marginBottom={"16px"}
          >
            Are you sure you want to delete this template?
          </Box>
          <Box
            fontSize={(theme) => theme.typography.body1}
            color={(theme) => theme.palette.text.main}
          >
            <li>
                Once you delete this template it will be unavailable for all users.
            </li>
            <li>
                Messages already using this template will not be affected.
            </li>
          </Box>
        </Box>
        <Stack
          direction={"row"}
          justifyContent={"end"}
          gap={"8px"}
          sx={{ padding: "8px" }}
        >
          <Button
            onClick={handleClose}
            variant={"text"}
            color={"secondary"}
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant={"text"}
            color={"secondary"}
            sx={{ textTransform: "none" }}
          >
            Delete
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default WarningDeleteTemplate;