import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import CustomCard from "../../common/CustomCard";
import StyledIcon from "../../common/StyledIcon";
import { ReactComponent as DemographyIcon } from "../../common/icons/demographyIcon.svg";
import { ReactComponent as ShieldPerson } from "../../common/icons/shieldPersonIcon.svg";
import {
  ArrowBackRounded,
  SettingsOutlined,
  DescriptionOutlined,
  MailOutlineRounded,
  SupervisorAccountOutlined,
  ManageAccountsOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import GeneralSettings from "./GeneralSettings";
import AdminLog from "./AdminLog";
import Templates from "./Templates";
import Roles from "./Roles";
import { useTheme } from "@mui/material/styles";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Notifications from "./Notifications";
import EmailNotifications from "./EmailNotifications";
import InstantMessaging from "./InstantMessaging";
import StyledDivider from "../../common/StyledDivider";

const Settings = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.appSlice);

  const location = useLocation();

  const selectedSettingsArray = [
    { text: "General", path: "/settings", icon: SettingsOutlined },
    { text: "Teams roles", path: "/settings/roles", icon: SupervisorAccountOutlined },
    {
      text: "Notifications",
      path: "/settings/notifications",
      icon: MailOutlineRounded,
    },
    { text: "Templates", path: "/settings/templates", icon: DescriptionOutlined },
    { text: "Activity log", path: "/settings/admin-log", icon: (props) => <DemographyIcon fill={theme.palette[props.color].main} /> },
    { text: "Admin", icon: ManageAccountsOutlined, isParent: true },
    { text: "Email configuration", path: "/settings/communications/email", isChild: true },
  ];

  const initialTab = selectedSettingsArray.find(item => item.path == location.pathname);

  const [selectedSettings, setSelectedSettings] = useState(initialTab?.text || "General");

  useEffect(() => {
    document.title = 'PartnerElement | Settings';
  }, []);

  const viewPage = (props) => {
    return (
      <Routes>
        <Route path="/" element={<GeneralSettings {...props} />} />
        <Route path="/admin-log" element={<AdminLog {...props} />} />
        <Route path="/templates" element={<Templates {...props} />} />
        <Route path="/roles" element={<Roles {...props} />} />
        <Route path="/notifications" element={<Notifications {...props} />} />
        <Route path="/communications/email" element={<EmailNotifications {...props} />} />
        <Route path="/communications/instant-messaging" element={<InstantMessaging {...props} />} />
      </Routes>
    );
  };

  const renderMenuItem = (item, index) => {
    if (item.isParent) {
      return (
        <Typography
          key={index}
          color="rgb(101, 88, 177)"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            padding: "10px 16px",
            fontSize: theme.typography.subtitle2,
          }}
        >
          <StyledIcon component={item.icon} color="icon" />
          {item.text}
        </Typography>
      );
    } else {
      return (
        <Button
          key={index}
          onClick={() => {
            setSelectedSettings(item.text);
            navigate(item.path);
          }}
          color="secondary"
          variant="text"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            justifyContent: "start",
            background: selectedSettings === item.text ? "#694FA30A" : "transparent",
            textTransform: "none",
            borderRadius: "10px",
            padding: "10px 16px",
            ml: item.isChild ? 4 : 0,
            fontSize: theme.typography.subtitle2,
          }}
        >
          {item?.icon &&
            <StyledIcon component={item.icon} color={selectedSettings === item.text ? "secondary" : "icon"} />
          }
          {item.text}
        </Button>
      );
    }
  };

  return (
    <Stack
      flexDirection={"row"}
      gap={"24px"}
      sx={{
        background: "#FDFDFD",
        minHeight: "calc(100vh - 68px)",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          width: "fit-content",
          height: "fit-content",
          minWidth: "288px",
          maxWidth: "288px",
        }}
      >
        <CustomCard>
          <Box sx={{ padding: "16px" }}>
            <Stack flexDirection={"column"} gap={"24px"} marginBottom={"24px"}>
              <Stack flexDirection={"row"} alignItems={"center"} gap={"8px"}>
                <IconButton
                  type="button"
                  sx={{ padding: "0" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <StyledIcon component={ArrowBackRounded} color={"icon"} />
                </IconButton>
                <Box
                  color={(theme) => theme.palette.text.main}
                  fontSize={(theme) => theme.typography.h6}
                >
                  Settings
                </Box>
              </Stack>
              <Box fontSize={(theme) => theme.typography.subtitle2} color={(theme) => theme.palette.secondary.main}>
                {user?.organization}
              </Box>
              <Stack flexDirection={"row"} alignItems={"start"} gap={"16px"}>
                <Stack
                  sx={{
                    borderRadius: "50%",
                    background: "#0000000A",
                    width: "40px",
                    minWidth: "40px",
                    height: "40px",
                    minHeight: "40px",
                  }}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <ShieldPerson fill={theme.palette.icon.main} />
                </Stack>
                <Box color={(theme) => theme.palette.text.main}>
                  <Box fontSize={(theme) => theme.typography.subtitle2}>
                    {user?.name}
                  </Box>
                  <Box
                    fontSize={(theme) => theme.typography.body2}
                    sx={{ hyphens: "auto", wordBreak: "break-all" }}
                  >
                    {user?.email}
                  </Box>
                </Box>
              </Stack>
              <StyledDivider />
            </Stack>
            <Stack flexDirection="column" gap="4px">
              {selectedSettingsArray.map(renderMenuItem)}
            </Stack>
          </Box>
        </CustomCard>
      </Box>
      {viewPage({ role: user && user.roles[0], user })}
    </Stack>
  );
};

export default Settings;
