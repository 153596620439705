export function FetchWithCancel(url) {
  const controller = new AbortController();
  const { signal } = controller;

  const fetchPromise = (async () => {
    try {
      const response = await fetch(url, {
        signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      if (response.ok) {
        return await response.json();
      } else {
        return 'error';
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        return 'error';
      }
      throw error;
    }
  })();

  return {
    fetchPromise,
    cancel: () => controller.abort(),
  };
}
