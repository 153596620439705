import { createListenerMiddleware } from "@reduxjs/toolkit"
import { getUser } from "./storeFunctions";
import { FullStory } from '@fullstory/browser';
import posthog from 'posthog-js';
import { getFiltersInteractions, getFiltersOverview, getFiltersBundles, setCurrentUserOrg} from "./localStorage";
import { appSlice } from "./appSlice";


const userLoginMiddleware = createListenerMiddleware();

const { setFiltersOverview, setFiltersInteractions, setFiltersBundles } = appSlice.actions;


userLoginMiddleware.startListening({
  actionCreator: getUser.fulfilled,
  effect: async (action, listenerApi) => {

    FullStory('setProperties', {
      type: 'user',
      properties: {
        organization: action.payload?.organization,
      },
    });

    posthog?.group('company', action.payload?.organization);

    setCurrentUserOrg(action.payload?.organization);
    listenerApi.dispatch(setFiltersOverview(getFiltersOverview()));
    listenerApi.dispatch(setFiltersInteractions(getFiltersInteractions()));
    listenerApi.dispatch(setFiltersBundles(getFiltersBundles()));

  }
})

export default userLoginMiddleware;