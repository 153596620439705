import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { NotInterested, Check, HighlightAlt } from '@mui/icons-material';

const buttonConfigs = [
    {
        statusValue: 0, // NOT_SUPPLIER
        apiValue: 'false',
        label: 'No',
        Icon: NotInterested,
        color: '#ef5350',
        backgroundColor: '#ffcccb',
        hoverBackgroundColor: '#ffcccb',
        hoverBorderColor: '#ef5350',
    },
    {
        statusValue: 2, // UNKNOWN_SUPPLIER
        apiValue: 'unknown',
        label: "Don't know",
        Icon: HighlightAlt,
        color: 'white',
        backgroundColor: '#999',
        hoverBackgroundColor: '#aaa',
        hoverBorderColor: '#666',
    },
    {
        statusValue: 1, // IS_SUPPLIER
        apiValue: 'true',
        label: 'Yes',
        Icon: Check,
        color: 'white',
        backgroundColor: '#4caf50',
        hoverBackgroundColor: '#66bb6a',
        hoverBorderColor: '#4caf50',
    },
];

const ReviewButtonGroup = ({ id, reviewStatus, onReviewUpdate }) => {
    const apiUrl = `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/bundles`;

    const handleReviewClick = async (isSupplierStatus, supplierManualValue) => {
        try {
            const response = await fetch(`${apiUrl}/${id}/review/?is_supplier=${isSupplierStatus}`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });

            if (response.ok) {
                const data = await response.json();

                onReviewUpdate(supplierManualValue);
            } else {
                throw new Error('Failed to update review status');
            }
        } catch (error) {
            console.error(`Error calling the API for ${id}, ${isSupplierStatus}:`, error);
        }
    };

    return (
        <ButtonGroup variant="outlined" aria-label="outlined button group">
            {buttonConfigs.map(({ statusValue, apiValue, label, Icon, color, backgroundColor, hoverBackgroundColor, hoverBorderColor }, index) => (
                <Button
                    key={label}
                    onClick={() => handleReviewClick(apiValue, statusValue)}
                    sx={{
                        border: "1px solid grey",
                        color: reviewStatus === statusValue ? color : "grey",
                        background: reviewStatus === statusValue ? backgroundColor : "white",
                        '&:hover': {
                            backgroundColor: reviewStatus === statusValue ? hoverBackgroundColor : '#f0f0f0',
                            color: reviewStatus === statusValue ? color : 'grey',
                            borderColor: reviewStatus === statusValue ? hoverBorderColor : 'grey',
                        },
                        borderRadius: (index === 0) ? '10px 0 0 10px' :
                            (index === buttonConfigs.length - 1) ? '0 10px 10px 0' : 0,
                    }}
                >
                    <Icon sx={{ fontSize: 16 }} />&nbsp;{label}
                </Button>
            ))}
        </ButtonGroup>
    );
};

export default ReviewButtonGroup;
