import * as React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';

const StyledTableSortLabel = styled(TableSortLabel)(({ theme, active }) => ({
  '& .MuiTableSortLabel-icon': {
    opacity: 1,
    color: active
      ? `${theme.palette.secondary.main} !important`
      : 'rgba(48, 37, 103, 0.35)',
    marginRight: '-4px',
  },
}));

export default function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells, tableRowSx, tableCellSx } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={tableRowSx}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={
              tableCellSx
                ? {}
                : {
                    display: 'table-cell',

                    minWidth: headCell.width,
                    verticalAlign: 'bottom',
                    textAlign: headCell.align || 'right',
                  }
            }
          >
            <Box
              sx={
                tableCellSx
                  ? tableCellSx
                  : {
                      width: '100%',
                    }
              }
            >
              {headCell.sort ? (
                <StyledTableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </StyledTableSortLabel>
              ) : (
                <Box>{headCell.label}</Box>
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
