import React from "react";
import { Box } from "@mui/material";

const ConfirmCopy = ({ message, positionStyles }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        left: "0",
        background: "rgba(0,0,0,0.6)",
        color: "white",
        fontSize: "12px",
        padding: "8px",
        borderRadius: "4px",
        whiteSpace: "nowrap",
        ...positionStyles,
      }}
    >
      {message}
    </Box>
  );
};

export default ConfirmCopy;
