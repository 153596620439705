import { Box } from "@mui/material";
import moment from "moment";

const DateTooltip = ({ active, payload, dates, valueKey }) => {
  if (active && payload && payload.length) {
    const label = valueKey ? payload[0].payload[valueKey] : moment(dates[payload[0].payload.index]).format("DD MMM YY");
    return (
      <Box
        fontSize={(theme) => theme.typography.body2.fontSize}
        color={(theme) => theme.palette.text.medium}
        sx={{
          position: "relative",
          background: "white",
          padding: "4px 12px",
          borderRadius: "6px",
          boxShadow:
            "0px 1.850000023841858px 6.25px 0px rgba(0, 0, 0, 0.19), 0px 0.5px 1.75px 0px rgba(0, 0, 0, 0.04)",
          right: "calc(50% + 10px)",
          top: "-64px",
          "::after": {
            content: "''",
            position: "absolute",
            width: "14px",
            height: "14px",
            background: "white",
            transform: "translateX(-50%) translateY(-50%) rotate(-45deg)",
            top: "100%",
            left: "50%",
            boxShadow:
              "0px 1.850000023841858px 6.25px 0px rgba(0, 0, 0, 0.19), 0px 0.5px 1.75px 0px rgba(0, 0, 0, 0.04)",
            clipPath: "polygon(0 100%, 0 0, 100% 100%, 0 100%)",
          },
        }}
      >
        <div>{label}</div>
      </Box>
    );
  }

  return null;
};

export default DateTooltip;
