import React from 'react';
import { Box } from '@mui/material';
import { variantBorderRadius } from "../../common/EngagementStageBlock";
import { stageNames } from "../../utils/constants";

const InteractionStageGraph = ({ item }) => {
    const getStyle = (stage, percentage) => ({
        width: `${percentage}%`,
        height: "13px",
        background: stage === 'exploration' ? "#9EE0A6" : stage === 'negotiation' ? "#F2D073" : "#FFB4A2",
        opacity: "0.7",
        borderRadius: variantBorderRadius(
            stageNames.find(s => s.label.toLowerCase() === stage)?.label,
            {
                exploration_stage: item?.exploration_stage,
                negotiation_stage: item?.negotiation_stage,
                closing_stage: item?.closing_stage,
            },
            "small"
        ).borderRadius,
        borderRight: stage !== 'closing' && item?.closing_stage !== 0 ? "0.5px solid white" : "",
    });

    return (
        <>
            <Box sx={getStyle('exploration', (item.exploration_stage / item.total_interactions) * 100)} />
            <Box sx={getStyle('negotiation', (item.negotiation_stage / item.total_interactions) * 100)} />
            <Box sx={getStyle('closing', (item.closing_stage / item.total_interactions) * 100)} />
        </>
    );
};

export default InteractionStageGraph;
