import React from "react";

import { Box, IconButton, Modal, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CloseRounded } from "@mui/icons-material";

import StyledIcon from "../../common/StyledIcon";

import { capitalizeFirst } from "../../utils/functions";
import TeamNameWithIcon from "../../common/TeamNameWithIcon";

const ProcurementModal = ({ total, open, handleClose, items }) => {
  const theme = useTheme();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "fit-content",
          minWidth: "720px",
          maxWidth: "1080px",
          maxHeight: "640px",
          overflow: "auto",
          background: "white",
          position: "absolute",
          marginTop: "35px",
          top: "0",
          left: "calc(50% - 360px)",
          borderRadius: "12px",
          padding: "24px",
        }}
      >
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          marginBottom={"4px"}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
          >
            Teams engaging procurement
          </Box>
          <IconButton padding={"8px"} onClick={handleClose}>
            <StyledIcon component={CloseRounded} color={"icon"} />
          </IconButton>
        </Stack>
        <Box
          marginBottom={"24px"}
          sx={{ width: "100%" }}
          color={(theme) => theme.palette.text.medium}
        >
          {total} teams total
        </Box>
        <Box>
          <Box sx={{ maxHeight: "640px", overflowY: "auto" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                marginBottom: "16px",
              }}
            >
              {items?.map((item, index) => (
                <Box key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <TeamNameWithIcon teamName={capitalizeFirst(item?.department_name)} />
                    
                    <Box
                      fontSize={(theme) => theme.typography.body2}
                      color={(theme) => theme.palette.text.black}
                    >
                      {Math.round(item?.total_percent)}%
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: "#5056A914",
                      height: "10px",
                      borderRadius: "38px",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#5056A9",
                        height: "10px",
                        width: `${item?.total_percent}%`,
                        borderRadius: "38px",
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProcurementModal;
