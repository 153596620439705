import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { Edit as EditIcon, Close as CloseIcon } from '@mui/icons-material';

const ContractNameEditor = ({ contract, onSave }) => {
  const [editableName, setEditableName] = useState(contract.name);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setEditableName(contract.name);
  }, [contract]);

  useEffect(() => {
    if (!isEditing && editableName !== contract.name) {
      updateContract(editableName, contract.id).then((res) => {
        if (res !== 'error') {
          onSave(res);
        }
      });
    }
  }, [isEditing]);

  const toggleIsEditing = (event) => {
    event?.stopPropagation();
    setIsEditing(!isEditing);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setEditableName(value);
  };

  const handleCancel = () => {
    setEditableName(contract.name);
    toggleIsEditing();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      toggleIsEditing();
    }
  };

  const updateContract = async (name, id) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/contracts/${id}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({ name }),
      },
    );

    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {isEditing ? (
        <Box
          sx={{
            display: 'flex',
            border: '1px solid #c4c4c4',
            borderRadius: '10px',
            alignItems: 'center',
            gap: '8px',
            padding: '2px',
            paddingLeft: '4px',
            width: '100%',
          }}
          onClick={(event) => event.stopPropagation()}
        >
          <TextField
            value={editableName}
            onChange={handleChange}
            type="text"
            size="small"
            variant="standard"
            onKeyDown={handleKeyDown}
            multiline
            InputProps={{
              disableUnderline: true,
            }}
            sx={{ fontSize: '16px', flex: 1 }}
            inputProps={{
              style: { padding: 0 },
            }}
          />
          <Button
            variant="text"
            color="secondary"
            onClick={toggleIsEditing}
            sx={{
              padding: '6px 16px',
              fontSize: '0.875rem',
              textTransform: 'none',
            }}
          >
            Save
          </Button>
          <IconButton size="small" onClick={handleCancel}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Box component={'span'}>{editableName}</Box>
          <IconButton
            variant="text"
            size="small"
            color="secondary"
            sx={{ fontSize: '14px', fontFamily: 'Roboto' }}
            onClick={toggleIsEditing}
          >
            <EditIcon fontSize="small" />
            Edit name
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ContractNameEditor;
