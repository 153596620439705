import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Stack,
  OutlinedInput,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import CustomCard from "../../common/CustomCard";
import StyledIcon from "../../common/StyledIcon";
import { InfoOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getDepartments } from "../../store/storeFunctions";
import useDebounce from "../../hooks/useDebounce";
import Notifications from "../../common/Notifications";
import EmailListInput from "./EmailListInput";
import InfoMessage from "../../common/InfoMessage";
import CurrencyInput from "./CurrencyInput";
import { appSlice } from "../../store/appSlice";

const defaultStylesForCard = {
  card: { padding: "16px" },
  title: {
    fontSize: (theme) => theme.typography.h6,
    color: (theme) => theme.palette.text.main,
    marginBottom: "16px",
  },
  subtitle: {
    fontSize: (theme) => theme.typography.subtitle1,
    color: (theme) => theme.palette.text.main,
    marginBottom: "16px",
  },
  titleSlider: {
    fontSize: (theme) => theme.typography.subtitle2,
    color: (theme) => theme.palette.text.main,
    marginBottom: "32px",
  },
  slider: {
    color: "secondary",
  },
  fields: { sx: { borderRadius: "10px" }, color: "secondary" },
};

const GeneralSettings = ({ role }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { departments, user } = useSelector((state) => state.appSlice);

  const { clearRangesFilterInteractions, clearRangesFilterOverview } = appSlice.actions;

  const [thresholdOption, setThresholdOption] = useState("none");
  const [singleThreshold, setSingleThreshold] = useState("");
  const [lowerThreshold, setLowerThreshold] = useState("");
  const [upperThreshold, setUpperThreshold] = useState("");

  const debounceSingleThreshold = useDebounce(singleThreshold, 200);
  const debounceLowerThreshold = useDebounce(lowerThreshold, 200);
  const debounceUpperThreshold = useDebounce(upperThreshold, 200);

  useEffect(() => {
    document.title = "PartnerElement | Settings > General";
    dispatch(getDepartments());
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const [defaultSettings, setDefaultSettings] = useState({
    data_look_back_days: "",
    domain_name: "",
    email_provider: "",
    external_domains_to_exclude: [],
    groups_to_exclude: [],
    min_outbound_emails: 0,
    min_total_emails: 0,
    procurement_department_id: "",
    procurement_group_email: "",
    target_department_ids: [],
    users_to_exclude: [],
    procurement_specific_emails: [],
    deal_value_option: 'none',
    deal_value_breakpoints: [],
  });
  const [settings, setSettings] = useState({
    data_look_back_days: "",
    domain_name: "",
    email_provider: "",
    external_domains_to_exclude: [],
    groups_to_exclude: [],
    min_outbound_emails: 0,
    min_total_emails: 0,
    procurement_department_id: "",
    procurement_group_email: "",
    target_department_ids: [],
    users_to_exclude: [],
    procurement_specific_emails: [],
    deal_value_option: 'none',
    deal_value_breakpoints: [],
  });

  useEffect(() => {
    const breakpoints = settings.deal_value_breakpoints || [];
    if (breakpoints.length === 0) {
      setThresholdOption('none');
    } else if (breakpoints.length === 1) {
      setThresholdOption('one');

      setSingleThreshold(breakpoints[0]);
    } else if (breakpoints.length === 2) {
      setThresholdOption('two');

      setLowerThreshold(breakpoints[0]);
      setUpperThreshold(breakpoints[1]);
    }
  }, [settings.deal_value_breakpoints]);


  const [domain, setDomain] = useState(settings?.domain_name);

  const [emailList, setEmailList] = useState(settings?.procurement_specific_emails || []);

  const debounceDomain = useDebounce(domain, 500);

  const [groupEmail, setGroupEmail] = useState(
    settings?.procurement_group_email
  );

  const debounceGroup = useDebounce(groupEmail, 500);

  useEffect(() => {
    setSettings((prev) => ({
      ...prev,
      domain_name: debounceDomain,
    }));
  }, [debounceDomain]);

  useEffect(() => {
    setSettings((prev) => ({
      ...prev,
      procurement_group_email: debounceGroup,
    }));
  }, [debounceGroup]);

  useEffect(() => {
    const validateAndSetBreakpoints = () => {
      if (thresholdOption === 'none') {
        setSettings(prev => ({ ...prev, deal_value_option: 'none', deal_value_breakpoints: [] }));
      } else if (thresholdOption === 'one' && isValidThreshold(debounceSingleThreshold)) {
        setSettings(prev => ({ ...prev, deal_value_option: 'one', deal_value_breakpoints: [Number(debounceSingleThreshold)] }));
      } else if (thresholdOption === 'two' && isValidThreshold(debounceLowerThreshold) && isValidThreshold(debounceUpperThreshold) && Number(debounceLowerThreshold) < Number(debounceUpperThreshold)) {
        setSettings(prev => ({ ...prev, deal_value_option: 'two', deal_value_breakpoints: [Number(debounceLowerThreshold), Number(debounceUpperThreshold)] }));
      }
    };

    validateAndSetBreakpoints();
  }, [debounceSingleThreshold, debounceLowerThreshold, debounceUpperThreshold, thresholdOption]);

  const isValidThreshold = (formattedValue) => {
    if (!formattedValue) {
      return false;
    }

    const stringValue = String(formattedValue).replace(/,/g, '');

    const value = parseInt(stringValue, 10);
    return !isNaN(value) && value > 0;
  };

  const handleThresholdOptionChange = (event) => {
    setThresholdOption(event.target.value);
  };

  const handleEmailsChange = useCallback((items) => {
    setSettings((prev) => ({
      ...prev,
      procurement_specific_emails: items,
    }));
  }, []);

  const getData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/settings`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "GET",
        credentials: "include",
      }
    );
    if (response.ok) {
      return await response.json();
    } else {
      return "error";
    }
  };

  const updateSettings = async (data) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/settings`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
    if (response.ok) {
      return await response.json();
    } else {
      return "error";
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getData().then((res) => {
      res.deal_value_option = 'none';
      if (res?.deal_value_breakpoints?.length) {
        res.deal_value_option = res?.deal_value_breakpoints?.length > 1 ? 'two' : 'one';
      }
      setDefaultSettings(res);
      setEmailList(res.procurement_specific_emails || []);
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (defaultSettings) {
      setSettings(defaultSettings);
      setDomain(defaultSettings?.domain_name);
      setGroupEmail(defaultSettings?.procurement_group_email);
      setEmailList(defaultSettings?.procurement_specific_emails || []);
    }
  }, [defaultSettings]);

  useEffect(() => {
    if (Boolean(JSON.stringify(settings) !== JSON.stringify(defaultSettings))) {
      updateSettings(settings).then(() => {
        if (defaultSettings.deal_value_option !== settings.deal_value_option ||
          JSON.stringify(defaultSettings.deal_value_breakpoints) !== JSON.stringify(settings.deal_value_breakpoints)) {
          dispatch(clearRangesFilterOverview());
          dispatch(clearRangesFilterInteractions());
        }
      });
    }
  }, [settings]);

  if (isLoading || !defaultSettings) {
    return <></>;
  }

  return (
    <>
      <Stack flexDirection={"column"} gap={"24px"} sx={{ width: "100%" }}>
        {role !== "admin" && (
          <Stack
            flexDirection={"row"}
            gap={"8px"}
            alignItems={"center"}
            color={(theme) => theme.palette.info.main}
            sx={{
              border: `1px solid ${theme.palette.info.main}`,
              borderRadius: "10px",
              padding: "8px 16px",
            }}
          >
            <StyledIcon component={InfoOutlined} color={"info"} />
            <Box
              color={(theme) => theme.palette.alert.main}
              fontSize={(theme) => theme.typography.body2}
            >
              Contact arina@partnerelement.com to change your settings.
            </Box>
          </Stack>
        )}
        <Notifications />
        <CustomCard>
          <Box sx={{ ...defaultStylesForCard.card }}>
            <Box {...defaultStylesForCard.title}>Procurement team</Box>
            <Stack flexDirection={"column"} gap={"24px"} sx={{ width: "50%" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="unit" color={"secondary"}>
                  Team:
                </InputLabel>
                <Select
                  value={settings?.procurement_department_id}
                  onChange={(event) =>
                    setSettings((prev) => ({
                      ...prev,
                      procurement_department_id: event.target.value,
                    }))
                  }
                  disabled={role !== "admin"}
                  id={"unit"}
                  label={"Team"}
                  MenuProps={{ PaperProps: { sx: { maxHeight: "200px" } } }}
                  {...defaultStylesForCard.fields}
                >
                  {departments.map((item) => (
                    <MenuItem value={item.path} key={item.path}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor=" groupEmail" color={"secondary"}>
                  Group email:
                </InputLabel>
                <OutlinedInput
                  value={groupEmail}
                  onChange={(event) => setGroupEmail(event.target.value)}
                  // DEV-138 disabled as we currently don't have backend support for it.
                  // Additional scope is required to retrieve the group participants.
                  disabled={true || role !== "admin"}
                  id={"Group email"}
                  label={"Group email"}
                  {...defaultStylesForCard.fields}
                />
              </FormControl>

              <FormControl sx={{ width: "100%" }}>
                <EmailListInput
                  id={"teamEmails"}
                  emails={emailList}
                  setEmails={setEmailList}
                  label={"Specific emails:"}
                  disabled={role !== "admin"}
                  onEmailsChange={handleEmailsChange}
                />
              </FormControl>
            </Stack>
          </Box>
        </CustomCard>

        <CustomCard>
          <Box sx={{ ...defaultStylesForCard.card }}>
            <Box {...defaultStylesForCard.title} sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0",
            }}>
              Deal value threshold
              <InfoMessage
                message={
                  `Set deal value thresholds to customize the filtering of interactions based on their detected and assigned deal values`
                }
              />
            </Box>
            <Box sx={{
              marginBottom: "8px",
            }}>
              <Typography color={(theme) => theme.palette.text.caption} fontSize={(theme) => theme.typography.body1}>
                Set threshold to display deal value in Filters and interaction details.
              </Typography>
            </Box>
            <Stack>
              <RadioGroup
                value={thresholdOption}
                onChange={handleThresholdOptionChange}
                name="threshold-option-group"
              >
                <FormControlLabel disabled={role !== "admin"} value="one" control={<Radio color={'secondary'} />} label="Single threshold" />
                {thresholdOption === "one" && (
                  <CurrencyInput disabled={role !== "admin"} label="Threshold" value={singleThreshold} onChange={(value) => setSingleThreshold(value)} />
                )}
                <FormControlLabel disabled={role !== "admin"} value="two" control={<Radio color={'secondary'} />} label="Double threshold" />
                {thresholdOption === "two" && (
                  <Stack direction={'row'} spacing={2}>
                    <CurrencyInput disabled={role !== "admin"} label="Lower threshold" value={lowerThreshold} onChange={(value) => setLowerThreshold(value)} />
                    <CurrencyInput disabled={role !== "admin"} label="Upper threshold" value={upperThreshold} onChange={(value) => setUpperThreshold(value)} />
                  </Stack>
                )}
                <FormControlLabel disabled={role !== "admin"} value="none" control={<Radio color={'secondary'} />} label="Hide deal value" />
              </RadioGroup>
            </Stack>
          </Box>
        </CustomCard>
      </Stack>
    </>
  );
};

export default GeneralSettings;
