import { ArrowForwardRounded, ChatOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCard from '../../common/CustomCard';
import Logo from '../../common/Logo';
import StyledIcon from '../../common/StyledIcon';
import { ReactComponent as Teams } from '../../common/icons/teams.svg';
import SupplierBadges from '../../common/SupplierBadges.js';

const mostRecentInteractionTime = (supplier) => {
  return (
    supplier.interactions?.length &&
    supplier.interactions.reduce((mostRecent, interaction) => {
      const currentTime = interaction.end_time;
      if (moment(currentTime).isAfter(mostRecent)) return currentTime;
      return mostRecent;
    }, moment(supplier.interactions[0].end_time))
  );
};

export const isActiveInteraction = (supplier) => {
  return moment(mostRecentInteractionTime(supplier)).isAfter(
    moment().subtract(7, 'days'),
  );
};

const SupplierItem = ({ supplier }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const hasInteractions = Boolean(supplier.interactions.length);

  const allDepartmentsInvolved = supplier.interactions
    .map((interaction) => interaction.departments_involved)
    .flat();
  const departmentsInvolved = [...new Set(allDepartmentsInvolved)].sort(
    (a, b) => a.localeCompare(b),
  );

  const stakeholdersInvolved = supplier.interactions.map((interaction) =>
    interaction.most_active_stakeholder?.substr(
      interaction.most_active_stakeholder?.indexOf(',') + 1,
    ),
  );

  const detailsLink = `details/supplier/${supplier.id}${search}`;

  return (
    <CustomCard isHover={true} onClick={() => navigate(detailsLink)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          gap: '10px',
          borderBottom: '1px solid #DEDBEC',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
              width: 'calc(100% / 3)',
            }}
          >
            <Logo size={54} img={supplier.image_url} />
            <Box
              color={(theme) => theme.palette.text.main}
              sx={{
                lineHeight: '150%',
              }}
              fontSize={(theme) => theme.typography.subtitle1}
            >
              {supplier.name}
            </Box>
          </Box>
          <Stack
            width={'66%'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <SupplierBadges supplier={supplier} gap={'4px'} />
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton type="button" sx={{ padding: '8px' }}>
                <StyledIcon component={ArrowForwardRounded} color={'icon'} />
              </IconButton>
            </Box>
          </Stack>
        </Box>
        {supplier.categories && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '10px',
              width: 'calc(100% / 3)',
            }}
          >
            {supplier.categories.map((item, index) => (
              <Box
                key={index}
                sx={{
                  border: '1px solid rgba(2, 136, 209, 0.5)',
                  borderRadius: '100px',
                  padding: '4px 10px',
                  fontSize: '13px',
                  color: '#0288D1',
                }}
              >
                {item}
              </Box>
            ))}
          </Box>
        )}
      </Box>
      {hasInteractions ? (
        <Box sx={{ padding: '16px', display: 'flex' }}>
          <Box
            color={(theme) => theme.palette.text.medium}
            fontSize={(theme) => theme.typography.body2}
            sx={{
              width: 'calc(100% / 3)',
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
            }}
          >
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.body2}
              sx={{
                marginBottom: '8px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <Teams />
              <Box sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
                {departmentsInvolved.map((item, index) => (
                  <Box key={index} fontSize={(theme) => theme.typography.body1}>
                    {item}
                    {index < departmentsInvolved.length - 1 && ','}
                  </Box>
                ))}
              </Box>
            </Box>
            {stakeholdersInvolved.length && (
              <Box color={(theme) => theme.palette.text.medium}>
                Most active:{' '}
                <Box component={'span'} sx={{ mr: 4 }}>
                  <Box
                    component={'span'}
                    color={(theme) => theme.palette.text.main}
                  >
                    {stakeholdersInvolved[0]}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              width: 'calc(100% / 3)',
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
            }}
          >
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.body2}
              sx={{
                marginBottom: '8px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <StyledIcon component={ChatOutlined} color={'icon'} />
              <Box fontSize={(theme) => theme.typography.body1}>
                Last active
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              {moment(mostRecentInteractionTime(supplier)).format(
                'MMM DD, YYYY',
              )}
            </Box>
          </Box>
        </Box>
      ) : (<Box sx={{height: '88px'}} />)}
    </CustomCard>
  );
};

export default SupplierItem;
