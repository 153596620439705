import { Box, IconButton, Modal, Stack } from '@mui/material';
import React, { useState } from 'react';
import StyledIcon from '../../common/StyledIcon';
import { CloseRounded, KeyboardArrowDownRounded } from '@mui/icons-material';
import { CollapseItem } from './EngagementTimingByPeople';
import EngagementTimingLine from './EngagementTimingLine';

const EngagementTimingByPeopleModal = ({
  open,
  handleClose,
  collection,
  peopleDetail,
  requestDetail,
}) => {
  const [collapseItems, setCollapseItems] = useState([]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 'fit-content',
          minWidth: '1080px',
          maxWidth: '1080px',
          maxHeight: '640px',
          overflow: 'auto',
          background: 'white',
          position: 'absolute',
          marginTop: '35px',
          top: '0',
          left: 'calc(50% - 415px)',
          borderRadius: '12px',
          padding: '24px',
        }}
      >
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginBottom={'4px'}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
          >
            Engagement timing by people
          </Box>
          <IconButton padding={'8px'} onClick={handleClose}>
            <StyledIcon component={CloseRounded} color={'icon'} />
          </IconButton>
        </Stack>
        <Stack
          marginBottom={'24px'}
          width={'100%'}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'end'}
        >
          <Box color={(theme) => theme.palette.text.medium}>
           {`${collection.length} employee${collection?.length === 1 ? '' : 's'} total`}
          </Box>
        </Stack>
        <Stack flexDirection={'column'} gap={'16px'}>
          {collection?.map((item) => (
            <Box width={'100%'} key={item?.user_name}>
              <Stack
                flexDirection={'row'}
                alignItems={'center'}
                width={'100%'}
                height={'40px'}
              >
                <Stack width={'100%'} gap={'4px'} flexDirection={'column'}>
                  <Box sx={{ whiteSpace: 'nowrap' }}>
                    {`${item.user_name} (${item?.count} request${item?.count === 1 ? '' : 's'}, ${item?.price_dollars_usd})`}
                  </Box>
                  <Stack flexDirection={'row'}>
                    <EngagementTimingLine item={item} />
                  </Stack>
                </Stack>

                <Stack
                  key={item?.user_name}
                  flexDirection={'row'}
                  alignItems={'center'}
                  gap={'8px'}
                  marginLeft={'16px'}
                >
                  <IconButton
                    sx={{
                      padding: '8px',
                      transform:
                        collapseItems.includes(item?.user_name) &&
                        'rotate(-180deg)',
                      transition: 'transform 300ms',
                    }}
                    onClick={() =>
                      setCollapseItems((prevState) =>
                        collapseItems.includes(item.user_name)
                          ? prevState.filter((i) => i !== item.user_name)
                          : [...prevState, item.user_name],
                      )
                    }
                  >
                    <StyledIcon
                      component={KeyboardArrowDownRounded}
                      color={'icon'}
                    />
                  </IconButton>
                </Stack>
              </Stack>
              {collapseItems.includes(item?.user_name) && (
                <CollapseItem
                  label={item.user_name}
                  collection={peopleDetail}
                  requestDetail={requestDetail}
                />
              )}
            </Box>
          ))}
        </Stack>
      </Box>
    </Modal>
  );
};

export default EngagementTimingByPeopleModal;
