import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const CurrencyInput = ({ label, value, onChange, disabled }) => {
    const [displayValue, setDisplayValue] = useState('');

    function formatCurrency(num) {
        if (num === null || num === undefined || isNaN(num)) {
            return '';
        }

        return num.toLocaleString('en-US');
    }

    useEffect(() => {
        setDisplayValue(formatCurrency(value));
    }, [value]);

    function unformatCurrency(formattedValue) {
        return parseInt(formattedValue.replace(/,/g, ''), 10);
    }

    const handleChange = (event) => {
        const val = event.target.value.replace(/[^0-9,]/g, '');
        setDisplayValue(val);
        const unformattedValue = unformatCurrency(val);
        if (!isNaN(unformattedValue)) {
            onChange(unformattedValue);
        }
    };

    return (
        <TextField
            label={label}
            value={displayValue}
            onChange={handleChange}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            inputProps={{
                style: { textAlign: 'right' },
            }}
            style={{ width: 130, marginBottom: '8px' }}
            disabled={disabled}
        />
    );
};

export default CurrencyInput;
