import React, { useEffect, useRef, useState } from "react";
import { Box, Chip } from "@mui/material";
import {
  CheckCircleRounded,
  ChangeCircleRounded,
  LanOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomDropMenu from "./CustomDropMenu";
import StyledIcon from "./StyledIcon";
import { ReactComponent as DealValue } from "../common/icons/request_quote.svg";
import { findInterval, formatCurrency } from "../utils/functions";
import { useSelector } from "react-redux";

const defaultStyles = {
  display: "flex",
  background: "#FFFFFFCC",
  alignItems: "center",
  borderRadius: "16px",
  width: "fit-content",
  height: "fit-content",
  padding: "8px",
  gap: "6px",
  cursor: "pointer",
  position: "relative",
};
const EngagementStageCard = ({ item }) => {
  const navigate = useNavigate();
  const { dealValueRanges } = useSelector((state) => state.appSlice);

  const cardRef = useRef(null);
  const chipRef = useRef(null);
  const departmentsRef = useRef(null);

  const [isHover, setIsHover] = useState(false);
  const [isWrapDepartments, setIsWrapDepartments] = useState(false);
  const [size, setSize] = useState();
  const [sizeChip, setSizeChip] = useState();

  const showDealValues = dealValueRanges?.length > 0;

  useEffect(() => {
    if (cardRef.current) {
      const cardWidth = cardRef.current.getBoundingClientRect().width;
      const cardHeight = cardRef.current.getBoundingClientRect().height;
      setSize({ width: cardWidth, height: cardHeight });
    }
  }, []);

  useEffect(() => {
    if (departmentsRef?.current && isHover) {
      setIsWrapDepartments(Boolean(departmentsRef.current.offsetWidth > 444));
    }
  }, [departmentsRef, isHover]);

  useEffect(() => {
    if (chipRef?.current && isHover) {
      const cardWidth = chipRef.current.getBoundingClientRect().width;
      const cardHeight = chipRef.current.getBoundingClientRect().height;
      setSizeChip({ width: cardWidth, height: cardHeight });
    }
  }, [chipRef, isHover]);

  return (
    <Box
      ref={cardRef}
      sx={{
        width: size?.width ? `${size.width}px` : "fit-content",
        height: size?.height ? `${size.height}px` : "fit-content",
        position: "relative",
        zIndex: isHover && 9,
      }}
    >
      <Box
        onMouseLeave={() => setIsHover(false)}
        onMouseEnter={() => setIsHover(true)}
        sx={{
          width: sizeChip?.width
            ? isHover && `${sizeChip.width + 8}px`
            : "fit-content",
          height: sizeChip?.height
            ? isHover && `${sizeChip.height + 8}px`
            : "fit-content",
          position: isHover && "absolute",
          top: 0,
          right: 0,
          zIndex: isHover && 1,
        }}
      >
        <Box
          ref={chipRef}
          sx={
            isHover
              ? {
                ...defaultStyles,
                position: "absolute",
                background: "white",
                padding: "8px 12px",
                borderRadius: "100px",
                top: 0,
                right: 0,
                boxShadow:
                  "0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.2)",
              }
              : defaultStyles
          }
          onClick={() => navigate(`/details/interaction/${item.id}`)}
        >
          {item.deal_closed && (
            <Box
              sx={{
                width: isHover ? "12px" : "8px",
                height: isHover ? "12px" : "8px",
                borderRadius: "50%",
                bgcolor: "secondary.main",
              }}
            />
          )}
          <Box
            fontSize={(theme) =>
              isHover ? theme.typography.h6 : theme.typography.body2
            }
            color={(theme) => theme.palette.text.main}
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            {item.supplier_name}
          </Box>
          {item.procurement_involved && (
            <StyledIcon
              component={CheckCircleRounded}
              color={"icon_success"}
              sx={{ fontSize: isHover ? 28 : 20 }}
            />
          )}
          {item.renewal && (
            <StyledIcon
              component={ChangeCircleRounded}
              color={"icon_warning"}
              sx={{ fontSize: isHover ? 28 : 20 }}
            />
          )}
        </Box>
        <CustomDropMenu
          isOpen={isHover}
          positionVariant={"bottom-right"}
          extraStyles={{
            borderRadius: "12px",
            marginTop: "52px",
            padding: "16px",
            maxWidth: "500px",
            minWidth: "200px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Box color={(theme) => theme.palette.text.black}>
              ID:{" "}
              <Box
                component={"span"}
                fontSize={(theme) => theme.typography.subtitle1}
              >
                {item.uid}
              </Box>
            </Box>
            {item.procurement_involved && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <StyledIcon
                  component={CheckCircleRounded}
                  color={"icon_success"}
                  sx={{ fontSize: 20 }}
                />
                <Box
                  fontSize={(theme) => theme.typography.body2}
                  color={(theme) => theme.palette.text.medium}
                >
                  Procurement engaged
                </Box>
              </Box>
            )}
            {item.renewal && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <StyledIcon
                  component={ChangeCircleRounded}
                  color={"icon_warning"}
                  sx={{ fontSize: 20 }}
                />
                <Box
                  fontSize={(theme) => theme.typography.body2}
                  color={(theme) => theme.palette.text.medium}
                >
                  Renewal
                </Box>
              </Box>
            )}
            {showDealValues && item?.deal_value && item.deal_value > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "4px",
                  justifyContent: "flex-start",
                }}
              >
                <Box marginTop={'4px'}>
                  <DealValue />
                </Box>
                <Box
                  fontSize={(theme) => theme.typography.body2}
                  color={(theme) => theme.palette.text.medium}
                >
                  {item?.deal_value_manually_assigned ? 'Assigned' : 'Detected'} deal value<br />
                  {item?.deal_value_manually_assigned ?
                    formatCurrency(item.deal_value) :
                    findInterval(dealValueRanges, item.deal_value)
                  }
                </Box>
              </Box>
            ) : ''}
            {!showDealValues && (
              <Box sx={{ display: "flex", gap: "6px" }}>
                <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                  {item.keywords
                    .filter((keyword) => keyword.stage_name === item.stage_name)
                    .map((keyword) =>
                    (<Chip
                      key={keyword.id}
                      label={keyword.keyword}
                      variant={"filled"}
                      sx={{
                        span: { padding: 0 },
                        color: "#000000DE",
                        padding: "4px 10px",
                      }}
                    />
                    ))}
                </Box>
              </Box>
            )}
            <Box
              ref={departmentsRef}
              fontSize={(theme) => theme.typography.body1}
              sx={{
                display: "flex",
                gap: "6px",
                width: "fit-content",
                maxWidth: "465px",
              }}
            >
              <StyledIcon component={LanOutlined} color={"icon"} />
              <Box
                sx={{
                  display: "flex",
                  flexWrap: isWrapDepartments && "wrap",
                  gap: "4px",
                }}
              >
                {item.departments_involved.map((department, index) => (
                  <Box color={(theme) => theme.palette.text.main} key={index}>
                    {department}
                    {index !== item.departments_involved.length - 1 && ","}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </CustomDropMenu>
      </Box>
    </Box>
  );
};

export default EngagementStageCard;
