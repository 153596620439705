import React from 'react';
import { Box } from '@mui/material';
import StyledIcon from './StyledIcon';
import { BusinessRounded } from '@mui/icons-material';

const Logo = ({ size, img, iconSize = 24, borderRadius = true }) => {
  return (
    <Box
      sx={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: borderRadius ? '50%' : '0',
        background: !img && '#0000000A',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {img ? (
        <>
          <Box
            sx={{
              width: `${size}px`,
              height: `${size}px`,
              borderRadius: borderRadius ? '50%' : '0',
              background: 'rgba(0, 0, 0, 0.04)',
              overflow: 'hidden',
              position: 'absolute',
              zIndex: 1,
              top: '0',
              left: '0',
            }}
          />
          <img src={img} alt="logo" width={size} />
        </>
      ) : (
        <StyledIcon
          component={BusinessRounded}
          color={'icon'}
          sx={{ fontSize: iconSize }}
        />
      )}
    </Box>
  );
};

export default Logo;
