import React from "react";

const CustomActiveDot = ({ cx, cy, stroke, r, colorDot, height }) => {
  return (
    <svg>
      <circle
        cx={cx}
        cy={cy}
        r={r + 2}
        stroke={stroke}
        strokeWidth={1}
        fill={"#FFFFFF"}
        style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
      />
      <circle
        cx={cx}
        cy={cy}
        r={r}
        stroke={colorDot}
        strokeWidth={1}
        fill={colorDot}
        style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
      />
      <line
        x1={cx}
        y1={10}
        x2={cx}
        y2={cy - r - 2}
        stroke={"#D0D9ED"}
        strokeWidth={1.5}
        strokeDasharray="4 4"
      />
      <line
        x1={cx}
        y1={cy + r + 3}
        x2={cx}
        y2={height}
        stroke={"black"}
        strokeWidth={1}
        strokeDasharray="4 4"
        strokeOpacity={"0.12"}
      />
    </svg>
  );
};

export default CustomActiveDot;
