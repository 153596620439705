import React, { useState } from 'react';
import CustomCard from '../../common/CustomCard';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import StyledIcon from '../../common/StyledIcon';
import moment from 'moment/moment';
import InteractionsByDepartmentsModal from './InteractionsByDepartmentsModal';
import { useSelector } from 'react-redux';
import { stageNames } from '../../utils/constants';
import TeamNameWithIcon from '../../common/TeamNameWithIcon';
import InteractionStageGraph from './InteractionStageGraph';

const CollapseItem = ({ item }) => {
  return (
    <Box
      sx={{
        overflowX: 'auto',
        border: '1px solid #DEDBEC',
        borderRadius: '12px',
        minWidth: '100%',
      }}
    >
      <Table>
        <TableHead>
          <TableRow
            sx={{
              th: {
                borderBottom:
                  item?.stakeholders?.length === 0
                    ? 'none'
                    : '1.5px solid #694FA31A',
                padding: '16px',
                paddingRight: '0',
                fontWeight: 'subtitle2',
                fontSize: 'subtitle2',
                color: '#000000DE',
              },
            }}
          >
            <TableCell>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                Name
              </Box>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                Title
              </Box>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                Interactions
              </Box>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                Last active
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {item?.stakeholders?.map((contact, index) => (
            <TableRow
              key={index}
              sx={{
                borderBottom: '1.5px solid #694FA31A',
                td: {
                  border: 'none',
                  padding: '16px 24px',
                  paddingRight: '0',
                  color: '#000000DE',
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                },
                ':last-of-type': { borderBottom: 'none' },
              }}
            >
              <TableCell>{contact?.name}</TableCell>
              <TableCell>
                <Box
                  sx={{
                    maxWidth: '150px',
                    whiteSpace: 'normal',
                  }}
                >
                  {contact?.title}
                </Box>
              </TableCell>
              <TableCell>{contact?.interactions}</TableCell>
              <TableCell>
                {contact?.last_active &&
                  moment(contact?.last_active).isValid() && moment(contact?.last_active).format('MMM DD, YYYY')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
const InteractionsByDepartments = ({ selectedDepartments }) => {
  const { interactionsOverview, filtersOverview } = useSelector(
    (state) => state.appSlice,
  );

  const [collapseItems, setCollapseItems] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const createTeamLabel = (department) => {
    if (department?.name && department?.path !== '/') return department?.name;
    return 'No team';
  };

  const addNewStakeholders = (currentStakeholders, interaction, department) => {
    const currentNames = currentStakeholders.map((person) => person.name);
    return currentStakeholders.concat(
      interaction.internal_stakeholders
        .filter(
          (stakeholder) =>
            stakeholder.department === department &&
            !currentNames.includes(stakeholder.name),
        )
        .map((stakeholder) => {
          const lastActiveDate = moment(stakeholder.last_active_at);
          return {
            name: stakeholder.name,
            title: stakeholder.title,
            emails: stakeholder.num_emails_total,
            interactions: 1,
            last_active: lastActiveDate.isValid() ? lastActiveDate : null,
          };
        }),
    );
  };

  const getLastActiveDate = (newDate, currentDate) => {
    if (!newDate.isValid() && !currentDate.isValid)
      return;
    if (!newDate.isValid())
      return currentDate;
    if (!currentDate.isValid())
      return newDate;
    return moment.max(newDate, currentDate);
  }

  // This transforms a list of interactions in the input format needed for InteractionsByDepartments
  const getDepartmentList = () => {
    const departmentMap = {};
    interactionsOverview.forEach((interaction) => {
      interaction.department_paths_involved
        .filter(
          (dep) =>
            !selectedDepartments.length || selectedDepartments.includes(dep),
        )
        .forEach((department_path) => {
          const departments_json = interaction.departments_json || [];
          const foundDepartment = departments_json.find(
            (d) => d.path === department_path,
          );
          let department = foundDepartment?.name;
          let departmentLabel = createTeamLabel(foundDepartment);
          if (
            filtersOverview.departments.length === 0 ||
            filtersOverview.departments.includes(department_path)
          ) {
            if (departmentMap[department]) {
              departmentMap[department]['total_interactions'] += 1;
              if (interaction.stage_name === stageNames[0].label) {
                departmentMap[department]['exploration_stage'] += 1;
              } else if (interaction.stage_name === stageNames[1].label) {
                departmentMap[department]['negotiation_stage'] += 1;
              } else if (interaction.stage_name === stageNames[2].label) {
                departmentMap[department]['closing_stage'] += 1;
              }

              departmentMap[department]['stakeholders'].forEach(
                (domain_stakeholder) => {
                  interaction.internal_stakeholders
                    .filter(
                      (stakeholder) =>
                        stakeholder.department === department &&
                        stakeholder.name === domain_stakeholder.name,
                    )
                    .forEach((stakeholder) => {
                      domain_stakeholder.emails += stakeholder.num_emails_total;
                      domain_stakeholder.last_active = getLastActiveDate(
                        moment(stakeholder.last_active_at),
                        moment(domain_stakeholder.last_active),
                      );
                      domain_stakeholder.interactions += 1;
                    });
                },
              );

              departmentMap[department]['stakeholders'] = addNewStakeholders(
                departmentMap[department]['stakeholders'],
                interaction,
                department,
              );

            } else {
              departmentMap[department] = {
                department_name: departmentLabel,
                total_interactions: 1,
                exploration_stage:
                  interaction.stage_name === stageNames[0].label ? 1 : 0,
                negotiation_stage:
                  interaction.stage_name === stageNames[1].label ? 1 : 0,
                closing_stage:
                  interaction.stage_name === stageNames[2].label ? 1 : 0,
                stakeholders: interaction.internal_stakeholders
                  .filter(
                    (stakeholder) => stakeholder.department === department,
                  )
                  .map((stakeholder) => {
                    const lastActiveDate = moment(stakeholder.last_active_at);
                    return {
                      name: stakeholder.name,
                      title: stakeholder.title,
                      emails: stakeholder.num_emails_total,
                      interactions: 1,
                      last_active: lastActiveDate.isValid() ? lastActiveDate : null,
                    };
                  }),
              };
            }

            departmentMap[department]['stakeholders'].sort(
              (a, b) => b.interactions - a.interactions,
            );
          }
        });
    });
    return Object.values(departmentMap);
  };

  const departments = getDepartmentList();

  if (departments.length === 0) {
    return <></>;
  }

  return (
    <>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            marginBottom={'10px'}
          >
            <Box
              fontSize={(theme) => theme.typography.h6}
              color={(theme) => theme.palette.text.main}
            >
              Interactions by teams
            </Box>
            <Button
              variant={'text'}
              color={'secondary'}
              sx={{ textTransform: 'none' }}
              onClick={() => setIsOpenModal(true)}
            >
              View All
            </Button>
          </Stack>

          <Stack flexDirection={'row'} gap={'13px'}>
            <Stack width={'100%'}>
              {departments
                ?.sort((a, b) => b.total_interactions - a.total_interactions)
                .map((item) => (
                  <Box
                    width={'100%'}
                    key={item?.department_name}
                    marginTop={'4px'}
                    marginBottom={'4px'}
                  >
                    <TeamNameWithIcon
                      sx={{ marginBottom: '-8px' }}
                      teamName={item?.department_name}
                    />
                    <Stack
                      flexDirection={'row'}
                      alignItems={'center'}
                      width={'100%'}
                      height={'40px'}
                    >
                      <InteractionStageGraph item={item} />

                      <Stack
                        key={item?.department_name}
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'8px'}
                        marginLeft={'16px'}
                      >
                        <Box sx={{ whiteSpace: 'nowrap' }}>
                          {item?.total_interactions} interactions
                        </Box>
                        <IconButton
                          sx={{
                            padding: '8px',
                            transform:
                              collapseItems.includes(item?.department_name) &&
                              'rotate(-180deg)',
                            transition: 'transform 300ms',
                          }}
                          onClick={() =>
                            setCollapseItems((prevState) =>
                              collapseItems.includes(item.department_name)
                                ? prevState.filter(
                                    (i) => i !== item.department_name,
                                  )
                                : [...prevState, item.department_name],
                            )
                          }
                        >
                          <StyledIcon
                            component={KeyboardArrowDownRounded}
                            color={'icon'}
                          />
                        </IconButton>
                      </Stack>
                    </Stack>
                    {collapseItems.includes(item?.department_name) && (
                      <CollapseItem item={item} />
                    )}
                  </Box>
                ))}
            </Stack>
          </Stack>
        </Box>
      </CustomCard>
      <InteractionsByDepartmentsModal
        open={isOpenModal}
        handleClose={() => setIsOpenModal(false)}
        total={departments.length}
        items={departments}
      />
    </>
  );
};

export default InteractionsByDepartments;
