import {
  AccessTimeRounded,
  ContentCopyRounded,
  MailOutlineRounded,
  PhoneOutlined,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import ConfirmCopy from '../../common/ConfirmCopy';
import CustomDropMenu from '../../common/CustomDropMenu';
import StyledIcon from '../../common/StyledIcon';

const SupplierDetailsContacts = ({ supplier, responseTime, miniView }) => {
  const [openMailId, setOpenMailId] = useState(null);
  const [openPhoneId, setOpenPhoneId] = useState(null);
  const [confirmCopy, setConfirmCopy] = useState('id + variant');

  const handleConfirmCopy = (id, variant) => {
    setConfirmCopy(`${id} ${variant}`);
    setTimeout(() => {
      setConfirmCopy('');
    }, 1000);
  };

  const handleMailPopoverOpen = (id) => {
    setOpenMailId(id);
  };

  const handleMailPopoverClose = () => {
    setOpenMailId(null);
  };
  const handlePhonePopoverOpen = (id) => {
    setOpenPhoneId(id);
  };

  const handlePhonePopoverClose = () => {
    setOpenPhoneId(null);
  };

  return (
    <Box
      sx={{
        border: '1.5px solid #694FA31A',
        borderRadius: '12px',
        marginBottom: miniView ? '0' : '24px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          padding: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1.5px solid #694FA31A',
        }}
      >
        <Box
          color={(theme) => theme.palette.text.main}
          fontSize={(theme) => theme.typography.h6}
        >
          Supplier contacts
        </Box>
        {supplier.avg_response_time_in_hours && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledIcon
              component={AccessTimeRounded}
              color={'icon'}
              sx={{ marginRight: '8px' }}
            />{' '}
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.medium}
              sx={{ marginRight: '4px' }}
            >
              Response time
            </Box>
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.subtitle1}
            >
              {responseTime}
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        <Table sx={{ overflow: 'visible' }}>
          <TableHead>
            <TableRow
              sx={{
                th: {
                  borderBottom:
                    supplier.contacts.length === 0
                      ? 'none'
                      : '1.5px solid #694FA31A',
                  padding: '16px',
                  paddingRight: '0',
                  fontWeight: 'subtitle2',
                  fontSize: 'subtitle2',
                  color: '#000000DE',
                },
              }}
            >
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                  }}
                >
                  Name
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                  }}
                >
                  Title
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                  }}
                >
                  Messages
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Last active
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {supplier.contacts
              ?.slice(...(miniView ? [0, 2] : [0]))
              .map((contact, index) => (
                <TableRow
                  key={index}
                  sx={{
                    borderBottom: '1.5px solid #694FA31A',
                    td: {
                      border: 'none',
                      padding: '16px',
                      paddingRight: '0',
                      color: '#000000DE',
                      fontSize: '14px',
                      whiteSpace: 'nowrap',
                    },
                    ':last-of-type': { borderBottom: 'none' },
                  }}
                >
                  <TableCell
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '12px',
                    }}
                  >
                    <Box>{contact.name}</Box>
                    <Box sx={{ display: 'flex' }}>
                      <Box
                        sx={{ position: 'relative' }}
                        onMouseEnter={() => handleMailPopoverOpen(index)}
                        onMouseLeave={handleMailPopoverClose}
                      >
                        <IconButton
                          sx={{ padding: '4px' }}
                          onClick={() => handleMailPopoverOpen(index)}
                        >
                          <StyledIcon
                            component={MailOutlineRounded}
                            color={'secondary'}
                          />
                        </IconButton>

                        <CustomDropMenu
                          isOpen={Boolean(
                            openMailId === index && contact.email,
                          )}
                          positionVariant={'bottom-left'}
                          extraStyles={{
                            borderRadius: '12px',
                            marginTop: '32px',
                            padding: '16px',
                            maxWidth: '500px',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <Box
                              color={(theme) => theme.palette.text.main}
                              fontSize={(theme) => theme.typography.body2}
                              sx={{
                                lineHeight: '143%',
                              }}
                            >
                              {contact.email}
                            </Box>

                            <Box sx={{ position: 'relative' }}>
                              <IconButton
                                sx={{ padding: 0 }}
                                onClick={() => {
                                  navigator.clipboard.writeText(contact.email);
                                  handleConfirmCopy(index, 'email');
                                }}
                              >
                                <StyledIcon
                                  component={ContentCopyRounded}
                                  color={'icon'}
                                />
                              </IconButton>
                              {confirmCopy === `${index} email` && (
                                <ConfirmCopy
                                  message={'Email copied'}
                                  positionStyles={{
                                    marginTop: '28px',
                                    marginLeft: 'calc(-100% - 12px)',
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        </CustomDropMenu>
                      </Box>
                      {contact.phone && (
                        <Box
                          sx={{ position: 'relative' }}
                          onMouseEnter={() => handlePhonePopoverOpen(index)}
                          onMouseLeave={handlePhonePopoverClose}
                        >
                          <IconButton sx={{ padding: '4px' }}>
                            <StyledIcon
                              component={PhoneOutlined}
                              color={'secondary'}
                            />
                          </IconButton>
                          <CustomDropMenu
                            isOpen={Boolean(
                              openPhoneId === index && contact.phone,
                            )}
                            positionVariant={'bottom-left'}
                            extraStyles={{
                              borderRadius: '12px',
                              marginTop: '32px',
                              padding: '16px',
                              maxWidth: '500px',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Box
                                color={(theme) => theme.palette.text.main}
                                fontSize={(theme) => theme.typography.body2}
                              >
                                {contact.phone}
                              </Box>
                              <Box sx={{ position: 'relative' }}>
                                <IconButton
                                  sx={{ padding: 0 }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      contact.phone,
                                    );
                                    handleConfirmCopy(index, 'phone');
                                  }}
                                >
                                  <StyledIcon
                                    component={ContentCopyRounded}
                                    color={'icon'}
                                  />
                                </IconButton>
                                {confirmCopy === `${index} phone` && (
                                  <ConfirmCopy
                                    message={'Phone copied'}
                                    positionStyles={{
                                      marginTop: '28px',
                                      marginLeft: 'calc(-100% - 12px)',
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                          </CustomDropMenu>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ maxWidth: '150px', whiteSpace: 'normal' }}>
                      {contact.title}
                    </Box>
                  </TableCell>
                  <TableCell>{contact.num_emails_total}</TableCell>
                  <TableCell>
                    {moment(contact.last_active_at).format('MMM DD, YYYY')}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      {miniView && (
        <Box
          sx={{
            padding: '26px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderTop: '1.5px solid #694FA31A',
          }}
        >
          <Link
            underline="hover"
            sx={{
              cursor: 'pointer'
            }}
            color="secondary"
            fontSize={(theme) => theme.typography.subtitle2}
            onClick={miniView}
          >
            See all people
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default SupplierDetailsContacts;
