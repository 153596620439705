import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CustomCard from "../../common/CustomCard";
import StyledIcon from "../../common/StyledIcon";
import {
  ModeEditOutlineOutlined,
  DescriptionOutlined,
} from "@mui/icons-material";
import RolePopup from "./RolePopup";
import LoadingSkeleton from "../../common/LoadingSkeleton";
import { getUsers } from "../../common/DataHelper";

const InstantMessaging = ({ role, user }) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const getData = () => {
    getUsers().then((res) => {
      setUsers(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    document.title = "PartnerElement | Settings > Email notifications";
    getData();
  }, []);

  return (
    <>
      <Box sx={{ width: "100%", height: "fit-content" }}>
        <CustomCard>
          <Box>
            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ borderBottom: "1px solid #694FA31A", padding: "16px" }}
            >
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.h6}
              >
                Instant Messaging
              </Box>
            </Stack>
            <Box>
              
            </Box>
          </Box>
        </CustomCard>
      </Box>
      {openPopup && (
        <RolePopup
          open={openPopup}
          handleClose={() => setOpenPopup(false)}
          user={currentUser}
          getData={getData}
        />
      )}
    </>
  );
};

export default InstantMessaging;
