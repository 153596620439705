import React from 'react';
import { Box, Stack } from '@mui/material';

const DepartmentTimingBar = ({ item }) => {
  const getStyle = (stage, percentage) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: `${percentage}%`,
    height: '32px',
    background:
      stage === 'without'
        ? '#FFDBD2'
        : stage === 'with'
        ? '#CCE7FF'
        : '#D5F7CF',
    color:
      stage === 'without'
        ? '#3C0800'
        : stage === 'with'
        ? '#004376'
        : '#196C32',
    fontWeight: '400',
    fontSize: '14px',
    opacity: '0.7',
    borderRadius: stage === 'without' ? '2px 0 0 2px' : stage === 'inWorkflow' ? '0 2px 2px 0' : '0',
    borderRight:
      stage !== 'inWorkflow' && item?.total_duration_mean !== 0
        ? '1px solid white'
        : '',
  });

  const getRequestString = (total) => {
    return total === 1 ? '1 request' : `${total} requests`;
  }

  return (
    <Stack flexDirection={'row'} gap={'16px'}>
      <Stack gap={'4px'}
        sx={{
          textAlign: 'right',
          fontWeight: '400',
          fontSize: '12px',
          width: '140px'
        }}
      >
        <Box color={'#000000DE'}>{item.user_department_name}</Box>
        <Box color={'#00000099'}>{getRequestString(item.request_number_nunique)}</Box>
      </Stack>
      <Stack width={'100%'} flexDirection={'row'}>
        {Boolean(item.independent_duration_mean) && <Box
          sx={getStyle(
            'without',
            (item.independent_duration_mean / item.total) * 100,
          )}
        >
          {item.independent_duration_mean}
        </Box>}
        {Boolean(item.collaboration_duration_mean) && <Box
          sx={getStyle(
            'with',
            (item.collaboration_duration_mean / item.total) * 100,
          )}
        >
          {item.collaboration_duration_mean}
        </Box>}
        {Boolean(item.total_duration_mean) && <Box
          sx={getStyle(
            'inWorkflow',
            (item.total_duration_mean / item.total) * 100,
          )}
        >
          {item.total_duration_mean}
        </Box>}
      </Stack>
    </Stack>
  );
};

export default DepartmentTimingBar;
