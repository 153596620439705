import { Box, Table, TableBody } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReportTable from './ReportTable';
import {
  calculateSpendTabColumns,
  CATEGORY_SPENDING_STATS_HEADERS,
  EMPLOYEE_SPENDING_STATS_HEADERS,
  generateQueryParams,
  SUPPLIER_SPENDING_STATS_HEADERS,
} from './reportHelpers';
import { encodeQuery } from '../../store/storeFunctions';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import SpendGraphs from './SpendGraphs';
import { FetchWithCancel } from '../../common/FetchWithCancel';

const SpendingStats = ({ filters }) => {
  const [employeeCollection, setEmployeeCollection] = useState([]);
  const [categoryCollection, setCategoryCollection] = useState([]);
  const [supplierCollection, setSupplierCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const employeeTableOptions = {
    type: 'employee',
    includeBorder: true,
    includeHeader: true,
    plural: 'employees',
    title: 'Spend by employees',
    modal: true,
  };

  const categoryTableOptions = {
    type: 'category',
    includeBorder: true,
    includeHeader: true,
    plural: 'categories',
    title: 'Spend by categories',
    modal: true,
  };

  const supplierTableOptions = {
    type: 'supplier',
    includeBorder: true,
    includeHeader: true,
    plural: 'suppliers',
    title: 'Spend by suppliers',
    modal: true,
  };

  const getStats = (subjectType) => {
    const query = {
      subject: subjectType,
      ...generateQueryParams(filters),
    };

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/report/requests`;
    const queryString = encodeQuery(query);
    const urlWithQuery = `${apiUrl}?${queryString}`;
    return FetchWithCancel(urlWithQuery);
  };

  useEffect(() => {
    setIsLoading(true);
    const {
      fetchPromise: costCenterStatsFetch,
      cancel: costCenterStatsCancel,
    } = getStats('cost_center');
    const { fetchPromise: categoryStatsFetch, cancel: categoryStatsCancel } =
      getStats('category');
    const { fetchPromise: supplierStatsFetch, cancel: supplierStatsCancel } =
      getStats('supplier');

    costCenterStatsFetch.then((employeeData) => {
      if (employeeData !== 'error') {
        const collection = calculateSpendTabColumns(employeeData?.report || []);
        setEmployeeCollection(collection);
      } else {
        setEmployeeCollection([]);
      }
    });

    categoryStatsFetch.then((categoryData) => {
      if (categoryData !== 'error') {
        const collection = calculateSpendTabColumns(categoryData?.report || []);
        setCategoryCollection(collection);
      } else {
        setCategoryCollection([]);
      }
    });

    supplierStatsFetch.then((supplierData) => {
      if (supplierData !== 'error') {
        const collection = calculateSpendTabColumns(supplierData?.report || []);
        setSupplierCollection(collection);
      } else {
        setSupplierCollection([]);
      }
      setIsLoading(false);
    });

    return () => {
      costCenterStatsCancel && costCenterStatsCancel();
      categoryStatsCancel && categoryStatsCancel();
      supplierStatsCancel && supplierStatsCancel();
    };
  }, [filters]);

  if (isLoading)
    return (
      <Table>
        <TableBody>
          <LoadingSkeleton variant={'adminLogs'} />
        </TableBody>
      </Table>
    );

  return (
    <Box>
      <SpendGraphs filters={filters} />
      <ReportTable
        tableHeaders={EMPLOYEE_SPENDING_STATS_HEADERS}
        collection={employeeCollection}
        options={employeeTableOptions}
      />
      <ReportTable
        tableHeaders={CATEGORY_SPENDING_STATS_HEADERS}
        collection={categoryCollection}
        options={categoryTableOptions}
      />
      <ReportTable
        tableHeaders={SUPPLIER_SPENDING_STATS_HEADERS}
        collection={supplierCollection}
        options={supplierTableOptions}
      />
    </Box>
  );
};

export default SpendingStats;
