import React, { useRef, useState } from "react";
import { Box, IconButton } from "@mui/material";
import StyledIcon from "./StyledIcon";
import { HelpOutlineRounded } from "@mui/icons-material";
import { Button } from "@mui/material";

const InfoMessage = ({
  message,
  extraStyles,
  positionVariant = "bottom-left",
}) => {
  const dropMenuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const setPositionVariant = () => {
    switch (positionVariant) {
      case "top-left":
        return { left: 0 };
      case "top-center":
        return {
          left: `calc(50% - ${dropMenuRef?.current?.offsetWidth}px)`,
        };
      case "top-right":
        return { right: 0 };
      case "bottom-left":
        return { left: 0 };
      case "bottom-center":
        return {
          left: `calc(50% - ${dropMenuRef?.current?.offsetWidth}px)`,
        };
      case "bottom-right":
        return { right: 0 };
      default:
        return { left: 0 };
    }
  };
  return (
    <Box sx={{ position: "relative" }}>
      <IconButton
        sx={{ padding: "8px", cursor: "default" }}
        onClick={() => setIsOpen(true)}
      >
        <StyledIcon component={HelpOutlineRounded} color="icon" />
      </IconButton>
      <Box
        ref={dropMenuRef}
        fontSize={(theme) => theme.typography.body2}
        sx={{
          width: "fit-content",
          maxWidth: "310px",
          minWidth: "310px",
          position: "absolute",
          top: "100%",
          ...setPositionVariant(),
          opacity: isOpen ? 1 : 0,
          display: isOpen ? "block" : "none",
          transition: "opacity 200ms",
          background: "#f5f5f5",
          borderRadius: "12px",
          color: "#49454f",
          zIndex: 1,
          padding: "12px",
          boxShadow: "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
          ...extraStyles,
        }}
      >
        {message}
        <Box 
          onClick={() => setIsOpen(false)}
          component={"a"}
          sx={{
            color: "secondary.main",
            textDecoration: "none",
            cursor: "pointer",
            display: "block",
            fontWeight: "bold",
            marginTop: "16px",
          }}
        >
          Ok
        </Box>
      </Box>
    </Box>
  );
};

export default InfoMessage;
