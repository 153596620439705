import React from 'react';
import { Box, Stack } from '@mui/material';
import { ReactComponent as Teams } from '../common/icons/teams.svg';

const TeamNameWithIcon = ({ teamName, sx, large }) => {
  return (
    <Stack flexDirection={'row'} gap={'4px'} alignItems={'center'} sx={sx}>
      <Box>
        <Teams />
      </Box>
      <Box
        color={(theme) => theme.palette.text.main}
        fontSize={(theme) =>
          large ? theme.typography.body1 : theme.typography.body2
        }
      >
        {teamName}
      </Box>
    </Stack>
  );
};

export default TeamNameWithIcon;
