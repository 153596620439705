import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Stack,
  OutlinedInput,
  Select,
  MenuItem,
  Slider,
} from "@mui/material";
import CustomCard from "../../common/CustomCard";
import Target from "./Target";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getDepartments } from "../../store/storeFunctions";
import useDebounce from "../../hooks/useDebounce";
import CheckboxTree from "./CheckboxTree";
import InfoMessage from "../../common/InfoMessage";

const defaultStylesForCard = {
  card: { padding: "16px" },
  title: {
    fontSize: (theme) => theme.typography.h6,
    color: (theme) => theme.palette.text.main,
    marginBottom: "16px",
  },
  subtitle: {
    fontSize: (theme) => theme.typography.subtitle1,
    color: (theme) => theme.palette.text.main,
    marginBottom: "16px",
  },
  titleSlider: {
    fontSize: (theme) => theme.typography.subtitle2,
    color: (theme) => theme.palette.text.main,
    marginBottom: "32px",
  },
  slider: {
    color: "secondary",
  },
  fields: { sx: { borderRadius: "10px" }, color: "secondary" },
};

const EmailNotifications = ({ role }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { departments } = useSelector((state) => state.appSlice);

  useEffect(() => {
    document.title = "PartnerElement | Settings > Integrations > Email notifications";
    dispatch(getDepartments());
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const [defaultSettings, setDefaultSettings] = useState({
    data_look_back_days: "",
    domain_name: "",
    email_provider: "",
    external_domains_to_exclude: [],
    groups_to_exclude: [],
    min_outbound_emails: 0,
    min_total_emails: 0,
    procurement_department_id: "",
    procurement_group_email: "",
    target_department_ids: [],
    users_to_exclude: [],
    procurement_specific_emails: [],
  });
  const [settings, setSettings] = useState({
    data_look_back_days: "",
    domain_name: "",
    email_provider: "",
    external_domains_to_exclude: [],
    groups_to_exclude: [],
    min_outbound_emails: 0,
    min_total_emails: 0,
    procurement_department_id: "",
    procurement_group_email: "",
    target_department_ids: [],
    users_to_exclude: [],
    procurement_specific_emails: [],
  });

  const [domain, setDomain] = useState(settings?.domain_name);

  const [emailList, setEmailList] = useState(settings?.procurement_specific_emails || []);

  const debounceDomain = useDebounce(domain, 500);

  const [groupEmail, setGroupEmail] = useState(
    settings?.procurement_group_email
  );

  const debounceGroup = useDebounce(groupEmail, 500);

  useEffect(() => {
    setSettings((prev) => ({
      ...prev,
      domain_name: debounceDomain,
    }));
  }, [debounceDomain]);

  useEffect(() => {
    setSettings((prev) => ({
      ...prev,
      procurement_group_email: debounceGroup,
    }));
  }, [debounceGroup]);

  const handleChange = (event, ids) => {
    setSettings((prev) => ({
      ...prev,
      target_department_ids: event.target.checked
        ? [...prev?.target_department_ids, ...ids]
        : prev?.target_department_ids.filter((item) => !ids.includes(item)),
    }));
  };

  const handleSelectedTagsEmails = useCallback((items) => {
    setSettings((prev) => ({
      ...prev,
      groups_to_exclude: items,
    }));
  }, []);

  const handleSelectedTagsUsers = useCallback((items) => {
    setSettings((prev) => ({
      ...prev,
      users_to_exclude: items,
    }));
  }, []);

  const handleSelectedTagsDomains = useCallback((items) => {
    setSettings((prev) => ({
      ...prev,
      external_domains_to_exclude: items,
    }));
  }, []);

  const getData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/settings`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "GET",
        credentials: "include",
      }
    );
    if (response.ok) {
      return await response.json();
    } else {
      return "error";
    }
  };

  const updateSettings = async (data) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/settings`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
    if (response.ok) {
      return await response.json();
    } else {
      return "error";
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getData().then((res) => {
      setDefaultSettings(res);
      setEmailList(res.procurement_specific_emails || []);
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (defaultSettings) {
      setSettings(defaultSettings);
      setDomain(defaultSettings?.domain_name);
      setGroupEmail(defaultSettings?.procurement_group_email);
      setEmailList(defaultSettings?.procurement_specific_emails || []);
    }
  }, [defaultSettings]);

  useEffect(() => {
    if (Boolean(JSON.stringify(settings) !== JSON.stringify(defaultSettings))) {
      updateSettings(settings).then();
    }
  }, [settings]);

  if (isLoading || !defaultSettings) {
    return <></>;
  }

  return (
    <>
      <Stack flexDirection={"column"} gap={"24px"} sx={{ width: "100%" }}>
        <CustomCard>
          <Box sx={{ ...defaultStylesForCard.card }}>
            <Box {...defaultStylesForCard.title}>Company</Box>
            <Stack flexDirection={"column"} gap={"24px"} sx={{ width: "50%" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="domain" color={"secondary"}>
                  Domains to scan:
                </InputLabel>
                <OutlinedInput
                  value={domain}
                  onChange={(event) => setDomain(event.target.value)}
                  disabled={role !== "admin"}
                  id={"domain"}
                  label={"Domains to scan"}
                  {...defaultStylesForCard.fields}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="emailProvider" color={"secondary"}>
                  Email provider:
                </InputLabel>
                <Select
                  value={settings?.email_provider}
                  onChange={(event) =>
                    setSettings((prev) => ({
                      ...prev,
                      email_provider: event.target.value,
                    }))
                  }
                  disabled={role !== "admin"}
                  id={"emailProvider"}
                  label={"Email provider"}
                  {...defaultStylesForCard.fields}
                >
                  <MenuItem value={"Google"}>Google</MenuItem>
                  <MenuItem value={"Outlook"}>Outlook</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="timeframe" color={"secondary"}>
                  Fetch data from:
                </InputLabel>
                <Select
                  value={settings?.data_look_back_days}
                  onChange={(event) =>
                    setSettings((prev) => ({
                      ...prev,
                      data_look_back_days: event.target.value,
                    }))
                  }
                  disabled={role !== "admin"}
                  id={"timeframe"}
                  label={"Fetch data from"}
                  {...defaultStylesForCard.fields}
                >
                  <MenuItem value={7}>Last 7 days</MenuItem>
                  <MenuItem value={30}>Last 30 days</MenuItem>
                  <MenuItem value={60}>Last 60 days</MenuItem>
                  <MenuItem value={90}>Last 90 days</MenuItem>
                  <MenuItem value={180}>Last 180 days</MenuItem>
                  <MenuItem value={365}>Last 365 days</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Box>
        </CustomCard>
        
        <CustomCard>
          <Box sx={{ ...defaultStylesForCard.card }}>
            <Box {...defaultStylesForCard.title} sx={{
              display: "flex",
              alignItems: "center",
            }}>
              Interaction threshold
              <InfoMessage
                message={
                  `Set the number of messages required to detect a supplier interaction.`
                }
              />
            </Box>
            <Stack flexDirection={"column"} gap={"16px"} sx={{ width: "50%" }}>
              <Box>
                <Box {...defaultStylesForCard.titleSlider}>Outbound messages</Box>
                <Slider
                  value={settings?.min_outbound_emails}
                  onChange={(event) =>
                    setSettings((prev) => ({
                      ...prev,
                      min_outbound_emails: event.target.value,
                    }))
                  }
                  valueLabelDisplay={role !== "admin" ? "on" : "auto"}
                  {...defaultStylesForCard.slider}
                  disabled={role !== "admin"}
                />
              </Box>
              <Box>
                <Box {...defaultStylesForCard.titleSlider}>Total messages</Box>
                <Slider
                  value={settings?.min_total_emails}
                  onChange={(event) =>
                    setSettings((prev) => ({
                      ...prev,
                      min_total_emails: event.target.value,
                    }))
                  }
                  valueLabelDisplay={role !== "admin" ? "on" : "auto"}
                  {...defaultStylesForCard.slider}
                  disabled={role !== "admin"}
                />
              </Box>
            </Stack>
          </Box>
        </CustomCard>

        <CustomCard>
          <Box sx={{ ...defaultStylesForCard.card }}>
            <Box {...defaultStylesForCard.title}>Manage message scanning</Box>
            <Box
              color={(theme) => theme.palette.text.caption}
              fontSize={(theme) => theme.typography.body1}
            >
              Choose teams in your company to include in message scanning.
            </Box>
            <Box sx={{ width: "50%" }}>
              <CheckboxTree
                data={departments}
                handleChange={handleChange}
                target_department_ids={settings?.target_department_ids}
                disabled={role !== "admin"}
              />
            </Box>
          </Box>
        </CustomCard>
        <CustomCard>
          <Box sx={{ ...defaultStylesForCard.card }}>
            <Box {...defaultStylesForCard.title}>Targeting exclusions</Box>
            <Box
              color={(theme) => theme.palette.text.caption}
              fontSize={(theme) => theme.typography.body1}
            >
              Choose specific emails, groups, or domains to exclude from message scanning.
            </Box>
            <Stack direction={"column"} gap={"16px"} width={"50%"}>
              <Box>
                <Target
                  disabled={role !== "admin"}
                  setTags={handleSelectedTagsUsers}
                  tags={
                    Array.isArray(settings?.users_to_exclude)
                      ? settings?.users_to_exclude
                      : []
                  }
                  fullWidth
                  color={"secondary"}
                  variant={"outlined"}
                  id={"tags"}
                  name={"tags"}
                  placeholder={"Type to add"}
                  label={"Emails to exclude:"}
                />
              </Box>

              <Box>
                <Target
                  disabled={role !== "admin"}
                  setTags={handleSelectedTagsEmails}
                  tags={
                    Array.isArray(settings?.groups_to_exclude)
                      ? settings?.groups_to_exclude
                      : []
                  }
                  fullWidth
                  color={"secondary"}
                  variant={"outlined"}
                  id={"tags"}
                  name={"tags"}
                  placeholder={"Type to add"}
                  label={"Groups to exclude:"}
                />
              </Box>

              <Box>
                <Target
                  disabled={role !== "admin"}
                  setTags={handleSelectedTagsDomains}
                  tags={
                    Array.isArray(settings?.external_domains_to_exclude)
                      ? settings?.external_domains_to_exclude
                      : []
                  }
                  fullWidth
                  color={"secondary"}
                  variant={"outlined"}
                  id={"tags"}
                  name={"tags"}
                  placeholder={"Type to add"}
                  label={"Domains to exclude:"}
                />
              </Box>
            </Stack>
          </Box>
        </CustomCard>
      </Stack>
    </>
  );
};

export default EmailNotifications;
