import React, { useRef } from "react";
import { Box } from "@mui/material";

const CustomDropMenu = ({ children, extraStyles, isOpen, positionVariant }) => {
  const dropMenuRef = useRef(null);
  const setPositionVariant = () => {
    switch (positionVariant) {
      case "top-left":
        return { left: 0 };
      case "top-center":
        return {
          left: `calc(50% - ${dropMenuRef?.current?.offsetWidth}px)`,
        };
      case "top-right":
        return { right: 0 };
      case "bottom-left":
        return { left: 0 };
      case "bottom-center":
        return {
          left: `calc(50% - ${dropMenuRef?.current?.offsetWidth}px)`,
        };
      case "bottom-right":
        return { right: 0 };
      default:
        return { left: 0 };
    }
  };

  return (
    <Box
      ref={dropMenuRef}
      sx={{
        width: "fit-content",
        position: "absolute",
        top: 0,
        ...setPositionVariant(),
        opacity: isOpen ? 1 : 0,
        display: isOpen ? "block" : "none",
        transition: "opacity 200ms",
        background: "white",
        zIndex: 1,
        boxShadow:
          "0px 3px 5px -1px rgba(0, 0, 0, 0.20), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
        ...extraStyles,
      }}
    >
      {children}
    </Box>
  );
};

export default CustomDropMenu;
