import React, { useState } from 'react';
import { Box, Button, InputAdornment, TextField, IconButton } from '@mui/material';
import { Edit as EditIcon, Close as CloseIcon } from '@mui/icons-material';
import { findInterval } from '../../utils/functions';

const DealValueEditor = ({ dealValue, dealValueRanges, onSave, isEditing, setIsEditing, isAssignedDealValue }) => {
    const formatCurrency = (num) => {
        if (num === null || num === undefined || isNaN(num)) {
            return '';
        }

        return num.toLocaleString();
    };

    const [tempDealValue, setTempDealValue] = useState(formatCurrency(dealValue));

    const handleSave = () => {
        onSave(parseFloat(tempDealValue.replace(/[^0-9.-]+/g, "")));
        setIsEditing(false);
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    const handleChange = (event) => {
        const value = event.target.value;
        const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
        if (!isNaN(numericValue)) {
            setTempDealValue(formatCurrency(numericValue));
        } else {
            setTempDealValue(value);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }} fontSize={(theme) => theme.typography.body1}>
            {isEditing ? (
                <Box sx={{ display: 'flex', border: '1px solid #c4c4c4', borderRadius: '10px', alignItems: 'center', gap: '8px', padding: '2px', paddingLeft: '4px' }}>
                    <TextField
                        value={tempDealValue}
                        onChange={handleChange}
                        type="text"
                        size="small"
                        variant="standard"
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        sx={{ fontSize: '16px', flex: 1 }}
                        inputProps={{
                            style: { padding: 0, width: '100px' },
                        }}
                    />
                    <Button variant="text" color="secondary" onClick={handleSave} sx={{ padding: '6px 16px', fontSize: '0.875rem', textTransform: 'none' }}>
                        Save
                    </Button>
                    <IconButton size="small" onClick={handleCancel}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    {dealValue > 0 ? (
                        <>
                            <Box component={'span'} color="text.primary" sx={{ fontSize: '16px', fontWeight: '500' }}>
                                {isAssignedDealValue ? `$${formatCurrency(dealValue)}` : findInterval(dealValueRanges, dealValue)}
                            </Box>
                            <Box component={'span'} color="text.secondary" sx={{ fontSize: '16px' }}>
                                {isAssignedDealValue ? 'assigned' : 'detected'} deal value
                            </Box>
                        </>) : (
                        <Box>
                            Deal value
                        </Box>
                    )}

                    <IconButton variant="text" size="small" color="secondary" sx={{ fontSize: '14px', fontFamily: 'Roboto' }} onClick={() => setIsEditing(true)}>
                        <EditIcon fontSize="small" />
                        Assign value
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

export default DealValueEditor;
