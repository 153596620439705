import React from "react";
import {
    Box
} from "@mui/material";
import CustomCard from "../../common/CustomCard";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const defaultStylesForCard = {
    card: { padding: "16px" },
};

const faqText = {
    color: (theme) => theme.palette.text.medium,
    fontSize: (theme) => "18px",
    lineHeight: "27px",
};

const accordionHeader = {
    paddingRight: "0",
    paddingLeft: "0",
    '&.Mui-expanded': {
        margin: '0 !important',
        minHeight: "0 !important",
    },
    '> div': {
        margin: "20px 0",
    }
};

const accordionHeaderText = {
    fontSize: (theme) => theme.typography.h6,
    color: (theme) => theme.palette.text.main,
    '&.Mui-expanded': {
        margin: '0 !important',
    }
};

const accordionContent = {
    marginTop: "0px",
    paddingBottom: "20px",
    ...faqText,
};

const accordionBase = {
    boxShadow: "none",
    borderTop: "1.5px solid",
    borderBottom: "none",
    borderColor: (theme) => theme.palette.secondary.light,
    '&.Mui-expanded': {
        margin: '0 !important',
        borderColor: (theme) => theme.palette.secondary.light,
    },
    '&:first-of-type': {
        borderRadius: "0 !important",
    }
};

const accordionTopBase = {
    boxShadow: "none",
};

const accordionDetails = {
    margin: "0",
    padding: "0",
};

const FAQ = ({ role }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    return (
        <>
            <CustomCard>

                <Box sx={defaultStylesForCard.card}>
                    <Box sx={theme.typography.h4}>
                        FAQ
                    </Box>
                    <Box sx={theme.typography.text}>
                        <Box paddingTop={"10px"} maxWidth={"720px"} sx={faqText}>
                            Here is a list of frequently asked questions. You can contact admin at arina@partnerelement.com for more questions.
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ ...defaultStylesForCard.card, maxWidth: "720px" }}>
                    <Accordion sx={accordionBase}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={accordionHeader}
                        >
                            <Typography sx={accordionHeaderText}>What is Partner Element?</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={accordionDetails}>
                            <Typography sx={accordionContent}>
                                Partner Element is a procurement-centric SaaS platform that empowers your company with early insights into ongoing supplier deals to facilitate company-wide coordination of supplier interactions.
                                <br />
                                Partner Element helps your organization:
                            </Typography>
                            <Box marginTop={"-34px"} sx={faqText}>
                                <ul>
                                    <li>Maximize its negotiating power</li>
                                    <li>Eliminate duplicative efforts</li>
                                    <li>Increase efficiency with regularly updated lists of supplier contacts and all employee-supplier interactions.</li>
                                </ul>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={accordionBase}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={accordionHeader}
                        >
                            <Typography sx={accordionHeaderText}>How does Partner Element work?</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={accordionDetails}>
                            <Typography sx={accordionContent}>
                                Partner Element scans your designated company email domain and detects prospective deals with suppliers through keyword and language pattern recognition in third-party messages. All messages related to the same deal are collectively referred to as interactions. After detection, interactions are assigned an engagement stage to indicate location in the purchasing process. Prospective deals are assigned to the exploration, negotiation, or closing engagement stage to help procurement teams prioritize resourcing and efforts. Using Partner Element, your procurement team can engage individual employees or entire teams across the company to provide timely assistance on new deals or renewals.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={accordionBase}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={accordionHeader}
                        >
                            <Typography sx={accordionHeaderText}>
                                What data does Partner Element have access to?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={accordionDetails}>
                            <Typography sx={accordionContent}>
                                Partner Element only accesses the data your administrator allows during the setup process. Using custom inclusion and exclusion rules, your company can choose which email inboxes are automatically scanned. For example, Partner Element can be configured to only scan the Research & Development team’s inboxes, excluding certain individuals, if desired. Coupled with this, Partner Element only accesses emails involving third-party email domains, i.e., emails sent to or received from third parties; the product is not designed to scan emails that are solely among your personnel.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={accordionBase}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={accordionHeader}
                        >
                            <Typography sx={accordionHeaderText}>
                                Who can access email scanning settings?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={accordionDetails}>
                            <Typography sx={accordionContent}>
                                In Partner Element, only users with the Administrator role can make changes to global settings, and you can designate who has this privilege. A common setup is for the IT team to hold the administrator role, and Partner Element can also serve as a company’s administrator during setup or on a long-term basis if preferred.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={accordionBase}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={accordionHeader}
                        >
                            <Typography sx={accordionHeaderText}>
                                How is the data accessed?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={accordionDetails}>
                            <Typography sx={accordionContent}>
                                Data is securely fetched via the Google Gmail API for Google customers or sent encrypted directly to Partner Element servers from Microsoft Exchange.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={accordionBase}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={accordionHeader}
                        >
                            <Typography sx={accordionHeaderText}>
                                Are duplicates of emails made and then sent to Partner Element’s servers?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={accordionDetails}>
                            <Typography sx={accordionContent}>
                                Yes, Partner Element streams email copies to its servers. After the copies are processed by Partner Element’s machine learning service, they are deleted from the Partner Element environment. Partner Element might store de-identified copies to improve the accuracy of supplier detection.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={accordionBase}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={accordionHeader}
                        >
                            <Typography sx={accordionHeaderText}>
                                What can our users see when using Partner Element?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={accordionDetails}>
                            <Typography sx={accordionContent}>
                                Once Partner Element’s machine learning models detect a potential supplier conversation, it only surfaces which employees are talking to which supplier, the particular engagement stage of the activity, relevant dates, and how many emails were exchanged.
                                <br />
                                Nothing else from the emails, such as email subject lines, content, and attachments, is accessible in the UI or otherwise exposed to users by Partner Element.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={accordionBase}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={accordionHeader}
                        >
                            <Typography sx={accordionHeaderText}>
                                How does Partner Element’s machine learning model work?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={accordionDetails}>
                            <Typography sx={accordionContent}>
                                Partner Element’s machine learning models are able to detect potential supplier conversations. When these models scan inboxes and emails to provide you with Partner Element’s services, the models improve based on the information being scanned. Continuous improvement through exposure to new information helps our machine learning models become more accurate and precise, which in turn enables us to provide you with our services.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={accordionBase}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={accordionHeader}
                        >
                            <Typography sx={accordionHeaderText}>
                                How is the data stored?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={accordionDetails}>
                            <Typography sx={accordionContent}>
                                The data is encrypted at rest and protected inside Partner Element’s virtual private cloud (VPC) in AWS. We do not provide your data to any third parties besides the sub-processors approved by your organization, such as AWS. The encryption keys live in a separate environment only accessible by Partner Element’s internal systems.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={accordionBase}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={accordionHeader}
                        >
                            <Typography sx={accordionHeaderText}>
                                How long are emails stored?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={accordionDetails}>
                            <Typography sx={accordionContent}>
                                Partner Element only keeps emails for as long as needed to detect supplier communications. Generally, most emails are deleted after a few seconds, but occasionally, they can be stored for up to three months — or the maximum time permitted by the customer’s email retention policy — to troubleshoot and improve system performance. Partner Element might store de-identified copies to improve the accuracy of supplier detection.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={accordionBase}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={accordionHeader}
                        >
                            <Typography sx={accordionHeaderText}>
                                Besides email integration, what other integrations with internal systems does Partner Element need to deliver its services?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={accordionDetails}>
                            <Typography sx={accordionContent}>
                                Besides the email integration, Partner Element requires integration with an HR system, such as Workday, to display the employee’s team and position. Without it, only employee names and emails are shown.
                                <br /><br />
                                Also, Partner Element can integrate with ERP systems, such as Netsuite, and contract management platforms, such as Ironclad, to help your procurement team prioritize supplier deals using historical spend data and active contracts.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </CustomCard>


        </>
    );
};

export default FAQ;
