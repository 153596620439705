import { Box, Stack, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReportFilters from './ReportFilters';
import SpendingStats from './SpendingStats';
import ProcurementEngagement from './ProcurementEngagement';
import SupplierTrends from './SupplierTrends';
import { generateFilters } from './reportHelpers';
import PlaceholderTimeSavings from './PlaceholderTimeSavings';
import PlaceholderCostSavings from './PlaceholderCostSavings';
import { getFiltersReports } from '../../store/localStorage';

const Reports = () => {
  const [page, setPage] = useState('spendingStats');
  const [filters, setFilters] = useState(getFiltersReports());
  const [filterValues, setFilterValues] = useState();

  const getFilterOptions = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/report/filters`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const fetchData = async () => {
    const data = await getFilterOptions();
    if (data !== 'error') {
      const filterData = generateFilters(data);
      setFilterValues(filterData);
    }
  };

  const viewPage = (props) => {
    switch (page) {
      case 'spendingStats':
        return <SpendingStats {...props} />;
      case 'procurementEngagement':
        return <ProcurementEngagement {...props} />;
      case 'supplierTrends':
        return <SupplierTrends {...props} />;
      case 'timeSavings':
        return <PlaceholderTimeSavings />;
      case 'costSavings':
        return <PlaceholderCostSavings />;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    fetchData();
    document.title = 'PartnerElement | Reports';
  }, []);

  const tabStyles = {
    fontSize: '16px',
    fontWeight: '500',
    opacity: '1',
    height: '34px',
    minHeight: 0,
    minWidth: 0,
    padding: 0,
    marginRight: '24px',
    textTransform: 'none',
    span: { background: 'transparent' },
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.60)',
      '>.MuiTab-iconWrapper': {
        marginRight: 0,
      },
    },
  };

  return (
    <Box
      sx={{
        minHeight: 'calc(100vh - 68px)',
      }}
    >
      <Box
        borderBottom={'1.5px solid #6558B11A'}
        paddingTop={'24px'}
        margin={'0 24px'}
      >
        <Tabs
          value={page}
          textColor={'inherit'}
          sx={{ height: '100%' }}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            sx: {
              color: 'secondary',
            },
          }}
          TabScrollButtonProps={{
            sx: {
              color: 'rgba(101, 88, 177, 0.45)',
            },
          }}
          indicatorColor={'secondary'}
        >
          <Tab
            label="Spending stats"
            value={'spendingStats'}
            sx={{
              color: page === 'spendingStats' ? '#694FA3' : '#00000099',
              ...tabStyles,
            }}
            onClick={() => {
              setPage('spendingStats');
            }}
          />
          <Tab
            label="Procurement engagement"
            value={'procurementEngagement'}
            sx={{
              color: page === 'procurementEngagement' ? '#694FA3' : '#00000099',
              ...tabStyles,
            }}
            onClick={() => {
              setPage('procurementEngagement');
            }}
          />
          <Tab
            label="Cross-team or duplicate suppliers"
            value={'supplierTrends'}
            sx={{
              color: page === 'supplierTrends' ? '#694FA3' : '#00000099',
              ...tabStyles,
            }}
            onClick={() => {
              setPage('supplierTrends');
            }}
          />
          {/* <Tab
            label="Time savings"
            value={'timeSavings'}
            sx={{
              color: page === 'timeSavings' ? '#694FA3' : '#00000099',
              ...tabStyles,
            }}
            onClick={() => {
              setPage('timeSavings');
            }}
          />
          <Tab
            label="Cost savings"
            value={'costSavings'}
            sx={{
              color: page === 'costSavings' ? '#694FA3' : '#00000099',
              ...tabStyles,
            }}
            onClick={() => {
              setPage('costSavings');
            }}
          /> */}
        </Tabs>
      </Box>
      <Stack width={'100%'} direction={'row'}>
        <Box sx={{ maxWidth: '312px', minWidth: '312px', width: '100%' }}>
          {
            <ReportFilters
              filters={filters}
              setFilters={setFilters}
              filterData={filterValues}
              page={page}
            />
          }
        </Box>
        <Box
          sx={{
            background: '#FDFDFD',
            padding: '24px 24px 16px 24px',
            width: '100%',
          }}
        >
          {viewPage({ filters })}
        </Box>
      </Stack>
    </Box>
  );
};

export default Reports;
