import React from 'react';
import { Box } from '@mui/material';

const EngagementTimingLine = ({ item }) => {
    const totalDays = item.independent_engagement_days + item.collaborative_engagement_days + item.workflow_days;
    const getStyle = (stage, percentage) => ({
        width: `${percentage}%`,
        height: "13px",
        background: stage === 'without' ? "#FFDBD2" : stage === 'with' ? "#CCE7FF" : "#D5F7CF",
        opacity: "0.7",
        borderRadius: stage === 'without' ? "8px 0 0 8px" : stage === 'with' && item?.independent_engagement_days ? "0" : stage === 'with' ? "8px 0 0 8px" : "0 8px 8px 0",
        borderRight: stage !== 'inWorkflow' && item?.workflow_days !== 0 ? "0.5px solid white" : "",
    });

    return (
        <>
            <Box sx={getStyle('without', (item.independent_engagement_days / totalDays) * 100)} />
            <Box sx={getStyle('with', (item.collaborative_engagement_days / totalDays) * 100)} />
            <Box sx={getStyle('inWorkflow', (item.workflow_days / totalDays) * 100)} />
        </>
    );
};

export default EngagementTimingLine;