import {
  Box,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { capitalizeFirst } from '../../utils/functions';
import ReportTableModal from './ReportTableModal';
import { useNavigate } from 'react-router-dom';
import StyledIcon from '../../common/StyledIcon';
import { Visibility } from '@mui/icons-material';
import { REQUEST_PROCUREMENT_ENGAGEMENT_HEADERS } from './reportHelpers';
import InfoMessageHover from '../../common/InfoMessageHover';
import { getCellDesc, getComparator } from '../../common/SortingHelper';
import EnhancedTableHead from '../../common/EnhancedTableHead';

export const InnerTable = ({
  tableHeaders,
  collection,
  options,
  handleLinkClick,
  allowSortBy,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRequests, setCurrentRequests] = useState([]);
  const [tableOrder, setTableOrder] = useState('desc');
  const [tableOrderBy, setTableOrderBy] = useState(null);

  const collectionSize = currentRequests?.length || 0;
  const pluralText = `${collectionSize === 1 ? 'request' : 'requests'}`;
  const collectionText = `${collectionSize} ${pluralText} total`;

  const modalPerRowOptions = {
    type: 'request',
    plural: 'requests',
  };

  const handleViewRequests = (item) => {
    const requests = options.modalPerRow.filter(
      (request) => request.category_label === item.category_label,
    );
    setIsModalOpen(true);
    setCurrentRequests(requests);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = tableOrderBy === property && tableOrder === 'asc';

    setTableOrder(isAsc ? 'desc' : 'asc');
    setTableOrderBy(property);
  };

  const visibleRows = useMemo(() => {
    const cellDesc = getCellDesc(tableHeaders, tableOrderBy);

    return collection
      ?.slice()
      .sort(
        getComparator(tableOrder, tableOrderBy, cellDesc?.numeric || false),
      );
  }, [collection, tableOrder, tableOrderBy]);

  return (
    <Box>
      <Table
        sx={{
          borderCollapse: 'separate !important',
          width: 'width: 100%;',
        }}
      >
        {allowSortBy ? (
          <EnhancedTableHead
            headCells={tableHeaders}
            onRequestSort={handleRequestSort}
            order={tableOrder}
            orderBy={tableOrderBy}
            tableRowSx={{
              th: {
                borderBottom:
                  visibleRows?.length === 0 ? 'none' : '1.5px solid #694FA31A',
                padding: '16px',
                paddingRight: '0',
                fontWeight: 'subtitle2',
                fontSize: 'subtitle2',
                color: '#000000DE',
              },
            }}
          />
        ) : (
          <TableHead>
            <TableRow
              sx={{
                th: {
                  borderBottom:
                    collection?.length === 0 ? 'none' : '1.5px solid #694FA31A',
                  padding: '16px',
                  paddingRight: '0',
                  fontWeight: 'subtitle2',
                  fontSize: 'subtitle2',
                  color: '#000000DE',
                },
              }}
            >
              {tableHeaders.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    minWidth: header.width,
                    verticalAlign: 'bottom',
                    textAlign: header.align || 'right',
                    ':last-of-type': { paddingRight: '16px' },
                  }}
                >
                  <Box width={'100%'}>
                    <Box>{header.label}</Box>
                    {Boolean(header.tooltip) && (
                      <InfoMessageHover message={header.tooltip} />
                    )}
                  </Box>
                </TableCell>
              ))}
              {options.modalPerRow && <TableCell minWidth={'50px'}></TableCell>}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {visibleRows.map((item, index) => (
            <TableRow
              key={`${options.type}-${index}`}
              sx={{
                td: {
                  border: 'none',
                  borderBottom: '1.5px solid #694FA31A',
                  padding: '16px',
                  paddingRight: '0',
                  color: '#000000DE',
                  fontSize: '14px',
                  whiteSpace: 'normal',
                },
                ':last-of-type': { td: { borderBottom: 'none' } },
              }}
            >
              {tableHeaders.map((header) => (
                <TableCell
                  key={`${options.type}-${index}-${header.key}`}
                  sx={{
                    textAlign: header.align || 'right',
                    ':last-of-type': { paddingRight: '16px' },
                  }}
                >
                  {options.arrayValue &&
                  options.arrayValue.includes(header.key) ? (
                    <ArrayList list={item[header.key]} />
                  ) : header.link ? (
                    <TableLink handleLinkClick={handleLinkClick} item={item} />
                  ) : (
                    item[header.key]
                  )}
                </TableCell>
              ))}
              {options.modalPerRow && (
                <TableCell sx={{ padding: '6px 0 0 6px !important' }}>
                  <IconButton
                    padding={'8px'}
                    onClick={() => handleViewRequests(item)}
                  >
                    <StyledIcon component={Visibility} color={'icon'} />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {options.modalPerRow && (
        <ReportTableModal
          collection={currentRequests.sort(
            (a, b) => Number(b.deal_value) - Number(a.deal_value),
          )}
          open={isModalOpen}
          handleClose={() => {
            setIsModalOpen(false);
          }}
          collectionText={collectionText}
          options={modalPerRowOptions}
          tableHeaders={REQUEST_PROCUREMENT_ENGAGEMENT_HEADERS}
        />
      )}
    </Box>
  );
};

const ArrayList = ({ list }) => {
  const [viewAll, setViewAll] = useState(false);
  const total = list.length;
  const miniList = list.slice(0, 3);
  if (viewAll && total > 3) {
    return (
      <Box>
        {list.join(', ')}{' '}
        <Link
          color="secondary"
          underline={'hover'}
          sx={{ cursor: 'pointer' }}
          onClick={() => setViewAll(false)}
        >
          View less
        </Link>
      </Box>
    );
  }
  return (
    <Box>
      {miniList.join(', ')}{' '}
      {total > 3 && (
        <Link
          color="secondary"
          underline={'hover'}
          sx={{ cursor: 'pointer' }}
          onClick={() => setViewAll(true)}
        >{`View all ${total}`}</Link>
      )}
    </Box>
  );
};

const TableLink = ({ item, handleLinkClick }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    handleLinkClick && handleLinkClick();
    navigate(item.linkUrl);
  };
  if (!item?.linkUrl) return <></>;
  return (
    <Link
      color="secondary"
      underline={'hover'}
      sx={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      {item?.linkLabel}
    </Link>
  );
};

const ReportTable = ({ tableHeaders, collection, options }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const collectionSize = collection?.length || 0;
  const pluralText = `${
    collectionSize === 1
      ? options.type
      : options.plural
      ? options.plural
      : `${options.type}s`
  }`;
  const collectionText = `${collectionSize} ${pluralText} total`;

  return (
    <Box
      sx={{
        border: `${options.includeBorder ? '1.5px solid #694FA31A' : '0px'}`,
        borderRadius: '12px',
        marginBottom: '24px',
        width: '100%',
      }}
    >
      {options.includeHeader && (
        <Box
          sx={{
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            borderBottom: '1.5px solid #694FA31A',
          }}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
            paddingBottom={'4px'}
          >
            {options.title || capitalizeFirst(options.plural)}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.medium}
            >
              {collectionText}
            </Box>
          </Box>
        </Box>
      )}
      <InnerTable
        tableHeaders={tableHeaders}
        collection={options.modal ? collection.slice(0, 3) : collection}
        options={options}
        handleLinkClick={() => {
          setIsModalOpen(false);
        }}
      />
      {options.modal && collection.length > 3 && (
        <Box
          sx={{
            padding: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTop: '1.5px solid #694FA31A',
          }}
        >
          <Link
            color="secondary"
            sx={{ fontSize: '14px', fontWeight: '500', cursor: 'pointer' }}
            underline="hover"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            {`See all ${options.plural}`}
          </Link>
        </Box>
      )}
      {options.modal && (
        <ReportTableModal
          collection={collection}
          open={isModalOpen}
          handleClose={() => {
            setIsModalOpen(false);
          }}
          collectionText={collectionText}
          options={options}
          tableHeaders={tableHeaders}
        />
      )}
    </Box>
  );
};

export default ReportTable;
