import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import { appSlice } from "../../store/appSlice";

import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  CloseRounded,
  LabelOutlined,
  MailOutlineRounded,
  PhoneOutlined,
  PeopleOutlineRounded,
  ContentCopyRounded,
  KeyboardDoubleArrowRight,
  Grading,
  CheckCircleRounded,
} from "@mui/icons-material";

import InteractionLogs from "../interactionDetails/InteractionLogs"

import { stageVariant } from "../../common/CustomChip";
import PopupMessage from "../../common/PopupMessage";
import StyledIcon from "../../common/StyledIcon";
import Logo from "../../common/Logo";
import CustomDropMenu from "../../common/CustomDropMenu";
import ConfirmCopy from "../../common/ConfirmCopy";
import LoadingSkeleton from "../../common/LoadingSkeleton";
import ReviewButtonGroup from "../../common/ReviewBtnGroup";
import { getBundleData, publishInteractions } from "../../common/DataHelper";
import BackofficeDetailsNavigation from "../../common/BackofficeDetailsNavigation";

const lineStyle = {
  background: "#DEDBEC",
  width: "100%",
  height: "2px",
};

const ReviewInteractionDetails = ({ close }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { interactionIdsBackoffice } = useSelector(
    (state) => state.appSlice
  );

  const [interaction, setInteraction] = useState();
  const [bundle, setBundle] = useState();

  const [reviewStatus, setReviewStatus] = useState("New");
  const [isLoading, setIsLoading] = useState(true);
  const [openMailId, setOpenMailId] = useState(null);
  const [openPhoneId, setOpenPhoneId] = useState(null);
  const [popupMessage, setPopupMessage] = useState("");
  const [confirmCopy, setConfirmCopy] = useState("id + variant");

  const handleConfirmCopy = (id, variant) => {
    setConfirmCopy(`${id} ${variant}`);
    setTimeout(() => {
      setConfirmCopy("");
    }, 1000);
  };

  const handleMailPopoverOpen = (id) => {
    setOpenMailId(id);
  };

  const handleMailPopoverClose = () => {
    setOpenMailId(null);
  };
  const handlePhonePopoverOpen = (id) => {
    setOpenPhoneId(id);
  };

  const handlePhonePopoverClose = () => {
    setOpenPhoneId(null);
  };

  const filterInternalStakeholders = (res) => {
    return res.departments_involved
      .map((item) => ({
        type: item,
        items: res.internal_stakeholders.filter(
          (internal) => internal.department === item
        ),
      }))
      .filter((item) => item.items.length !== 0);
  };

  const fetchData = async () => {
    const data = await getBundleData(id);

    if (data !== "error" && data?.bundle?.interaction) {
      setInteraction({
        ...data.bundle.interaction,
        internal_stakeholders: filterInternalStakeholders(data.bundle.interaction),
      });

      setBundle(data.bundle);

      if (data.bundle?.supplier_manual !== null) {
        setReviewStatus("Reviewed");
      }
      else {
        setReviewStatus("New");
      }
    }
  };

  const publish = (interactionId) => {
    publishInteractions([interactionId]).then((res) => {
      if (res) {
        setBundle(prevBundle => ({
          ...prevBundle,
          interaction: {
            ...prevBundle.interaction,
            status: 'published',
          }
        }));

        dispatch(
          appSlice.actions.updatePublishedInteractionStatus([interactionId]),
        );
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData().then(() => setIsLoading(false));
  }, [id]);

  const handleReviewUpdate = (supplierManualValue) => {
    setReviewStatus("Reviewed");
    setBundle(prevBundle => ({ ...prevBundle, supplier_manual: supplierManualValue }));
    dispatch(appSlice.actions.updateReviewStatus({ bundleId: id, isSupplierManual: supplierManualValue }));
  };

  if (isLoading && !interaction) {
    return <LoadingSkeleton variant={"interactionDetails"} close={close} />;
  }

  return (
    <Box>
      <Box
        sx={{
          width: "720px",
          minHeight: "calc(100vh - 88px)",
          maxHeight: "calc(100vh - 88px)",
          overflowY: "auto",
          padding: "16px 24px 72px 24px",
          background: "white",
          "@media (max-width: 768px)": {
            width: "calc(100vw - 48px)",
          },
        }}
      >
        {interaction && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1.5px solid #694FA31A",
                paddingBottom: "20px",
                marginBottom: "24px",
              }}
            >
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.subtitle1}
              >
                Interaction
              </Box>
              <Box display={'flex'} alignItems={'center'}>
                <BackofficeDetailsNavigation
                  itemId={Number(id)}
                  idArray={interactionIdsBackoffice}
                  navigationLink={'/backoffice/details/interaction/'}
                />
                <Box>
                  <IconButton
                    type="button"
                    sx={{ padding: '8px' }}
                    onClick={close}
                  >
                    <CloseRounded sx={{ color: '#2F236D73', fontSize: 24 }} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "16px",
              }}
            >
              <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
                <Logo size={44} img={interaction.supplier_image_url} />
                <Box>
                  <Box
                    onClick={() =>
                      navigate(`/backoffice/details/supplier/${interaction?.supplier_id}`)
                    }
                    sx={{
                      lineHeight: "160%",
                      cursor: "pointer",
                    }}
                    color={(theme) => theme.palette.secondary.main}
                    fontSize={(theme) => theme.typography.h6}
                  >
                    {interaction?.supplier_name}
                  </Box>
                  <Box
                    fontSize={(theme) => theme.typography.caption}
                    color={(theme) => theme.palette.text.medium}
                  >
                    ID:{interaction?.uid}
                  </Box>
                </Box>
              </Box>

              <Box>
                {bundle?.interaction?.status === 'published' &&
                  <Stack flexDirection={"row"} alignItems={"center"} gap={"5px"} marginTop={"8px"}>
                    <StyledIcon component={CheckCircleRounded} color={"icon_success"} />Published
                  </Stack>
                }
                {bundle?.interaction?.status !== 'published' &&
                  <Button
                    onClick={() =>
                      publish(bundle?.interaction?.id)
                    }
                    variant={"contained"}
                    color={"secondary"}
                    sx={{ borderRadius: "10px" }}
                    disabled={bundle?.supplier_manual === null}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "end",
                        gap: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          lineHeight: "24px",
                          textTransform: "none",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Publish
                      </Box>
                    </Box>
                  </Button>
                }
              </Box>

            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                borderBottom: "1.5px solid #694FA31A",
                paddingBottom: "16px",
                marginBottom: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "6px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Stack flexDirection={"row"} gap={"5px"}>
                  <Box
                    color={(theme) => theme.palette.text.medium}
                    fontSize={(theme) => theme.typography.body1}
                    sx={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <Grading
                      sx={{ fontSize: 24, color: "#2F236D73" }}
                    />
                  </Box>
                  <Chip
                    label={reviewStatus}
                    size={"small"}
                    sx={{
                      padding: "4px 10px",
                      span: { padding: 0 },
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      ...stageVariant(reviewStatus),
                    }}
                  />
                </Stack>

                <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
                  <Box marginBottom={"8px"}>
                    Prospective supplier
                  </Box>

                  <ReviewButtonGroup
                    reviewStatus={bundle?.supplier_manual}
                    id={id}
                    onReviewUpdate={handleReviewUpdate}
                  />
                </Box>
              </Box>

            </Box>
            <Box sx={{ marginBottom: "24px" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "6px",
                  alignItems: "start",
                  marginBottom: "24px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                  <StyledIcon component={LabelOutlined} color="icon" />
                  <Box
                    color={(theme) => theme.palette.text.medium}
                    fontSize={(theme) => theme.typography.body1}
                  >
                    Keywords
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                  {interaction?.keywords.map((item, index) => (
                    <Chip
                      size={"small"}
                      key={index}
                      variant={"filled"}
                      sx={{
                        span: { padding: 0 },
                        padding: "4px 10px",
                        color: "#000000DE",
                        fontSize: "13px",
                      }}
                      label={item.keyword}
                    />
                  ))}
                </Box>
              </Box>

              <Stack flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                <Box sx={{ display: "flex", gap: "6px", marginBottom: "16px" }}>
                  <StyledIcon component={MailOutlineRounded} color="icon" />
                  <Box>
                    <Box
                      color={(theme) => theme.palette.text.main}
                      fontSize={(theme) => theme.typography.body1}
                      sx={{
                        marginBottom: "6px",
                      }}
                    >
                      {interaction?.num_emails} messages over{" "}
                      {interaction?.num_days} days
                    </Box>
                    <Box
                      color={(theme) => theme.palette.text.medium}
                      fontSize={(theme) => theme.typography.body2}
                    >
                      {moment(new Date(interaction?.start_time)).format(
                        "MMM DD, YYYY"
                      )}
                      {" - "}
                      {moment(new Date(interaction?.end_time)).format(
                        "MMM DD, YYYY"
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Button
                    onClick={() =>
                      navigate(`/backoffice/details/messages/${id}`, { state: { from: location, hideDetailsNav: true } })
                    }

                    color={"secondary"}
                    sx={{ borderRadius: "10px", border: '1px solid' }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "end",
                        gap: "8px",
                      }}
                    >
                      <StyledIcon component={KeyboardDoubleArrowRight} />
                      <Box
                        sx={{
                          lineHeight: "24px",
                          textTransform: "none",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        View messages
                      </Box>
                    </Box>
                  </Button>
                </Box>
              </Stack>

              <Box sx={{ display: "flex", gap: "6px" }}>
                <StyledIcon component={PeopleOutlineRounded} color="icon" />
                <Box>
                  <Box
                    component={"span"}
                    color={(theme) => theme.palette.text.main}
                    sx={{
                      marginRight: "4px",
                    }}
                    fontSize={(theme) => theme.typography.subtitle1}
                  >
                    {interaction?.num_stakeholders} people
                  </Box>
                  <Box
                    component={"span"}
                    color={(theme) => theme.palette.text.medium}
                  >
                    involved
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                border: "1px solid #694FA31A",
                borderRadius: "12px",

                marginBottom: "24px",
              }}
            >
              <Box
                sx={{
                  padding: "16px",
                  paddingBottom: "0",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    color={(theme) => theme.palette.text.main}
                    fontSize={(theme) => theme.typography.h6}
                  >
                    Employees
                  </Box>
                  <Box sx={{ position: "relative" }}>

                  </Box>
                </Box>
              </Box>
              <Box>
                {interaction?.internal_stakeholders.map((internal, index) => (
                  <Box key={internal.type}>
                    <Box
                      sx={{
                        borderTop: index !== 0 && "1.5px solid #694FA31A",
                        borderBottom: "1.5px solid #694FA31A",
                        padding: index === 0 ? "16px" : "24px 16px 16px",
                      }}
                    >
                      <Chip
                        variant={"outlined"}
                        color={"secondary"}
                        size={"small"}
                        label={internal.type || "Unknown"}
                      />
                    </Box>
                    <Box sx={{ overflowX: "auto" }}>
                      <Table>
                        <TableHead>
                          <TableRow
                            sx={{
                              borderBottom:
                                internal.items.length === 0
                                  ? "none"
                                  : "1.5px solid rgba(101, 88, 177, 0.07)",
                              th: {
                                border: "none",
                                padding: "16px",
                                paddingRight: "0",
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "#000000DE",
                              },
                            }}
                          >
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "nowrap",
                                  gap: "8px",
                                  alignItems: "center",
                                }}
                              >
                                Name
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "nowrap",
                                  gap: "8px",
                                  alignItems: "center",
                                }}
                              >
                                Title
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "nowrap",
                                  gap: "8px",
                                  alignItems: "center",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Messages
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "nowrap",
                                  gap: "8px",
                                  alignItems: "center",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Last active
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {internal.items.map((item) => (
                            <TableRow
                              key={item.id}
                              sx={{
                                borderBottom: "1.5px solid #694FA31A",
                                td: {
                                  border: "none",
                                  padding: "16px",
                                  paddingRight: "0",
                                  color: "#000000DE",
                                  fontSize: "14px",
                                  whiteSpace: "nowrap",
                                },
                                ":last-of-type": { borderBottom: "none" },
                              }}
                            >
                              <TableCell>{item.name}</TableCell>
                              <TableCell>
                                <Box
                                  sx={{
                                    maxWidth: "150px",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  {item.title}
                                </Box>
                              </TableCell>
                              <TableCell>{item.num_emails_total}</TableCell>
                              <TableCell>
                                {moment(item.last_active_at).format(
                                  "MMM DD, YYYY"
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                border: "1.5px solid #694FA31A",
                borderRadius: "12px",
                marginBottom: "24px",
              }}
            >
              <Box
                sx={{
                  padding: "16px",
                  borderBottom: "1.5px solid #694FA31A",
                }}
              >
                <Box
                  fontSize={(theme) => theme.typography.h6}
                  color={(theme) => theme.palette.text.main}
                >
                  Supplier contacts
                </Box>
              </Box>
              <Box>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        th: {
                          borderBottom:
                            interaction?.external_stakeholders.length === 0
                              ? "none"
                              : "1.5px solid #694FA31A",
                          padding: "16px",
                          paddingRight: "0",
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#000000DE",
                        },
                      }}
                    >
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "nowrap",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          Name
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "nowrap",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          Title
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "nowrap",
                            gap: "8px",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Messages
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "nowrap",
                            gap: "8px",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Last active
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {interaction?.external_stakeholders.map((item) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          borderBottom: "1.5px solid #694FA31A",
                          td: {
                            border: "0px",
                            padding: "16px",
                            paddingRight: "0",
                            color: "#000000DE",
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                          },
                          ":last-of-type": { borderBottom: "none" },
                        }}
                      >
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "12px",
                              alignItems: "center",
                            }}
                          >
                            <Box>{item.name}</Box>
                            <Box sx={{ display: "flex", gap: "8px" }}>
                              <Box
                                sx={{ position: "relative" }}
                                onMouseEnter={() =>
                                  handleMailPopoverOpen(item.id)
                                }
                                onMouseLeave={handleMailPopoverClose}
                              >
                                <IconButton sx={{ padding: 0 }}>
                                  <StyledIcon
                                    component={MailOutlineRounded}
                                    color={"secondary"}
                                    sx={{ fontSize: 20 }}
                                  />
                                </IconButton>

                                <CustomDropMenu
                                  isOpen={Boolean(
                                    openMailId === item.id && item.email
                                  )}
                                  positionVariant={"bottom-left"}
                                  extraStyles={{
                                    borderRadius: "12px",
                                    marginTop: "20px",
                                    padding: "16px",
                                    maxWidth: "500px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <Box
                                      color={(theme) => theme.palette.text.main}
                                      fontSize={(theme) =>
                                        theme.typography.body2
                                      }
                                      sx={{
                                        lineHeight: "143%",
                                      }}
                                    >
                                      {item.email}
                                    </Box>

                                    <Box sx={{ position: "relative" }}>
                                      <IconButton
                                        sx={{ padding: 0 }}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            item.email
                                          );
                                          handleConfirmCopy(item?.id, "email");
                                        }}
                                      >
                                        <StyledIcon
                                          component={ContentCopyRounded}
                                          color={"icon"}
                                        />
                                      </IconButton>
                                      {confirmCopy === `${item?.id} email` && (
                                        <ConfirmCopy
                                          message={"Email copied"}
                                          positionStyles={{
                                            marginTop: "28px",
                                            marginLeft: "calc(-100% - 12px)",
                                          }}
                                        />
                                      )}
                                    </Box>
                                  </Box>
                                </CustomDropMenu>
                              </Box>
                              {item.phone && (
                                <Box
                                  sx={{ position: "relative" }}
                                  onMouseEnter={() =>
                                    handlePhonePopoverOpen(item.id)
                                  }
                                  onMouseLeave={handlePhonePopoverClose}
                                >
                                  <IconButton sx={{ padding: 0 }}>
                                    <StyledIcon
                                      component={PhoneOutlined}
                                      color={"secondary"}
                                      sx={{ fontSize: 20 }}
                                    />
                                  </IconButton>
                                  <CustomDropMenu
                                    isOpen={Boolean(
                                      openPhoneId === item.id && item.phone
                                    )}
                                    positionVariant={"bottom-left"}
                                    extraStyles={{
                                      borderRadius: "12px",
                                      marginTop: "20px",
                                      padding: "16px",
                                      maxWidth: "500px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <Box
                                        color={(theme) =>
                                          theme.palette.text.main
                                        }
                                        fontSize={(theme) =>
                                          theme.typography.body2
                                        }
                                      >
                                        {item.phone}
                                      </Box>
                                      <Box sx={{ position: "relative" }}>
                                        <IconButton
                                          sx={{ padding: 0 }}
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              item.phone
                                            );
                                            handleConfirmCopy(
                                              item?.id,
                                              "phone"
                                            );
                                          }}
                                        >
                                          <StyledIcon
                                            component={ContentCopyRounded}
                                            color={"icon"}
                                          />
                                        </IconButton>
                                        {confirmCopy ===
                                          `${item?.id} phone` && (
                                            <ConfirmCopy
                                              message={"Phone copied"}
                                              positionStyles={{
                                                marginTop: "28px",
                                                marginLeft: "calc(-100% - 12px)",
                                              }}
                                            />
                                          )}
                                      </Box>
                                    </Box>
                                  </CustomDropMenu>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box sx={{ maxWidth: "150px", whiteSpace: "normal" }}>
                            {item.title}
                          </Box>
                        </TableCell>
                        <TableCell>{item.num_emails_total}</TableCell>
                        <TableCell>
                          {moment(item.last_email_sent_at).format(
                            "MMM DD, YYYY"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Box>
            <InteractionLogs interaction={interaction} />
            <PopupMessage
              open={Boolean(popupMessage.message)}
              message={popupMessage}
              close={() => setPopupMessage("")}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ReviewInteractionDetails;