// TODO delete after outreach is done
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CustomCard from "../../common/CustomCard";
import StyledIcon from "../../common/StyledIcon";
import {
  ModeEditOutlineOutlined,
  DescriptionOutlined,
} from "@mui/icons-material";
import TemplatePopup from "./TemplatePopup";
import LoadingSkeleton from "../../common/LoadingSkeleton";
import { ReactComponent as StarFilled } from "../../common/icons/starFilled.svg";

const Templates = () => {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [variantPopup, setVariantPopup] = useState("");
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const getTemplates = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/templates`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "GET",
        credentials: "include",
      }
    );
    if (response.ok) {
      return await response.json();
    } else {
      return "error";
    }
  };
  const getData = () => {
    getTemplates().then((res) => {
      setTemplates(res);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    document.title = "PartnerElement | Settings > Templates";
    getData();
  }, []);

  return (
    <>
      <Box sx={{ width: '100%', height: 'fit-content' }}>
        <CustomCard>
          <Box>
            <Stack
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ borderBottom: '1px solid #694FA31A', padding: '16px' }}
            >
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.h6}
              >
                Templates
                <Box
                  color={(theme) => theme.palette.text.medium}
                  fontSize={(theme) => theme.typography.body2}
                >
                  Add or edit your team's message templates to engage with
                  employees on any interaction.
                </Box>
              </Box>
              <Button
                onClick={() => {
                  setOpenPopup(true);
                  setVariantPopup('add');
                }}
                variant={'outlined'}
                color={'secondary'}
                sx={{
                  borderRadius: '10px',
                  textTransform: 'none',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                startIcon={
                  <StyledIcon
                    component={DescriptionOutlined}
                    color={'secondary'}
                  />
                }
              >
                Add New
              </Button>
            </Stack>
            <Box>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      th: {
                        borderBottom:
                          templates?.length === 0
                            ? 'none'
                            : '1.5px solid #694FA31A',
                        padding: '16px',
                        fontWeight: 'subtitle2',
                        fontSize: 'subtitle2',
                        color: '#000000DE',
                      },
                    }}
                  >
                    <TableCell width={'20%'}>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                        }}
                      >
                        {!isLoading && 'Title'}
                      </Box>
                    </TableCell>
                    <TableCell width={'40%'}>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                        }}
                      >
                        {!isLoading && 'Message'}
                      </Box>
                    </TableCell>
                    <TableCell width={'40%'}>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                        }}
                      >
                        {!isLoading && 'Last edited'}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading
                    ? [0, 1, 2, 3].map((_, index) => (
                        <LoadingSkeleton variant={'templates'} key={index} />
                      ))
                    : templates?.map((item, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            borderBottom: '1.5px solid #694FA31A',
                            td: {
                              border: 'none',
                              padding: '16px',
                              color: '#000000DE',
                              fontWeight: 'body2',
                              fontSize: 'body2',
                              whiteSpace: 'nowrap',
                            },
                            ':last-of-type': { borderBottom: 'none' },
                          }}
                        >
                          <TableCell>
                            <Stack gap={'5px'}>
                              {item?.title}
                              {item?.default_template && (
                                <Stack direction={'row'} alignItems={'center'}>
                                  <StyledIcon component={StarFilled} color={'icon'} />
                                  <Typography
                                    color={(theme) => theme.palette.text.medium}
                                    fontSize={(theme) =>
                                      theme.typography.caption
                                    }
                                  >
                                    Default
                                  </Typography>
                                </Stack>
                              )}
                            </Stack>
                          </TableCell>

                          <TableCell>
                            <Box
                              sx={{
                                whiteSpace: 'normal',
                              }}
                            >
                              {item?.text}
                            </Box>
                          </TableCell>
                          <TableCell sx={{ paddingRight: '16px' }}>
                            <Stack
                              flexDirection={'row'}
                              justifyContent={'space-between'}
                              alignItems={'start'}
                            >
                              <Box>{item?.updated_at}</Box>
                              <IconButton
                                sx={{
                                  padding: '8px',
                                  position: 'relative',
                                  top: '-8px',
                                }}
                                onClick={() => {
                                  setOpenPopup(true);
                                  setVariantPopup('edit');
                                  setCurrentTemplate(item);
                                }}
                              >
                                <StyledIcon
                                  component={ModeEditOutlineOutlined}
                                  color={'secondary'}
                                />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </CustomCard>
      </Box>
      {openPopup && (
        <TemplatePopup
          open={openPopup}
          variant={variantPopup}
          handleClose={() => setOpenPopup(false)}
          template={currentTemplate}
          getData={getData}
        />
      )}
    </>
  );
};

export default Templates;
