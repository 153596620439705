export const getUsers = async () => {
    const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/users`,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "GET",
            credentials: "include",
        }
    );
    if (response.ok) {
        return await response.json();
    } else {
        return "error";
    }
};

export const getActivityLogData = async (selectedUser, selectedDateRange) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin_logs?user=${selectedUser}&days=${selectedDateRange}`,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "GET",
            credentials: "include",
        }
    );
    if (response.ok) {
        return await response.json();
    } else {
        return "error";
    }
};

export const getBundleData = async (id) => {
    const response = await fetch(
        `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/bundles/${id}`,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "GET",
            credentials: "include",
        }
    );
    if (response.ok) {
        return await response.json();
    } else {
        return "error";
    }
};

export const getOrganizations = async (page) => {
    const filter = page ? `?page=${page}` : ``;
    const response = await fetch(
        `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/organizations${filter}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'GET',
            credentials: 'include',
        },
    );
    if (response.ok) {
        return await response.json();
    } else {
        return 'error';
    }
};

export const publishInteractions = async (interactionIds) => {
    const body = interactionIds && interactionIds.length
        ? JSON.stringify({ uuids: interactionIds })
        : null;

    if (!body) {
        return false;
    }

    const response = await fetch(
        `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/interactions/batch_publish`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            credentials: 'include',
            body,
        },
    );

    if (response.ok) {
        return true;
    } else {
        return false;
    }
};

export const getEmailNotificationConfigs = async () => {
    const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/email_notification_configs`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'GET',
            credentials: 'include',
        },
    );
    if (response.ok) {
        return await response.json();
    } else {
        return 'error';
    }
};

export const getEmailNotificationStats = async () => {
    const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/email_notification_receipts/stats`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'GET',
            credentials: 'include',
        },
    );
    if (response.ok) {
        return await response.json();
    } else {
        return 'error';
    }
};

export const setEmailNotificationStatus = async (id, status) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/email_notification_configs/${id}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify({ active: status }),
        },
    );
    if (response.ok) {
        return await response.json();
    } else {
        return 'error';
    }
};

export const updateUser = async (data) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/users/update_notifications`,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "PATCH",
            body: JSON.stringify(data),
            credentials: "include",
        }
    );
    if (response.ok) {
        return await response.json();
    } else {
        return "error";
    }
};

export const getNotificationSettings = async (email) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/notification_settings?email=${email}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include",
    });

    if (!response.ok) {
        throw new Error('Error');
    }

    return response.json();
};