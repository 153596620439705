import { Box } from '@mui/material';
import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        fontSize={(theme) => theme.typography.body2}
        color={(theme) => theme.palette.text.medium}
        sx={{
          position: 'relative',
          background: 'white',
          padding: '4px 12px',
          borderRadius: '6px',
          boxShadow:
            '0px 1.850000023841858px 6.25px 0px rgba(0, 0, 0, 0.19), 0px 0.5px 1.75px 0px rgba(0, 0, 0, 0.04)',
          top: '-64px',
          '::after': {
            content: "''",
            position: 'absolute',
            width: '14px',
            height: '14px',
            background: 'white',
            transform: 'translateX(-50%) translateY(-50%) rotate(-45deg)',
            top: '100%',
            left: '50%',
            boxShadow:
              '0px 1.850000023841858px 6.25px 0px rgba(0, 0, 0, 0.19), 0px 0.5px 1.75px 0px rgba(0, 0, 0, 0.04)',
            clipPath: 'polygon(0 100%, 0 0, 100% 100%, 0 100%)',
          },
        }}
      >
        {`${payload[0].payload.label} - ${payload[0].payload.totalFormatted} (${payload[0].payload.percent})`}
      </Box>
    );
  }

  return null;
};

const SpendPieChart = ({ data, inModal }) => {
  return (
    <ResponsiveContainer width={'99%'} height={inModal ? 329 : 211}>
      <PieChart width={inModal ? 329 : 211} height={inModal ? 329 : 211}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={inModal ? 160 : 90}
          innerRadius={inModal ? 80 : 40}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default SpendPieChart;
