import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import { getNewInteractions } from "../../store/storeFunctions";

import InteractionItem from "../../common/InteractionItem";
import LoadingSkeleton from "../../common/LoadingSkeleton";
import InfoMessage from "../../common/InfoMessage";
import { createOverviewFilters } from '../../utils/functions';

const NewInteractions = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    newInteractions,
    isLoadingNewInteractions,
    filtersOverview,
    sortBy,
    sortDirection,
  } = useSelector((state) => state.appSlice);

  useEffect(() => {
    if (location.pathname === '/') {
      let overviewFiltersProcessed = createOverviewFilters(
        filtersOverview,
        sortBy,
        sortDirection,
      );
      dispatch(
        getNewInteractions({
          ...overviewFiltersProcessed,
          new_since_last_visit: 'true',
        }),
      );
    }
  }, [location, filtersOverview]);

  if (newInteractions?.length === 0) {
    return <></>;
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <Box
          color={(theme) => theme.palette.text.main}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          fontSize={(theme) => theme.typography.h6}
        >
          New since last login
          <InfoMessage
            message={
              "Engage with teams on the top interactions since your last login."
            }
          />
        </Box>
        <Box fontSize={(theme) => theme.typography.body1}>
          <Box component={"span"} color={(theme) => theme.palette.text.main}>
            {newInteractions?.length} new interactions
          </Box>
        </Box>
      </Box>
      {isLoadingNewInteractions ? (
        <LoadingSkeleton variant={"interactionItem"} />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {newInteractions?.map((interaction) => (
            <InteractionItem key={interaction.id} interaction={interaction} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default NewInteractions;
