import React from "react";
import {
    Box
} from "@mui/material";
import CustomCard from "../../common/CustomCard";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { BorderLeft } from "@mui/icons-material";

const defaultStylesForCard = {
    card: {
        padding: "16px",
        lineHeight: "27px",
        fontSize: "18px",
    },
};

const liStyle = {
    color: (theme) => theme.palette.text.main,
    paddingBottom: "10px",
    paddingTop: "10px",
};

const linkStyle = {
    textDecoration: 'none',
    fontSize: "14px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.87)",
};

const Glossary = ({ role }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    return (
        <>
            <CustomCard>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                    <Box sx={defaultStylesForCard.card}>
                        <Box sx={theme.typography.h4}>
                            Glossary of Partner Element Terminology
                        </Box>
                        <Box sx={theme.typography.text} color={(theme) => theme.palette.text.medium}>
                            <p>
                                Partner Element uses specific terms throughout the product, and this glossary attempts to unify the language for all users across experiences and access levels.
                            </p>
                            <Box color={(theme) => theme.palette.text.main} sx={theme.typography.h6}>
                                Terminology use and explanation
                            </Box>
                            <p sx={theme.typography.text}>
                                Partner Element helps procurement teams maximize their value by facilitating early engagement in potential company purchases. Partner Element detects deals through keyword and language pattern recognition in employee and supplier messages. Employee and supplier messages related to the same deal are collectively referred to as interactions. After an interaction is detected, it’s assigned an engagement stage to indicate location in the purchasing process. A prospective deal can be assigned as exploration, negotiations, or closing, to help procurement teams prioritize resourcing and efforts. Using Partner Element, procurement teams can engage employees or teams throughout the company to provide timely assistance on new deals or renewals, elevating their role as trusted facilitators and caring colleagues.
                            </p>
                        </Box>
                        <Box sx={theme.typography.text}>
                            <Box color={(theme) => theme.palette.text.medium} paddingTop={"10px"}>

                                <Box id="activity-log" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    Activity Log
                                </Box>
                                <p>In the interaction profile: <i>Activity log</i> is a chronological record of all changes, both automatic and manual, that occur for a given interaction.</p>

                                <Box id="company" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    Company
                                </Box>
                                <p><i>Company</i> refers to your employer or partner you represent as a customer in customer-supplier relationships.</p>

                                <Box id="deal" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    Deal
                                </Box>
                                <p><i>Deal</i> refers to a business agreement for products or services between your company and a supplier.</p>

                                <Box id="deprioritize" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    Deprioritize
                                </Box>
                                <p><i>Deprioritize</i> hides an interaction from the active Interactions dashboard; this is a universal change and will affect all users.</p>

                                <Box id="employees" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    Employees
                                </Box>
                                <p><i>Employees</i> are individuals within your company who are involved in supplier <i>interactions</i>. Employees are detected using your company’s email domains.</p>
                                <p style={{ marginTop: "-14px" }}>To include partners with external email addresses, add their domain in your <a style={{
                                    color: "secondary.main",
                                    textDecoration: "none",
                                    cursor: "pointer",
                                }} href="/settings">admin settings</a>.</p>

                                <Box id="interactions" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    Interaction(s)
                                </Box>
                                <p><i>Interactions</i> refer to a group of messages between company employees and supplier contacts related to the same deal. <i>Interactions</i> are the foundational component that procurement teams will manage using Partner Element.</p>

                                <Box id="messages" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    Message(s)
                                </Box>
                                <p><i>Message(s)</i> refers to an email or instant message between employees and suppliers. Partner Element detects interactions by analyzing language patterns and keywords in employee-supplier <b>messages</b>.</p>

                                <Box id="procurement-engaged" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    Procurement Engaged (PE)
                                </Box>
                                <p><i>Procurement engaged</i> is a status that signifies the procurement team is involved in a given interaction. The <i>‘procurement engaged’</i> status is auto-detected by Partner Element, or you can manually assign the ‘PE’ status to an interaction.</p>
                                <p style={{ marginTop: "-14px" }}>Procurement can run weekly, monthly, quarterly, or annual reports to measure the procurement team’s engagement with teams across the organization.</p>

                                <Box id="recommended" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    Recommended
                                </Box>
                                <p><i>Recommended</i> interactions are evaluated and ranked by Partner Element using machine learning an algorithmic assessment of an interaction’s date, message volume, number of employees involved, and deal progression speed.</p>

                                <Box id="renewals" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    Renewals
                                </Box>
                                <p><i>Renewals</i> are interactions that are related to renewing existing deals with suppliers. Renewals are determined using language pattern recognition in supplier messages about extending contract duration or other service terms.</p>

                                <Box id="stages" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    Stages
                                </Box>
                                <p>Stages indicate where an interaction is in the purchasing process. Stages reflect a traditional sales funnel and are determined by keyword detection in supplier messages.</p>
                                <p style={{ marginTop: "-14px" }}><i><b>Interactions</b> have varying <b>stages</b> to help identify priorities and situational urgency.</i></p>
                                <Box sx={theme.typography.h6} color={(theme) => theme.palette.text.main} marginTop={"-14px"}>
                                    Exploration
                                </Box>
                                <Box marginTop={"-14px"}>
                                    <p><i>Exploration</i> indicates employees or teams have expressed interest in the supplier’s products or services via actions, such as demo request, discovery call, request for information, or signing an NDA.</p>
                                </Box>
                                <Box sx={theme.typography.h6} color={(theme) => theme.palette.text.main} marginTop={"-14px"}>
                                    Negotiation
                                </Box>
                                <Box marginTop={"-14px"}>
                                    <p><i>Negotiation</i> indicates that an employee or team is actively discussing pricing or service term agreements.</p>
                                </Box>
                                <Box sx={theme.typography.h6} color={(theme) => theme.palette.text.main} marginTop={"-14px"}>
                                    Closing
                                </Box>
                                <Box marginTop={"-14px"}>
                                    <p><i>Closing</i> indicates that a deal for products or services is being finalized.</p>
                                </Box>

                                <Box id="supplier" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    Supplier
                                </Box>
                                <p>A <i>supplier</i> is an external individual or organization that your company engages with for products or services. Partner Element recognizes suppliers based on language patterns in messages that are detected with proprietary algorithms and machine learning models.</p>

                                <Box sx={theme.typography.h6} color={(theme) => theme.palette.text.main} marginTop={"-14px"}>
                                    Supplier contacts
                                </Box>
                                <Box marginTop={"-14px"}>
                                    <p><i>Supplier contacts</i> are individuals who represent suppliers that procurement or company teams are engaging with.</p>
                                </Box>

                                <Box id="teams" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    Teams
                                </Box>
                                <p><i>Teams</i> are groups within your company determined with existing organizational structure.</p>
                                <Box marginTop={"-14px"}>
                                    <p>Partner Element analyzes <i>interactions</i> between <i>suppliers</i> and targeted <i>teams</i> to identify opportunities for procurement to engage and facilitate <i>deals</i> that benefit fellow <i>employees</i>.</p>
                                </Box>

                                <Box id="sort-by-date" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    [Sort by] Date
                                </Box>
                                <p><i>Date</i> is a sorting label that allows users to arrange interactions by the date the interaction started.</p>

                                <Box id="sort-by-recommended" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    [Sort by] Recommended
                                </Box>
                                <p><i>Recommended</i> is a sorting method that arranges interactions by evaluating date, message volume, number of employees involved, and deal progression speed.</p>

                                <Box id="sort-by-supplier-name" marginBottom={"-14px"} sx={theme.typography.h5} color={(theme) => theme.palette.text.main}>
                                    [Sort by] Supplier Name
                                </Box>
                                <p><i>Supplier name</i> is a sorting method that allows users to arrange interactions alphabetically by the supplier’s name.</p>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ ...defaultStylesForCard.card, width: "308px", minWidth: "308px" }}>
                        <Box sx={{ borderLeft: "1px solid rgba(101, 88, 177, 0.2)", marginTop: "-6px", paddingLeft: "36px" }}>
                            <Box sx={{ ...theme.typography.h6, paddingTop: "16px" }}>
                                Quick access
                            </Box>

                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                <li style={liStyle}><a style={linkStyle} href="#activity-log">Activity Log</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#company">Company</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#deal">Deal</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#deprioritize">Deprioritize</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#employees">Employees</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#interactions">Interaction(s)</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#messages">Message(s)</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#procurement-engaged">Procurement Engaged</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#recommended">Recommended</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#renewals">Renewals</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#stages">Stages</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#supplier">Supplier</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#teams">Teams</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#sort-by-date">[Sort by] Date</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#sort-by-recommended">[Sort by] Recommended</a></li>
                                <li style={liStyle}><a style={linkStyle} href="#sort-by-supplier-name">[Sort by] Supplier Name</a></li>
                            </ul>
                        </Box>
                    </Box>
                </Box>
            </CustomCard>


        </>
    );
};

export default Glossary;
