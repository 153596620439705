import React from 'react';
import { IconButton } from "@mui/material";
import {
  LoginOutlined,
  LogoutOutlined,
} from "@mui/icons-material";
import StyledIcon from "../../common/StyledIcon";
import { setCurrentUserOrg, updateWarningChangeSettings } from "../../store/localStorage";

export const login = async (authenticity_token, auth0_org_id) => {
  let requestUrl =
    `${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?` +
    `scope=openid%20profile%20email&` +
    `response_type=code&` +
    `client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&` +
    `state=${authenticity_token}&` +
    `redirect_uri=${process.env.REACT_APP_API_BASE_AUTH_URL}/callback`;

  if (auth0_org_id) {
    requestUrl += `&organization=${auth0_org_id}`;
  }

  window.location.href = requestUrl;
  return;
};

// perform these on the server
export const LoginButton = () => {
  return (
    <IconButton sx={{ padding: "8px" }} onClick={login}  title='Login'>
      <StyledIcon component={LoginOutlined} color={"icon"} />
    </IconButton>
  );
};

export const LogoutButton = () => {
    const logout = async () => {
    updateWarningChangeSettings(false)
    setCurrentUserOrg(null)
    window.location.href = `${process.env.REACT_APP_API_BASE_AUTH_URL}/logout`;
    return;
  };

  return (
    <IconButton sx={{ padding: "8px" }} onClick={logout} title='Logout'>
      <StyledIcon component={LogoutOutlined} color={"icon"} />
    </IconButton>
  );
};
