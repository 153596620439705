import React from 'react';
import { Box } from '@mui/material';

const StyledDivider = () => (
    <Box
        sx={{
            width: "100%",
            height: "1.5px",
            background: "#694FA380",
            opacity: "0.2",
        }}
    />
);

export default StyledDivider;