import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import EngagementStage from "./EngagementStage";
import NewInteractions from "./NewInteractions";
import Stats from "./Stats";
import InteractionsByDepartments from "./InteractionsByDepartments";
import NoResultCard from "../../common/NoResultCard";
import Notifications from "../../common/Notifications";
import { generateEmployeeFilters, getInteractionsOverview } from "../../store/storeFunctions";
import { useLocation } from "react-router-dom";
import { FullStory } from '@fullstory/browser';
import InteractionsByMembers from "./InteractionsByMembers";

const Overview = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    user, interactionsOverview, isLoadingOverview, search, page, filtersOverview, sortBy,
    sortDirection, procurementFilterData
  } = useSelector((state) => state.appSlice);

  useEffect(() => {
    if (page === "overview") {
      document.title = "PartnerElement | Dashboard";

      if(!procurementFilterData?.internal_stakeholders || !user)  return;

      dispatch(
        getInteractionsOverview({
          timeframe: filtersOverview.timeframe,
          stage: filtersOverview.engagementStage,
          procurement_involved: filtersOverview.procurementInvolved,
          renewals: filtersOverview.renewals,
          deal_closed: "false",
          sort_by: sortBy,
          sort_direction: sortDirection,
          deprioritized: "false",
          procurement_members: generateEmployeeFilters(user, procurementFilterData?.internal_stakeholders, filtersOverview.procurementMembers),
          department_ids: filtersOverview.procurementTeams,
          deal_value_ranges: filtersOverview.dealValueRanges,
        })
      );
    }
  }, [page, procurementFilterData, user, filtersOverview, sortBy, location]);

  if (search && interactionsOverview.length === 0) {
    return (
      <Box sx={{ height: "422px" }}>
        <NoResultCard />
      </Box>
    );
  }

  FullStory('setProperties', {
    type: 'page',
    properties: {
      pageName: 'Dashboard',
    }
  });

  return (
    <Stack flexDirection={"column"} gap={"24px"}>
      <Notifications id={1} />
      <Stats />
      {interactionsOverview.length > 0 ? (
        <>
          <EngagementStage />
          {procurementFilterData?.specific_emails?.length ?
            <InteractionsByMembers
              selectedMembers={filtersOverview.procurementMembers || []}
              procurementMembers={procurementFilterData.specific_emails.map(i => i.value)}
            />
            : <></>
          }
          {procurementFilterData?.departments?.length ?
            <InteractionsByDepartments
              selectedDepartments={filtersOverview.procurementTeams || []}
            />
            : <></>
          }
        </>
      ) : !isLoadingOverview && (
        <Box sx={{ paddingY: "76px" }}>
          <NoResultCard variant={"filtersOverview"} />
        </Box>
      )}

      <NewInteractions />
    </Stack>
  );
};

export default Overview;
