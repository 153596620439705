import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import Details from './components/Details';
import Header from './components/Header';
import Home from './components/Home';
import Settings from './components/settings/Settings';
import { getProcurementFilterData, getUser } from './store/storeFunctions';

import ReactGA from 'react-ga4';
import SearchResults from './components/SearchResults';
import Backoffice from './components/backoffice/Backoffice';
import ReviewDetails from './components/backoffice/ReviewDetails';
import Help from './components/help/Help';
import {
  isBackofficePage,
  isHelpPage,
  isOutreachPage,
  isPDFViewer,
  isSettingsPage,
  isReportsPage
} from './utils/functions';
import Outreach from './components/outreach/Outreach';
import ContractViewer from './components/supplierDetails/ContractViewer';
import Reports from './components/reports/Reports';

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  // Automatically handles page views
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
}

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { search, user } = useSelector((state) => state.appSlice);

  useEffect(() => {
    // If the user isn't logged in, optionally pull Auth0 Org ID for customer auth
    const params = new URLSearchParams(document.location.search);
    dispatch(getUser(params.get('org_id')));
    dispatch(getProcurementFilterData());
  }, []);

  const viewPage = (props) => {
    if (search) {
      return <SearchResults />;
    }

    if (isReportsPage(location)) {
      return <Reports />;
    }

    if (isOutreachPage(location)) {
      return <Outreach />;
    }

    if (isBackofficePage(location)) {
      return <Backoffice />;
    }

    if (isSettingsPage(location)) {
      return <Settings />;
    }

    if (isHelpPage(location)) {
      return <Help />;
    }

    return <Home {...props} />;
  };

  if (!user) {
    return <></>;
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        fontFamily: 'Roboto',
        background: '#FDFDFD',
      }}
    >
      {isPDFViewer(location) ? <></> : <Header />}

      <Routes>
        <Route
          path="/"
          element={
            <>
              {viewPage()}
              <Outlet />
            </>
          }
        >
          <Route path="/details/*" element={<Details />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>

        <Route
          path="/interactions"
          element={
            <>
              <Outlet />
              {viewPage()}
            </>
          }
        >
          <Route path="details/*" element={<Details />} />
        </Route>

        <Route
          path="/suppliers"
          element={
            <>
              <Outlet />
              {viewPage()}
            </>
          }
        >
          <Route path="details/*" element={<Details />} />
        </Route>

        <Route
          path="/backoffice/*"
          element={
            <>
              {viewPage()}
              <Outlet />
            </>
          }
        >
          <Route path="details/*" element={<ReviewDetails />} />
        </Route>

        <Route
          path="/outreach/*"
          element={
            <>
              {viewPage()}
              <Outlet />
            </>
          }
        ></Route>

        <Route
          path="/reports/*"
          element={
            <>
              {viewPage()}
              <Outlet />
            </>
          }
        >
          <Route path="details/*" element={<Details />} />
        </Route>

        <Route
          path="/settings/*"
          element={
            <>
              {viewPage()}
              <Outlet />
            </>
          }
        />
        <Route
          path="/help/*"
          element={
            <>
              {viewPage()}
              <Outlet />
            </>
          }
        />
        <Route
          path="/pdf_view/:supplierId/:contractId"
          element={<ContractViewer />}
        />
      </Routes>
    </Box>
  );
}

export default App;
