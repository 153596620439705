import React, { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import EngagementStageCard from "./EngagementStageCard";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import StyledIcon from "./StyledIcon";
import { createColumns } from "../utils/engagementStage";
import { stageNames } from "../utils/constants";

export const variantBorderRadius = (
  variant,
  engagementStage,
  version,
  item
) => {
  if (version === "small") {
    switch (variant) {
      case stageNames[0].label: {
        return Boolean(
          engagementStage?.closing_stage !== 0 ||
          engagementStage?.negotiation_stage !== 0
        )
          ? { borderRadius: "8px 0 0 8px", padding: "0 0 0 12px" }
          : { borderRadius: "8px", padding: "0 12px" };
      }
      case stageNames[1].label: {
        if (
          Boolean(
            engagementStage?.exploration_stage === 0 &&
            engagementStage?.closing_stage !== 0
          )
        ) {
          return { borderRadius: "8px 0 0 8px", padding: "0 0 0 12px" };
        }
        if (
          Boolean(
            engagementStage?.exploration_stage !== 0 &&
            engagementStage?.closing_stage === 0
          )
        ) {
          return { borderRadius: "0 8px 8px 0", padding: "0 12px 0 0 " };
        }
        if (
          Boolean(
            engagementStage?.exploration_stage !== 0 &&
            engagementStage?.closing_stage !== 0
          )
        ) {
          return { borderRadius: "0px", paddingX: 0 };
        } else {
          return { borderRadius: "8px", padding: "0 12px" };
        }
      }
      case stageNames[2].label: {
        return Boolean(
          engagementStage?.exploration_stage !== 0 ||
          engagementStage?.negotiation_stage !== 0
        )
          ? { borderRadius: "0 8px 8px 0", padding: "0 12px 0 0" }
          : { borderRadius: "8px", padding: "0 12px" };
      }
      default:
        return { borderRadius: 0, padding: 0 };
    }
  }
  switch (variant) {
    case stageNames[0].label: {
      return Boolean(
        engagementStage.closing.items.length !== 0 ||
        engagementStage.negotiation.items.length !== 0
      )
        ? { borderRadius: "8px 0 0 8px", padding: "0 0 0 12px" }
        : { borderRadius: "8px 0 0 8px", padding: "0 0 0 12px" };
    }
    case stageNames[1].label: {
      if (
        Boolean(
          engagementStage.exploration.items.length === 0 &&
          engagementStage.closing.items.length !== 0
        )
      ) {
        return { borderRadius: "0 0 0 0", padding: "0 0 0 0" };
      }
      if (
        Boolean(
          engagementStage.exploration.items.length !== 0 &&
          engagementStage.closing.items.length === 0
        )
      ) {
        return { borderRadius: "0 0 0 0", padding: "0 0 0 0" };
      }
      if (
        Boolean(
          engagementStage.exploration.items.length !== 0 &&
          engagementStage.closing.items.length !== 0
        )
      ) {
        return { borderRadius: "0px", padding: 0 };
      } else {
        return { borderRadius: "0px", padding: "0" };
      }
    }
    case stageNames[2].label: {
      return Boolean(
        engagementStage.exploration.items.length !== 0 ||
        engagementStage.negotiation.items.length !== 0
      )
        ? { borderRadius: "0 8px 8px 0", padding: "0 12px 0 0" }
        : { borderRadius: "0 8px 8px 0", padding: "0 12px 0 0" };
    }
    default:
      return { borderRadius: 0, paddingX: 0 };
  }
};

const createVariantValues = (variant, engagementStage, isEmpty) => {
  switch (variant) {
    case stageNames[0].label: {
      return {
        background: "#C9F7D5",
        borderRight:
          Boolean(
            engagementStage?.exploration_stage !== 0 ||
            engagementStage?.closing_stage !== 0
          ) && "1px solid white",
        borderRadius: variantBorderRadius(variant, engagementStage)
          .borderRadius,
        bottomLine: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ...variantBorderRadius(variant, engagementStage),
            }}
          >
            <Box
              sx={{
                height: "2px",
                background: "#FFFFFF",
                width: "100%",
                borderRadius: "9px 0 0 9px",
              }}
            />
            <Box
              fontSize={(theme) => theme.typography.subtitle2}
              sx={{
                background: "#F7FFF5",
                color: "#196C32",
                borderRadius: "4px",
                paddingX: "4px",
                left: isEmpty ? "6px" : "0",
                bottom: isEmpty ? "8px" : "0",
                zIndex: 5,
                position: isEmpty ? "absolute" : "relative",
              }}
            >
              {variant}
            </Box>
            <Box sx={{ height: "2px", background: "#FFFFFF", width: "100%" }} />
          </Box>
        ),
      };
    }
    case stageNames[1].label: {
      return {
        background: "#FFE7B3",
        borderRight:
          engagementStage?.closing_stage !== 0 && "1px solid white",
        borderRadius: variantBorderRadius(variant, engagementStage)
          .borderRadius,
        bottomLine: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ...variantBorderRadius(variant, engagementStage),
            }}
          >
            <Box
              sx={{
                height: "2px",
                background: "#FFFFFF",
                width: "100%",
              }}
            />
            <Box
              fontSize={(theme) => theme.typography.subtitle2}
              sx={{
                background: "#FFF8F1",
                color: "#765A00",
                borderRadius: "4px",
                paddingX: "4px",
                position: isEmpty ? "absolute" : "relative",
                right: isEmpty ? "-24px" : "0",
                bottom: isEmpty ? "8px" : "0",
                zIndex: 5,
              }}
            >
              {variant}
            </Box>
            <Box sx={{ height: "2px", background: "#FFFFFF", width: "100%" }} />
          </Box>
        ),
      };
    }
    case stageNames[2].label: {
      return {
        background: "#FFDBD2",
        right: "20px",
        borderRadius: variantBorderRadius(variant, engagementStage)
          .borderRadius,
        bottomLine: (
          <Stack
            sx={{ ...variantBorderRadius(variant, engagementStage) }}
            alignItems={"center"}
            position={"relative"}
            paddingRight={"8px"}
            direction={"row"}
          >
            <Box
              sx={{
                height: "2px",
                background: "#FFFFFF",
                width: "100%",
              }}
            />
            <Box
              fontSize={(theme) => theme.typography.subtitle2}
              sx={{
                background: "#FFF8F6",
                color: "#B32A00",
                borderRadius: "4px",
                paddingX: "4px",
                whiteSpace: "nowrap",
                position: isEmpty ? "absolute" : "relative",
                right: isEmpty ? "4px" : "0",
                bottom: isEmpty ? "1px" : "0",
              }}
            >
              {variant}
            </Box>
            <Box sx={{ height: "2px", background: "#FFFFFF", width: "100%" }} />
            {!isEmpty && <StyledIcon
              component={ArrowForwardIosRounded}
              sx={{
                color: "#FFFFFF",
                position: "absolute",
                right: "0",
              }}
            />}
          </Stack>
        ),
      };
    }
    default:
      return {
        background: "white",
        bottomLine: <></>,
      };
  }
};

const EngagementStageBlock = ({
  variant,
  isShowEmpty,
  items,
  engagementStage,
  colWidth,
  minWidth
}) => {
  const [hover, setHover] = useState(false);

  const containerRef = useRef(null);
  const blocksRef = useRef(null);

  const [variantValues, setVariantValues] = useState({
    background: "white",
    bottomLine: <></>,
  });

  const [columnItems, setColumnItems] = useState([]);

  useEffect(() => {
    setVariantValues(
      createVariantValues(
        variant,
        isShowEmpty
          ? {
            exploration: {
              items: [1],
            },
            negotiation: {
              items: [1],
            },
            closing: {
              items: [1],
            },
          }
          : engagementStage,
        items.length === 0 && minWidth === "25px"
      )
    );
  }, [variant, items]);

  useEffect(() => {
    setColumnItems(createColumns(items));
  }, [items]);

  let marginLeft = (variant === 'Closing') ? (minWidth === '25px' ? '-300px' : '-230px') : '-150px';
  if (minWidth === '33%') {
    marginLeft = '0';
  }

  const renderHoverBox = (stage) => (
    <Box
      sx={{
        position: 'absolute',
        top: '45%',
        left: 0,
        right: 0,
        background: 'rgb(245, 245, 245)',
        color: 'rgb(73, 69, 79)',
        padding: '8px',
        textAlign: 'center',
        borderRadius: '12px',
        width: '300px',
        zIndex: '10',
        marginLeft: marginLeft,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 6px 2px',
      }}
    >
      No interactions in {stage} stage
    </Box>
  );

  return (
    <Box
      ref={containerRef}
      sx={{
        width: `${colWidth}%`,
        minWidth: `${minWidth}`,
        background: variantValues?.background || "white",
        borderRadius: variantValues?.borderRadius || "0px",
        borderRight: variantValues?.borderRight || "none",
        gap: "4px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingBottom: "8px",
        minHeight: "350px",
        transition: "all 500ms, border-color 0s",
        position: "relative",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && columnItems.length === 0 && renderHoverBox(variant)}
      <Box
        ref={blocksRef}
        sx={{
          width: "calc(100% - 16px)",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "30px 8px",
          transition: "all 500ms",
        }}
      >
        {!isShowEmpty &&
          columnItems.map((column, index) => (            
            <Box
              key={index}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "28px",
              }}
            >
              {column.map((item) => (
                <EngagementStageCard item={item} key={item.id} />
              ))}
            </Box>
          ))}
      </Box>
      {variantValues?.bottomLine}
    </Box>
  );
};

export default EngagementStageBlock;
