import React, { useEffect, useState } from "react";
import { Drawer } from "@mui/material";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import BundleMessages from "./BundleMessages";
import InteractionDetails from "./ReviewInteractionDetails";
import SupplierDetails from "../supplierDetails/SupplierDetails";
import ContractDetails from "./Contracts/ContractDetails";

const ReviewDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsOpen(true);
        }, 300);
    }, []);

    const handleClose = () => {
        setIsOpen(false);

        setTimeout(() => {
            navigate("/backoffice");
        }, 300);
    };

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                component: "div",
                sx: { overflowX: "hidden", maxWidth: "768px" },
            }}
        >
            <AnimatePresence mode={"sync"}>
                <Routes location={location}>
                    <Route
                        path="messages/:id"
                        element={
                            <motion.div
                                key="messages"
                                initial={{ opacity: 0, x: "100%" }}
                                animate={{ opacity: 1, x: "0%" }}
                                exit={{ opacity: 0, x: "-100%" }}
                            >
                                <BundleMessages
                                    prevLocation={location.state?.from}
                                    hideDetailsNav={location.state?.hideDetailsNav}
                                    close={handleClose} />
                            </motion.div>
                        }
                    />
                    <Route
                        path="supplier/:id"
                        element={
                            <motion.div
                                key="supplier"
                                initial={{ opacity: 0, x: "100%" }}
                                animate={{ opacity: 1, x: "0%" }}
                                exit={{ opacity: 0, x: "-100%" }}
                            >
                                <SupplierDetails close={handleClose} />
                            </motion.div>
                        }
                    />
                    <Route
                        path="interaction/:id"
                        element={
                            <motion.div
                                key="interaction"
                                initial={{ opacity: 0, x: "-100%" }}
                                animate={{ opacity: 1, x: "0%" }}
                                exit={{ opacity: 0, x: "100%" }}
                            >
                                <InteractionDetails close={handleClose} />
                            </motion.div>
                        }
                    />
                                        <Route
                        path="contract/:id"
                        element={
                            <motion.div
                                key="contract"
                                initial={{ opacity: 0, x: "-100%" }}
                                animate={{ opacity: 1, x: "0%" }}
                                exit={{ opacity: 0, x: "100%" }}
                            >
                                <ContractDetails close={handleClose} />
                            </motion.div>
                        }
                    />
                </Routes>
            </AnimatePresence>
        </Drawer>
    );
};

export default ReviewDetails;
