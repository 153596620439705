import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSkeleton from '../../../common/LoadingSkeleton';
import { Box, IconButton, Table, TableBody } from '@mui/material';
import StyledIcon from '../../../common/StyledIcon';
import AdobePDFEmbedClient from '../../../common/AdobePDFEmbedClient';
import { ArrowBackIosRounded, CloseRounded } from '@mui/icons-material';
import BackofficeDetailsNavigation from '../../../common/BackofficeDetailsNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { appSlice } from '../../../store/appSlice';
import ContractManualLabel from './ContractManualLabel';
import ContractTypeEditor from './ContractTypeEditor';
import ContractNameEditor from './ContractNameEditor';

const ContractDetails = ({ close }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { backofficeContracts } = useSelector((state) => state.appSlice);

  const { setBackofficeContracts } = appSlice.actions;

  const [isLoading, setIsLoading] = useState(true);
  const [contract, setContract] = useState();
  const [contractIds, setContractIds] = useState([]);
  const [pdfUrl, setPDFUrl] = useState('');

  useEffect(() => {
    setIsLoading(true);
    const currentContract = backofficeContracts.find(
      (contract) => contract.id === id,
    );
    const ids = backofficeContracts.map((contract) => contract.id);
    setContractIds(ids);

    if (currentContract) handleCurrentContract(currentContract);
    else
      getContract().then((res) => {
        if (res !== 'error') {
          handleCurrentContract(res);
        }
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (!contract || !pdfUrl) return;
    const downloadUrl = `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/contracts/${id}/pdf`;
    const viewerConfig = {
      divId: 'pdf-div',
    };

    const pdfConfig = {
      content: {
        location: {
          url: downloadUrl,
        },
      },
      metaData: {
        fileName: `${contract.raw_name}`,
        hasReadOnlyAccess: true,
      },
    };
    const viewSDKClient = new AdobePDFEmbedClient();
    viewSDKClient.ready().then(() => {
      /* Invoke file preview */
      viewSDKClient.previewFile('pdf-div', viewerConfig, pdfConfig);
    });
  }, [pdfUrl]);

  const handleCurrentContract = (currentContract) => {
    setContract(currentContract);
    setPDFUrl(currentContract?.download_link || '');
    setIsLoading(false);
  };

  const onSave = (updatedContract) => {
    const updatedContracts = backofficeContracts.map((contract) => {
      if (updatedContract.id === contract.id) return updatedContract;
      return contract;
    });
    dispatch(setBackofficeContracts(updatedContracts));
  };

  const getContract = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/contracts/${id}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  if (isLoading || !contract) {
    return (
      <Table>
        <TableBody>
          <LoadingSkeleton variant={'adminLogs'} />
        </TableBody>
      </Table>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '720px',
          height: 'calc(100vh - 32px)',
          overflowY: 'auto',
          padding: '16px 24px',
          background: 'white',
          '@media (max-width: 768px)': {
            width: 'calc(100vw - 48px)',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1.5px solid #694FA31A',
            paddingBottom: '20px',
            marginBottom: '24px',
          }}
        >
          <Box
            display={'flex'}
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.subtitle1}
          >
            <IconButton
              type="button"
              sx={{ padding: '0', marginRight: '8px' }}
              onClick={close}
            >
              <StyledIcon component={ArrowBackIosRounded} color={'icon'} />
            </IconButton>
            <Box>
              <ContractNameEditor contract={contract} onSave={onSave} />
              <Box
                color={(theme) => theme.palette.text.medium}
                fontSize={(theme) => theme.typography.caption}
              >
                Raw name: {contract.raw_name}
              </Box>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            {Boolean(contractIds.length > 1) && (
              <BackofficeDetailsNavigation
                itemId={id}
                idArray={contractIds}
                navigationLink={'/backoffice/details/contract/'}
              />
            )}
            <Box>
              <IconButton type="button" sx={{ padding: '8px' }} onClick={close}>
                <CloseRounded sx={{ color: '#2F236D73', fontSize: 24 }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box>
            <ContractTypeEditor contract={contract} onSave={onSave} />
            <Box
              color={(theme) => theme.palette.text.medium}
              fontSize={(theme) => theme.typography.caption}
            >
              Raw types:{' '}
              {contract.contract_type_tags.join(', ') ||
                contract.raw_contract_type}
            </Box>
          </Box>
          <Box>
            <ContractManualLabel contract={contract} onSave={onSave} />
          </Box>
        </Box>
        <Box id="pdf-div"></Box>
      </Box>
    </Box>
  );
};

export default ContractDetails;
