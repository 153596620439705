import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BackofficeDetailsNavigation = ({ itemId, idArray, navigationLink }) => {
  const [previousId, setPreviousId] = useState();
  const [nextId, setNextId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setPreviousId();
    setNextId();
    const currentIndex = idArray?.indexOf(itemId);

    if (currentIndex > 0) {
      setPreviousId(idArray[currentIndex - 1]);
    }

    if (currentIndex < idArray?.length - 1) {
      setNextId(idArray[currentIndex + 1]);
    }
  }, [itemId, idArray]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowLeft':
          if (previousId !== undefined) {
            navigate(`${navigationLink}${previousId}`);
          }
          break;
        case 'ArrowRight':
          if (nextId !== undefined) {
            navigate(`${navigationLink}${nextId}`);
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [previousId, nextId, navigate, navigationLink]);

  if (!idArray || !idArray.length) {
    return <></>;
  }

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <IconButton
        disabled={!previousId}
        disableRipple={!previousId}
        type="button"
        sx={{ padding: '0px' }}
        title="Previous item, keyboard: ←"
        onClick={() => navigate(`${navigationLink}${previousId}`)}
      >
        <ExpandLessOutlined
          color={previousId ? 'icon' : 'icon_disabled'}
          sx={{ fontSize: 24 }}
        />
      </IconButton>
      <IconButton
        disabled={!nextId}
        disableRipple={!nextId}
        type="button"
        title="Next item, keyboard: →"
        sx={{ padding: '0px' }}
        onClick={() => navigate(`${navigationLink}${nextId}`)}
      >
        <ExpandMoreOutlined
          color={nextId ? 'icon' : 'icon_disabled'}
          sx={{ fontSize: 24 }}
        />
      </IconButton>
    </Box>
  );
};

export default BackofficeDetailsNavigation;
