import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import CustomCard from "../../common/CustomCard";
import { ReactComponent as Download } from "../../common/icons/downloadIcon.svg";
import { useTheme } from "@mui/material/styles";
import LoadingSkeleton from "../../common/LoadingSkeleton";
import { getUsers, getActivityLogData } from "../../common/DataHelper";
import { getComparator, getCellDesc } from "../../common/SortingHelper";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import { convertToCSV } from "../../utils/functions";
import styled from '@emotion/styled';

const StyledDownloadButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.secondary.main,
  border: "1px solid #DEDBEC",
  width: "100%",
  fontSize: "15px",
  fontWeight: "500",
  borderRadius: "10px",
  padding: "6px 16px",
}));

const AdminLog = () => {
  const theme = useTheme();

  const [selectedDateRange, setSelectedDateRange] = useState(7);
  const [selectedUser, setSelectedUser] = useState('all');

  const [tableOrder, setTableOrder] = React.useState('desc');
  const [tableOrderBy, setTableOrderBy] = React.useState('created_at');

  const [users, setUsers] = useState([]);
  const [adminLog, setAdminLog] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = tableOrderBy === property && tableOrder === 'asc';

    setTableOrder(isAsc ? 'desc' : 'asc');
    setTableOrderBy(property);
  };

  const headCells = [
    {
      id: 'user',
      numeric: false,
      label: 'User',
      sort: true,
    },
    {
      id: 'action',
      numeric: false,
      label: 'Activity',
      sort: true,
    },
    {
      id: 'created_at',
      numeric: true,
      label: 'Date',
      sort: true,
    },
  ];

  const downloadCSV = () => {
    const csvData = convertToCSV(visibleRows, headCells);

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "admin_log.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    document.title = "PartnerElement | Settings > Activity log";

    const fetchUsers = async () => {
      try {
        const users = await getUsers();

        setUsers(users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    getActivityLogData(selectedUser, selectedDateRange).then((res) => {
      setAdminLog(res.map(item => ({
        ...item,
        created_at: moment(item.created_at),
      })));
      setIsLoading(false);
    });
  }, [selectedUser, selectedDateRange]);

  const visibleRows = React.useMemo(
    () => {
      const cellDesc = getCellDesc(headCells, tableOrderBy);

      return adminLog?.slice().sort(getComparator(tableOrder, tableOrderBy, cellDesc?.numeric || false));
    },
    [adminLog, tableOrder, tableOrderBy],
  );

  return (
    <Box sx={{ width: "100%", height: "fit-content" }}>
      <CustomCard>
        <Box>
          <Stack
            flexDirection={"column"}
            justifyContent={"space-between"}
            alignItems={"left"}
            sx={{ borderBottom: "1px solid #694FA31A", padding: "16px" }}
          >
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.h6}
            >
              Activity log
            </Box>

            <Stack flexDirection={"row"} justifyContent={"space-between"} marginTop={"16px"} marginBottom={"8px"}>
              <Stack flexDirection={"row"} gap={"10px"}>
                <Box width={"200px"}>
                  <FormControl key={users.length} sx={{ width: "100%" }}>
                    <InputLabel htmlFor="user-select" color={"secondary"}>
                      Users:
                    </InputLabel>
                    <Select
                      sx={{ borderRadius: "10px", height: "40px" }}
                      id={"user-select"}
                      color={"secondary"}
                      label={"Users:"}
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e.target.value)}
                    >
                      <MenuItem value="all">All users</MenuItem>
                      <MenuItem value="System">System</MenuItem>
                      {users.map((user) => (
                        <MenuItem key={user.id} value={user.email}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box width={"220px"}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel htmlFor="timeframe" color={"secondary"}>
                      Include messages from:
                    </InputLabel>
                    <Select
                      sx={{ borderRadius: "10px", height: "40px" }}
                      id={"timeframe"}
                      color={"secondary"}
                      label={"Include messages from:"}
                      value={selectedDateRange}
                      onChange={(e) => setSelectedDateRange(e.target.value)}
                    >
                      <MenuItem value={7}>Last 7 days</MenuItem>
                      <MenuItem value={30}>Last 30 days</MenuItem>
                      <MenuItem value={60}>Last 60 days</MenuItem>
                      <MenuItem value={90}>Last 90 days</MenuItem>
                      <MenuItem value={180}>Last 180 days</MenuItem>
                      <MenuItem value={365}>Last 365 days</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
              <Stack>
                <StyledDownloadButton onClick={downloadCSV} color="secondary">
                  <Download />
                  Export as CSV
                </StyledDownloadButton>
              </Stack>
            </Stack>
          </Stack>

          <Box>
            <Table>
              <EnhancedTableHead headCells={headCells} onRequestSort={handleRequestSort} order={tableOrder}
                orderBy={tableOrderBy} tableCellSx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
                tableRowSx={{
                  th: {
                    borderBottom:
                      visibleRows?.length === 0
                        ? "none"
                        : "1.5px solid #694FA31A",
                    padding: "16px",
                    fontWeight: "subtitle2",
                    fontSize: "subtitle2",
                    color: "#000000DE",
                  },
                }} />
              <TableBody>
                {isLoading
                  ? [0, 1, 2, 3].map((_, index) => (
                    <LoadingSkeleton variant={"adminLogs"} key={index} />
                  ))
                  : visibleRows?.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        borderBottom: "1.5px solid #694FA31A",
                        td: {
                          border: "none",
                          padding: "16px",
                          color: "#000000DE",
                          fontWeight: "body2",
                          fontSize: "body2",
                          whiteSpace: "nowrap",
                        },
                        ":last-of-type": { borderBottom: "none" },
                      }}
                    >
                      <TableCell>{item?.user_name}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            whiteSpace: "normal",
                          }}
                        >
                          {item?.action}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ paddingRight: "16px" }}>
                        {moment(item.created_at)
                          .utc()
                          .format("MM/DD/YY HH:mm:ss")}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </CustomCard >
    </Box >
  );
};

export default AdminLog;
