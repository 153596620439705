import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import StyledIcon from "../../common/StyledIcon";
import { CloseRounded } from "@mui/icons-material";
import WarningChangeRole from "./WarningChangeRole";

const RolePopup = ({ handleClose, user, open, getData }) => {
  const [role, setRole] = useState(user.role);
  const [isShowPopup, setIsShowPopup] = useState(false);

  const editRole = async (data) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/users/${user.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
    if (response.ok) {
      return await response.json();
    } else {
      return "error";
    }
  };

  const handleClickButton = () => {
    handleClose();
    editRole({ role }).then(() => getData());
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          width: "676px",
          top: "24px",
          position: "fixed",
          margin: "0",
        },
      }}
    >
      <Box sx={{ padding: "24px" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"24px"}
        >
          <Box
            fontSize={(theme) => theme.typography.h6}
            color={(theme) => theme.palette.text.main}
          >
            {`Change team role for ${user.name}`}
          </Box>
          <IconButton onClick={handleClose} sx={{ padding: 0 }}>
            <StyledIcon component={CloseRounded} color={"icon"} />
          </IconButton>
        </Stack>
        <Box sx={{ marginBottom: "24px" }}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel htmlFor="role" color={"secondary"}>
              Role
            </InputLabel>
            <Select
              value={role}
              onChange={(event) =>
                setRole(event.target.value)
              }
              id={"role"}
              label={"Role"}
              {...{ sx: { borderRadius: "10px" }, color: "secondary" }}
            >
              <MenuItem value={'user'}>user</MenuItem>
              <MenuItem value={'admin'}>admin</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Stack direction={"row"} justifyContent={"end"}>
          <Button
            onClick={() => setIsShowPopup(true)}
            variant={"contained"}
            color={"secondary"}
            sx={{ textTransform: "none", borderRadius: "10px" }}
          >
            {"Save"}
          </Button>
        </Stack>
        <WarningChangeRole
          handleClose={() => {
            setIsShowPopup(false);
          }}
          handleConfirm={() => {
            setIsShowPopup(false);
            handleClickButton(true);
          }}
          open={isShowPopup}
          name={user.name}
        />
      </Box>
    </Dialog>
  );
};

export default RolePopup;
