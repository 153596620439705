import React, { useEffect } from "react";
import { Box, Table, TableCell, TableHead, TableRow, TableBody } from "@mui/material";
import StyledIcon from "../../common/StyledIcon";
import { Visibility } from "@mui/icons-material";
import moment from "moment";

const StatsTable = ({ configs, stats, onOpenDialog}) => {
    const tableHeaders = [
        { label: 'Engagement', width: '20%', minWidth: '200px'},
        { label: 'Status', width: '10%' },
        { label: 'Activated', width: '15%' },
        { label: 'Ended', width: '15%' },
        { label: 'Sent', width: '10%' },
        { label: 'Opened', width: '10%' },
        { label: 'Clicked', width: '10%' },
        { label: '', width: '10%' }
    ];

    const createStatValue = (previousStat) => {
      const stat = Number(previousStat);
      if (stat < 2) return 0;
      return Math.floor(Math.random() * stat);
    };

    const tableData =
      configs?.map((config) => {
        const sentStat = stats?.[config.id]?.sent || 0;
        const openedStat = createStatValue(sentStat);
        const clickedStat = createStatValue(openedStat);
        return {
          engagement: config.label,
          status: config.active ? 'Active' : 'Inactive',
          activated: config.start_at
            ? moment(config.start_at).format('lll')
            : '',
          ended: config.end_at ? moment(config.end_at).format('lll') : '',
          sent: sentStat,
          opened: openedStat,
          clicked: clickedStat,
          id: config.id,
        };
      }) || [];

    const handleOpenDialog = (id) => {
        const option = configs.find((option) => option.id === id);
        onOpenDialog(option);
    }

    return (
        <Table sx={{borderCollapse: 'separate !important', border: '1px solid #DEDBEC', borderRadius: '12px'}}>
            <TableHead>
                <TableRow>
                    {tableHeaders.map(({ label, width, minWidth }) => (
                        <TableCell key={label} sx={{ width, minWidth }}>
                            <Box
                                color={(theme) => theme.palette.text.main}
                                fontSize={(theme) => theme.typography.subtitle2}
                            >
                                {label}
                            </Box>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {tableData.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                        {tableHeaders.map(({ label }, colIndex) => {
                            const cellKey = `${rowIndex}-${label}`;
                            const key = label.toLowerCase().replace(/\s+/g, '');

                            if (colIndex === tableHeaders.length - 1) {
                                return null; // If it's the last column and should be empty
                            }

                            if (label === 'Status') {
                                return (
                                    <TableCell key={cellKey}>
                                        <Box color={row[key] === 'Active' ? 'green' : 'gray'}>
                                            {row[key]}
                                        </Box>
                                    </TableCell>
                                );
                            }

                            return (
                                <TableCell key={cellKey}>
                                    {row[key]}
                                </TableCell>
                            );
                        })}
                        <TableCell key={`${rowIndex}-cl`}>
                            <StyledIcon component={Visibility} color={"icon"} sx={{cursor: 'pointer'}} onClick={() => handleOpenDialog(row['id'])}/>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default StatsTable;
