import { DEFAULT_REPORT_FILTERS } from "../components/reports/reportHelpers";
import {
  defaultFiltersBundles,
  defaultFiltersInteractions,
  defaultFiltersOverview,
} from "../utils/constants";

export const setCurrentUserOrg = (org) => {
  sessionStorage.setItem("currentUserOrg", JSON.stringify(org))
}

export const getCurrentUserOrg = () => {
  const value = sessionStorage.getItem("currentUserOrg")
  return JSON.parse(value);
}

function updateFilter(filterName, filters) {
  const orgName = getCurrentUserOrg();
  if (orgName)
    localStorage.setItem(`${orgName}-${filterName}`, JSON.stringify(filters));
}

function getFilter(filterName) {
  const orgName = getCurrentUserOrg();
  return localStorage.getItem(`${orgName}-${filterName}`);
}

export const updateFiltersOverview = (filters) => {
  updateFilter("filtersOverview", filters);
};
export const updateFiltersInteractions = (filters) => {
  updateFilter("filtersInteractions", filters);
};
export const updateFiltersBundles = (filters) => {
  updateFilter("filtersBundles", filters);
};
export const updateFiltersReports = (filters) => {
  updateFilter("filtersReports", filters);
};

export function getFiltersOverview() {
  const value = getFilter("filtersOverview");
  if (value === null) {
    return defaultFiltersOverview;
  }
  return JSON.parse(value);
}

export function getFiltersInteractions() {
  const value = getFilter("filtersInteractions");
  if (value === null) {
    return defaultFiltersInteractions;
  }
  return JSON.parse(value);
}

export function getFiltersBundles() {
  const value = getFilter("filtersBundles");
  if (value === null) {
    return defaultFiltersBundles;
  }
  return JSON.parse(value);
}

export function getFiltersReports() {
  const value = getFilter("filtersReports");
  if (value === null) {
    return DEFAULT_REPORT_FILTERS;
  }
  const filters = JSON.parse(value)
  return {...filters, status: filters.status || []};
}

export function warningChangeSettings() {
  const value = sessionStorage.getItem("warningChangeSettings");
  if (value === null) {
    return false;
  }
  return JSON.parse(value);
}
export const updateWarningChangeSettings = (value) => {
  sessionStorage.setItem("warningChangeSettings", JSON.stringify(value));
};
