import React from 'react';
import { Box, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import Filters from './Filters';
import Interactions from './Interactions';
import Overview from './overview/Overview';
import Suppliers from './suppliers/Suppliers';
import SupplierFilters from './suppliers/SupplierFilters';

const Home = () => {
  const { page } = useSelector((state) => state.appSlice);

  const viewPage = (props) => {
    switch (page) {
      case 'interactions':
        return <Interactions {...props} />;
      case 'overview':
        return <Overview {...props} />;
      case 'suppliers':
        return <Suppliers {...props} />;
      default:
        return <></>;
    }
  };

  return (
    <Box
      sx={{
        minHeight: 'calc(100vh - 68px)',
      }}
    >
      <Stack width={'100%'} direction={'row'}>
        <Box sx={{ maxWidth: '312px', minWidth: '312px', width: '100%' }}>
          {page === 'suppliers' ? <SupplierFilters /> : <Filters />}
        </Box>
        <Box
          sx={{
            width: '100%',
            background: '#FDFDFD',
            padding: '24px 13px 16px 24px',
          }}
        >
          {viewPage()}
        </Box>
      </Stack>
    </Box>
  );
};

export default Home;
