import { Box, IconButton, Modal, Stack } from '@mui/material';
import React from 'react';
import StyledIcon from '../../common/StyledIcon';
import { CloseRounded } from '@mui/icons-material';
import SpendPieChart from './SpendPieChart';

const SpendByTeamModal = ({ open, handleClose, collection }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 'fit-content',
          minWidth: '830px',
          maxWidth: '1080px',
          maxHeight: '640px',
          background: 'white',
          position: 'absolute',
          marginTop: '35px',
          top: '0',
          left: 'calc(50% - 415px)',
          borderRadius: '12px',
          padding: '24px',
          overflow: 'auto',
        }}
      >
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginBottom={'4px'}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
          >
            Spend by team
          </Box>
          <IconButton padding={'8px'} onClick={handleClose}>
            <StyledIcon component={CloseRounded} color={'icon'} />
          </IconButton>
        </Stack>
        <Stack
          paddingBottom={'16px'}
          width={'100%'}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'end'}
        >
          <Box color={(theme) => theme.palette.text.medium}>{collection.length} teams</Box>
        </Stack>
        <Box display={'flex'} width={'100%'}>
        <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
          {collection.map((spend, index) => (
            <Box
              key={`by-team-${spend.label}-${index}`}
              display={'flex'}
              flexDirection={'row'}
              gap={'8px'}
              borderBottom={'1.5px solid #694FA31A'}
              padding={'16px 0'}
            >
              <Box>
                <svg width="10" height="10">
                  <circle cx="5" cy="5" r="5" fill={spend.color} />
                </svg>
              </Box>
              <Box
                width={'204px'}
                color={(theme) => theme.palette.text.black}
                fontSize={(theme) => theme.typography.body2}
              >
                {spend.label}
              </Box>
              <Box
                width={'222px'}
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.body2}
              >
                {spend.totalFormatted}
              </Box>
            </Box>
          ))}
        </Box>
        <Box width={'50%'}>
          <SpendPieChart data={collection} inModal />
        </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SpendByTeamModal;
