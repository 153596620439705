import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, TextField, InputLabel } from '@mui/material';
import { getOrganizations } from '../../common/DataHelper';

const OrganizationDropdown = ({ value, onChange }) => {
  const [organizations, setOrganizations] = useState([]);
  const [metaNextPage, setMetaNextPage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [localValue, setLocalValue] = useState();

  const fetchOrganizations = async () => {
    const page = metaNextPage;
    try {
      const orgList = await getOrganizations(page);
      setOrganizations([...organizations, ...orgList.organizations]);

      // TODO: lazy load orgs in dropdown
      const meta = orgList.meta;
      if (Number(meta.current_page) < Number(meta.total_pages) - 1)
        setMetaNextPage(Number(meta.next_page));
      else setIsLoading(false);
    } catch (error) {
      console.error('Error fetching Organizations:', error);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, [metaNextPage]);

  useEffect(() => {
    if (!isLoading) {
      const filter = organizations?.find(
        (organization) => organization.id === value,
      );
      
      setLocalValue(filter);
      onChange(filter);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!value && !isLoading) {
      setLocalValue(null);
    }
  }, [value]);

  if (!organizations.length || isLoading) {
    return <></>;
  }

  return (
    <>
      <Autocomplete
        id="autocomplete-organization"
        options={organizations}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {option.name} ({option.id})
          </Box>
        )}
        value={localValue || null}
        onChange={(_, value) => {
          setLocalValue(value);
          onChange(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            label="Organization"
            sx={{
              '& .MuiOutlinedInput-root': {
                padding: '2px 8px 2px 8px',
                borderRadius: '8px',
              },
              '& .MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
                top: '-5px',
              }
            }}
          />
        )}
      />
    </>
  );
};

export default OrganizationDropdown;
