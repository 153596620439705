import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import StyledIcon from './StyledIcon';
import { HelpOutlineRounded } from '@mui/icons-material';

const tooltipStyle = {
  tooltip: {
    sx: {
      borderRadius: '12px',
      display: 'flex',
      alignItems: 'center',
      padding: '16px',
      background: '#FFF',
      boxShadow: '0px 3px 5px -1px #00000033',
      boxShadow: '0px 6px 10px 0px #00000024',
      boxShadow: '0px 1px 18px 0px #0000001F',
    },
  },
};

const InfoMessageHover = ({ message }) => {
  return (
    <Tooltip
      title={
        <Box
          color={(theme) => theme.palette.text.main}
          fontSize={(theme) => theme.typography.body2}
        >
          {message}
        </Box>
      }
      componentsProps={tooltipStyle}
    >
      <IconButton
        size="small"
        sx={{ padding: 0, height: '20px', cursor: 'default' }}
      >
        <StyledIcon
          sx={{ fontSize: '20px' }}
          component={HelpOutlineRounded}
          color="icon"
        />
      </IconButton>
    </Tooltip>
  );
};

export default InfoMessageHover;
