import React from 'react';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import DateTooltip from './DateTooltip';
import CustomActiveDot from './CustomActiveDot';

const SimpleLineGraph = ({ data, getColor, dates, valueKey }) => {
  return (
    <ResponsiveContainer width={'100%'} height={50}>
      <LineChart height={50}>
        <XAxis hide={true} />
        <YAxis hide={true} />
        <Line
          type="linear"
          dataKey="value"
          data={data}
          stroke={getColor()}
          dot={({ cx, cy, index }) =>
            index === data?.length - 1 ? (
              <circle
                cx={cx}
                cy={cy}
                r={3}
                key={index}
                fill={getColor()}
              />
            ) : null
          }
          activeDot={
            <CustomActiveDot
              r={3}
              xAxis={{ x: 0 }}
              yAxis={{ y: 0 }}
              colorDot={getColor()}
              height={50}
            />
          }
          isAnimationActive={false}
        />

        <Tooltip
          cursor={false}
          wrapperStyle={{ outline: 'none' }}
          allowEscapeViewBox={{ x: true, y: true }}
          content={<DateTooltip dates={dates} valueKey={valueKey || 'index'} />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SimpleLineGraph;
