import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import StyledIcon from "../../common/StyledIcon";
import { CloseRounded } from "@mui/icons-material";
import WarningDeleteTemplate from "./WarningDeleteTemplate";

const TemplatePopup = ({ variant, handleClose, template, open, getData }) => {
  const [title, setTitle] = useState(variant === 'edit' ? template.title : '');
  const [text, setText] = useState(variant === 'edit' ? template.text : '');
  const [isDefaultTemplate, setIsDefaultTemplate] = useState(
    variant === 'edit' ? template.default_template : false,
  );
  const [isShowPopup, setIsShowPopup] = useState(false);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleToggleClick = (event) => {
    event.stopPropagation();
    setIsDefaultTemplate(!isDefaultTemplate);
};

  const characterCount = text.length;
  const maxLength = 500;

  const addTemplate = async (data) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/templates`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
    if (response.ok) {
      return await response.json();
    } else {
      return "error";
    }
  };

  const editTemplate = async (data) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/templates/${template.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
    if (response.ok) {
      return await response.json();
    } else {
      return "error";
    }
  };

  const deleteTemplate = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/templates/${template.id}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    return response.ok;
  };

  const handleDeleteButton = () => {
    setIsShowPopup(true);
  };

  const handleConfirmDelete = async () => {
    const success = await deleteTemplate();

    if (success) {
      handleClose();
      setIsShowPopup(false);
      getData();
    }
  };

  const handleClickButton = () => {
    handleClose();

    switch (variant) {
      case "edit": {
        editTemplate({ ...template, text, title, default_template: isDefaultTemplate }).then(() => getData());
        break;
      }
      case "add": {
        addTemplate({ text, title, default_template: isDefaultTemplate }).then(() => getData());
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            width: '676px',
            top: '24px',
            position: 'fixed',
            margin: '0',
          },
        }}
      >
        <Box sx={{ padding: '24px' }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            marginBottom={'24px'}
          >
            <Box
              fontSize={(theme) => theme.typography.h6}
              color={(theme) => theme.palette.text.main}
            >
              {variant === 'edit' ? 'Edit template' : 'Add a new template'}
            </Box>
            <IconButton onClick={handleClose} sx={{ padding: 0 }}>
              <StyledIcon component={CloseRounded} color={'icon'} />
            </IconButton>
          </Stack>
          <Box sx={{ marginBottom: '24px' }}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="title" color={'secondary'} shrink>
                Title
              </InputLabel>
              <OutlinedInput
                notched
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                id={'title'}
                label={'Title'}
                sx={{ borderRadius: '10px' }}
                color={'secondary'}
                placeholder="Use a title others will read, i.e. Procurement pricing negotiations"
              />
            </FormControl>
          </Box>
          <Box marginBottom={'24px'}>
            <FormControl sx={{ width: '100%', marginBottom: '4px' }}>
              <TextField
                multiline
                minRows={3}
                maxRows={5}
                value={text}
                onChange={handleChange}
                label={'Message'}
                color={'secondary'}
                placeholder="Use this space to introduce yourself and the benefits of involving procurement"
                sx={{ fieldset: { borderRadius: '10px' } }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: maxLength,
                }}
                fullWidth
              />
            </FormControl>
            <Stack
              direction={'row'}
              justifyContent={'end'}
              fontSize={(theme) => theme.typography.caption}
              color={(theme) =>
                characterCount === maxLength
                  ? theme.palette.error.light
                  : theme.palette.text.medium
              }
            >
              {`${characterCount}/${maxLength}`}
            </Stack>
            <Stack
              direction={'row'}
              alignItems={'center'}
              marginBottom={'3px'}
            >
              <Switch
                checked={isDefaultTemplate || false}
                color={'secondary'}
                onClick={handleToggleClick}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.body1}
              >
                Default template
              </Box>
            </Stack>
            <Box
              color={(theme) => theme.palette.text.medium}
              fontSize={(theme) => theme.typography.caption}
            >
              Make the template default for engagement with employees.
            </Box>
          </Box>
          <Stack direction={'row'} justifyContent={'end'} gap={'10px'}>
            <Button
              onClick={handleDeleteButton}
              variant={'outlined'}
              color={'secondary'}
              sx={{ textTransform: 'none', borderRadius: '10px' }}
            >
              Delete
            </Button>

            <Button
              onClick={handleClickButton}
              variant={'contained'}
              color={'secondary'}
              sx={{ textTransform: 'none', borderRadius: '10px' }}
            >
              {variant === 'edit' ? 'Save' : 'Add'}
            </Button>
          </Stack>
        </Box>
      </Dialog>

      {isShowPopup && (
        <WarningDeleteTemplate
          handleClose={() => setIsShowPopup(false)}
          handleConfirm={handleConfirmDelete}
          open={isShowPopup}
        />
      )}
    </>
  );
};

export default TemplatePopup;
