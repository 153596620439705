import { is } from "@react-spring/shared";

export function descendingNumberComparator(a, b, orderBy) {
    return b?.[orderBy] - a?.[orderBy];
}

export function descendingTextComparator(a, b, orderBy) {
    return b?.[orderBy]?.localeCompare(a?.[orderBy]);
}

export function getComparator(order, orderBy, isNumeric) {
    if (isNumeric) {
        return order === 'desc'
            ? (a, b) => descendingNumberComparator(a, b, orderBy)
            : (a, b) => -descendingNumberComparator(a, b, orderBy);
    }

    return order === 'desc'
        ? (a, b) => descendingTextComparator(a, b, orderBy)
        : (a, b) => -descendingTextComparator(a, b, orderBy);
}

export function getCellDesc(cells, cellId) {
    return cells.filter((cell) => cell.id === cellId)?.[0];
}