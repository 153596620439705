import React, { useEffect, useState } from "react";
import { DateCalendar } from "@mui/x-date-pickers";
import { useTheme } from "@mui/material/styles";
import moment from "moment";

const CustomDateCalendar = ({
  value,
  setValue,
  minDate,
  maxDate,
  error,
  setError,
  minDateErrorMessage,
  maxDateErrorMessage,
}) => {
  const theme = useTheme();
  const [date, setDate] = useState(moment(value));

  useEffect(() => {
    if (
      minDate &&
      moment(minDate).startOf("day").unix() > moment(date).unix()
    ) {
      setError(minDateErrorMessage);
    } else if (
      maxDate &&
      moment(date).startOf("day").unix() > moment(maxDate).startOf("day").unix()
    ) {
      setError(maxDateErrorMessage);
    } else {
      setError("");
      setValue(date);
    }
  }, [
    date,
    maxDate,
    minDate,
    setValue,
    setError,
    minDateErrorMessage,
    maxDateErrorMessage,
  ]);

  return (
    <DateCalendar
      sx={{
        margin: "0",
        ".MuiDateCalendar-viewTransitionContainer": {
          ".MuiPickersDay-hiddenDaySpacingFiller": {
            opacity: "0 !important",
            background: "transparent !important",
            color: "transparent !important",
          },
          ".Mui-selected": {
            opacity: "1",
            background: error
              ? `${theme.palette.error.text}`
              : `${theme.palette.secondary.main} !important`,
            color: "white !important",
          },
          ".Mui-disabled": {
            opacity: "1 !important",
            color: `${theme.palette.error.text} `,
          },
        },
      }}
      value={date}
      onChange={(newValue) => setDate(moment(new Date(newValue)))}
      minDate={error ? minDate : null}
      maxDate={error ? maxDate : null}
    />
  );
};

export default CustomDateCalendar;
