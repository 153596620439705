import React, { useState, useEffect } from "react";
import { Box, Stack } from "@mui/material";
import EngagementGrid from "./EngagementGrid";
import { getEmailNotificationConfigs, getEmailNotificationStats, setEmailNotificationStatus } from "../../common/DataHelper";
import LoadingSkeleton from "../../common/LoadingSkeleton";
import StatsTable from "./StatsTable";

const AutomaticOutreach = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [configs, setConfigs] = useState([]);
    const [stats, setStats] = useState({});
    const [currentOption, setCurrentOption] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const configsData = await getEmailNotificationConfigs();
                const statsData = await getEmailNotificationStats();
                setConfigs(configsData);
                setStats(statsData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };
        setIsLoading(true);
        fetchData();
    }, []);

    const handleToggleOption = (index) => {
        let id = -1;
        let status = null;

        const updatedConfigs = configs.map((item, idx) => {
            if (idx === index) {
                const updatedItem = {...item, active: !item.active};
                id = updatedItem.id;
                status = updatedItem.active;

                if (currentOption && currentOption.title === item.title) {
                    setCurrentOption(updatedItem);
                }
                return updatedItem;
            }
            return item;
        });

        if (id !== -1 && status !== null) {
            setEmailNotificationStatus(id, status).then((res) => {
                setConfigs(updatedConfigs.map((item) => {
                    if (item.id === id) {
                        return {...res};
                    }

                    return item;
                }));
            });
        }
    };

    const handleOpenDialog = (option) => {
        setCurrentOption(option);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    if (isLoading) {
        return <LoadingSkeleton variant={'stats'} />;
    }

    return (
        <Stack
            flexDirection={"column"}
            gap={"16px"}
            width={"100%"}
            sx={{ background: "#FDFDFD", minHeight: "calc(100vh - 68px)" }}
        >
            <Box color={(theme) => theme.palette.text.main} fontSize={(theme) => theme.typography.h6}>
                Automatic outreach
                <Box sx={{paddingBottom: '16px'}} color={(theme) => theme.palette.text.secondary} fontSize={(theme) => theme.typography.body1}>Use email to send the right advice at the right time, then monitor results.</Box>
                <EngagementGrid configs={configs} onToggleOption={handleToggleOption} onOpenDialog={handleOpenDialog} onCloseDialog={handleCloseDialog} currentOption={currentOption} open={dialogOpen} />
            </Box>
            <Box color={(theme) => theme.palette.text.main} fontSize={(theme) => theme.typography.h6}>
                Analytics
            </Box>
            <StatsTable configs={configs} stats={stats} onOpenDialog={handleOpenDialog} />
        </Stack>
    );
};

export default AutomaticOutreach;
