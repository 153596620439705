/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

class AdobePDFEmbedClient {
  constructor() {
      this.readyPromise = new Promise((resolve) => {
          if (window.AdobeDC) {
              resolve();
          } else {
              /* Wait for Adobe Acrobat Services PDF Embed API to be ready */
              document.addEventListener("adobe_dc_view_sdk.ready", () => {
                  resolve();
              });
          }
      });
      this.adobeDCView = undefined;
  }

  ready() {
      return this.readyPromise;
  }

  previewFile(divId, viewerConfig, pdfConfig) {
      const config = {
          /* Pass your registered client id */
          clientId: process.env.REACT_APP_ADOBE_PDF_EMBED_KEY,
          downloadWithCredentials: true,
      };
      if (divId) { /* Optional only for Light Box embed mode */
          /* Pass the div id in which PDF should be rendered */
          config.divId = divId;
      }
      /* Initialize the AdobeDC View object */
      this.adobeDCView = new window.AdobeDC.View(config);

      /* Invoke the file preview API on Adobe DC View object */
      const previewFilePromise = this.adobeDCView.previewFile(pdfConfig, viewerConfig);

      return previewFilePromise;
  }
}

export default AdobePDFEmbedClient;