import React, { useMemo, useState } from "react";
import {
  Box,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import StyledIcon from "../../common/StyledIcon";
import { CloseRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import moment from "moment";
import { getComparator, getCellDesc } from "../../common/SortingHelper";
import TeamNameWithIcon from "../../common/TeamNameWithIcon";
import InteractionStageGraph from "./InteractionStageGraph";

const headCells = [
  {
    id: 'name',
    numeric: false,
    label: 'Name',
    sort: true,
  },
  {
    id: 'title',
    numeric: false,
    label: 'Title',
    sort: true,
  },
  {
    id: 'interactions',
    numeric: true,
    label: 'Interactions',
    sort: true,
  },
  {
    id: 'last_active',
    numeric: true,
    label: 'Last active',
    sort: true,
  },
];


const CollapseItem = ({ item }) => {
  const [tableOrder, setTableOrder] = React.useState('asc');
  const [tableOrderBy, setTableOrderBy] = React.useState('name');

  const handleRequestSort = (_, property) => {
    const isAsc = tableOrderBy === property && tableOrder === 'asc';

    setTableOrder(isAsc ? 'desc' : 'asc');
    setTableOrderBy(property);
  };

  const visibleRows = useMemo(
    () => {
      const cellDesc = getCellDesc(headCells, tableOrderBy);

      return item?.stakeholders?.slice().sort(getComparator(tableOrder, tableOrderBy, cellDesc?.numeric || false));
    },
    [item.stakeholders, tableOrder, tableOrderBy],
  );

  return (
    <Box
      sx={{
        overflowX: "auto",
        border: "1px solid #DEDBEC",
        borderRadius: "12px",
        minWidth: "100%",
      }}
    >

      <Table>
        <EnhancedTableHead headCells={headCells} onRequestSort={handleRequestSort} order={tableOrder}
          orderBy={tableOrderBy} tableCellSx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
          tableRowSx={{
            th: {
              borderBottom:
                item?.stakeholders?.length === 0
                  ? "none"
                  : "1.5px solid #694FA31A",
              padding: "16px",
              paddingRight: "0",
              fontWeight: "subtitle2",
              fontSize: "subtitle2",
              color: "#000000DE",
            },
          }} />

        <TableBody>
          {visibleRows.map((contact, index) => (
            <TableRow
              key={index}
              sx={{
                borderBottom: "1.5px solid #694FA31A",
                td: {
                  border: "none",
                  padding: "16px 16px",
                  paddingRight: "0",
                  color: "#000000DE",
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                },
                ":last-of-type": { borderBottom: "none" },
              }}
            >
              <TableCell>{contact?.name}</TableCell>
              <TableCell>
                <Box
                  sx={{
                    maxWidth: "150px",
                    whiteSpace: "normal",
                  }}
                >
                  {contact?.title}
                </Box>
              </TableCell>
              <TableCell>{contact?.interactions}</TableCell>
              <TableCell>
                {contact?.last_active &&
                  moment(contact?.last_active).format("MMM DD, YYYY")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
const InteractionsByDepartmentsModal = ({
  total,
  open,
  handleClose,
  items,
}) => {
  const [collapseItems, setCollapseItems] = useState([]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "fit-content",
          minWidth: "830px",
          maxWidth: "1080px",
          maxHeight: "640px",
          overflow: "auto",
          background: "white",
          position: "absolute",
          marginTop: "35px",
          top: "0",
          left: "calc(50% - 415px)",
          borderRadius: "12px",
          padding: "24px",
        }}
      >
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          marginBottom={"4px"}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
          >
            Interactions by teams
          </Box>
          <IconButton padding={"8px"} onClick={handleClose}>
            <StyledIcon component={CloseRounded} color={"icon"} />
          </IconButton>
        </Stack>
        <Stack
          marginBottom={"24px"}
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"end"}
        >
          <Box color={(theme) => theme.palette.text.medium}>
            {total} teams total
          </Box>
        </Stack>
        <Box>
          <Stack flexDirection={"row"} gap={"13px"}>
            <Stack width={"100%"}>
              {items?.map((item) => (
                <Box width={"100%"} key={item?.department_name} marginTop={"4px"} marginBottom={"4px"}>
                  <TeamNameWithIcon sx={{ marginBottom: "-8px" }} teamName={item?.department_name} />
                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    width={"100%"}
                    height={"40px"}
                  >
                    <InteractionStageGraph item={item} />

                    <Stack
                      key={item?.department_name}
                      flexDirection={"row"}
                      alignItems={"center"}
                      gap={"8px"}
                      marginLeft={"16px"}
                    >
                      <Box sx={{ whiteSpace: "nowrap" }}>
                        {item?.total_interactions} interactions
                      </Box>
                      <IconButton
                        sx={{
                          padding: "8px",
                          transform:
                            collapseItems.includes(item.department_name) &&
                            "rotate(-180deg)",
                          transition: "transform 300ms",
                        }}
                        onClick={() =>
                          setCollapseItems((prevState) =>
                            collapseItems.includes(item.department_name)
                              ? prevState.filter((i) => i !== item.department_name)
                              : [...prevState, item.department_name]
                          )
                        }
                      >
                        <StyledIcon
                          component={KeyboardArrowDownRounded}
                          color={"icon"}
                        />
                      </IconButton>
                    </Stack>
                  </Stack>
                  {collapseItems.includes(item?.department_name) && (
                    <CollapseItem item={item} />
                  )}
                </Box>
              ))}
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default InteractionsByDepartmentsModal;
