import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { Check, HighlightAlt, NotInterested } from '@mui/icons-material';

export const labelButtonConfigs = [
  {
    value: 0,
    label: 'No',
    Icon: NotInterested,
    color: '#ef5350',
    backgroundColor: '#ffcccb',
    hoverBackgroundColor: '#ffcccb',
    hoverBorderColor: '#ef5350',
  },
  {
    value: 2,
    label: "Don't know",
    Icon: HighlightAlt,
    color: 'white',
    backgroundColor: '#999',
    hoverBackgroundColor: '#aaa',
    hoverBorderColor: '#666',
  },
  {
    value: 1,
    label: 'Yes',
    Icon: Check,
    color: 'white',
    backgroundColor: '#4caf50',
    hoverBackgroundColor: '#66bb6a',
    hoverBorderColor: '#4caf50',
  },
];

const ContractManualLabel = ({ contract, onSave }) => {
  const [editableLabel, setEditableLabel] = useState(
    contract.contract_type_manual,
  );

  useEffect(() => {
    setEditableLabel(contract.contract_type_manual);
  }, [contract]);

  useEffect(() => {
    if (editableLabel !== contract.contract_type_manual) {
      updateContract(editableLabel, contract.id).then((res) => {
        if (res !== 'error') {
          onSave(res);
        }
      });
    }
  }, [editableLabel]);

  const handleChange = (event, value) => {
    event.stopPropagation();
    setEditableLabel(value);
  };

  const updateContract = async (contract_type_manual, id) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/contracts/${id}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({ contract_type_manual }),
      },
    );

    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  return (
    <ButtonGroup variant="outlined" aria-label="outlined button group">
      {labelButtonConfigs.map(
        (
          {
            value,
            label,
            Icon,
            color,
            backgroundColor,
            hoverBackgroundColor,
            hoverBorderColor,
          },
          index,
        ) => (
          <Button
            key={label}
            onClick={(event) => handleChange(event, value)}
            sx={{
              border: '1px solid grey',
              color: editableLabel === value ? color : 'grey',
              background: editableLabel === value ? backgroundColor : 'white',
              '&:hover': {
                backgroundColor:
                  editableLabel === value ? hoverBackgroundColor : '#f0f0f0',
                color: editableLabel === value ? color : 'grey',
                borderColor:
                  editableLabel === value ? hoverBorderColor : 'grey',
              },
              borderRadius:
                index === 0
                  ? '10px 0 0 10px'
                  : index === labelButtonConfigs.length - 1
                  ? '0 10px 10px 0'
                  : 0,
            }}
          >
            <Icon sx={{ fontSize: 16 }} />
          </Button>
        ),
      )}
    </ButtonGroup>
  );
};

export default ContractManualLabel;
