import React, { useState, useRef } from 'react';
import { Box, Chip, InputLabel, ClickAwayListener } from '@mui/material';

const EmailListInput = ({ emails, setEmails, disabled, label, onEmailsChange }) => {
    const [inputValue, setInputValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [hoveredEmail, setHoveredEmail] = useState(null);
    const inputRef = useRef(null);

    const handleMouseEnter = (email) => {
        setHoveredEmail(email);
    };

    const handleMouseLeave = () => {
        setHoveredEmail(null);
    };

    const isValidEmail = email => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const updateEmails = (newEmails) => {
        setEmails(newEmails);
        if (onEmailsChange) {
            onEmailsChange(newEmails);
        }
    };

    const addEmail = () => {
        const newEmail = inputValue.trim();
        if (newEmail && isValidEmail(newEmail) && !emails.includes(newEmail)) {
            const newEmails = [...emails, newEmail];
            updateEmails(newEmails);
            setInputValue('');
        }
    };

    const handleDelete = (emailToDelete) => {
        const newEmails = emails.filter(email => email !== emailToDelete);
        updateEmails(newEmails);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleKeyDown = (event) => {
        if ((event.key === ' ' || event.key === 'Enter') && inputValue.trim()) {
            event.preventDefault();
            addEmail();
        }
    };

    const handleBlur = () => {
        setIsFocused(false);
        addEmail();
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleInputClick = () => {
        inputRef.current.focus();
    };

    return (
        <ClickAwayListener onClickAway={handleBlur}>
            <Box sx={{
                position: 'relative',
                border: '1px solid lightgrey',
                borderRadius: '10px',
                minHeight: '46px',
                padding: '5px',
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                ':focus-within': {
                    borderColor: (theme) => theme.palette.secondary.main,
                    borderWidth: '2px',
                }
            }}>
                <InputLabel
                    shrink={isFocused || emails.length > 0}
                    sx={{
                        position: 'absolute',
                        left: '12px',
                        top: '-10px',
                        background: '#FFF',
                        px: '5px',
                        display: 'block',
                        transform: isFocused || emails.length > 0 ? 'scale(0.75)' : 'scale(1)',
                        transformOrigin: 'top left',
                        transition: theme => theme.transitions.create(['top', 'transform'], {
                            duration: theme.transitions.duration.shorter
                        })
                    }}
                >
                    {isFocused || emails.length ? label : ''}
                </InputLabel>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    cursor: 'text'
                }} onClick={handleInputClick}>
                    {emails.map((email, index) => (
                        <Chip
                            key={index}
                            label={email}
                            onDelete={hoveredEmail === email ? () => handleDelete(email) : null}
                            onMouseEnter={() => handleMouseEnter(email)}
                            onMouseLeave={handleMouseLeave}
                            sx={{ margin: '5px' }}
                        />
                    ))}
                    <input
                        ref={inputRef}
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        placeholder={isFocused || emails.length ? '' : "Specific emails:"}
                        disabled={disabled}
                        style={{
                            border: 'none',
                            outline: 'none',
                            background: 'transparent',
                            padding: '0',
                            margin: '5px',
                            fontSize: '16px',
                            flex: 1,
                        }}
                    />
                </Box>
            </Box>
        </ClickAwayListener>
    );
};

export default EmailListInput;
