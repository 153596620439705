import React from "react";
import { Box, Chip, IconButton, Stack, Checkbox } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ArrowForwardRounded,
  CheckCircleRounded,
  MailOutlineRounded,
  PeopleOutline,
  Grading,
  People,
  NotInterested,
  Inventory2Outlined,
  HighlightAlt,
} from "@mui/icons-material";
import StyledIcon from "./StyledIcon";
import { stageVariant } from "./CustomChip";
import CustomCard from "./CustomCard";
import moment from "moment";
import Logo from "./Logo";
import { ReactComponent as ActiveContract } from './icons/activeContract.svg';

const BundleItem = ({ bundle, interaction, selected, onSelect, disabled }) => {
  const navigate = useNavigate();
  const location = useLocation();

  let mostActive = '';
  if (interaction) {
    const supplierContacts = interaction?.external_stakeholders?.slice() || [];
    supplierContacts.sort((a, b) => b?.num_emails_sent - a?.num_emails_sent);

    mostActive = [supplierContacts[0]?.name, supplierContacts[0]?.title]
      .filter(Boolean)
      .join(', ') || '';
  }
  else {
    const members = [];

    // TODO: api should be fixed
    /*if (bundle.internal_stakeholders && bundle.internal_stakeholders.length > 0) {
      members.push(bundle.internal_stakeholders[0]);
    }

    if (bundle.external_stakeholders && bundle.external_stakeholders.length > 0) {
      members.push(bundle.external_stakeholders[0]);
    }*/

    mostActive = members.join(', ');
  }

  const currentBundle = bundle || interaction?.bundle;

  const reviewStatus = currentBundle?.supplier_manual === null ? "New" : "Reviewed";

  const detailsLink = interaction?.id ? `/backoffice/details/interaction/${currentBundle.id}` : `/backoffice/details/messages/${currentBundle.id}`;

  return (
    <CustomCard
      isHover={true}
      onClick={() =>
        navigate(detailsLink, { state: { from: location } })
      }
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          padding: "16px",
          alignItems: "center",
          borderBottom: "1px solid #DEDBEC",
        }}
      >
        <Box
          sx={{
            width: "calc(100% / 3)",
            display: "flex",
            gap: "16px",
            alignItems: "center",
          }}
        >
          {disabled && <Inventory2Outlined titleAccess="Bundle" color={"icon"} />}
          {!disabled && <Checkbox checked={selected} disabled={disabled}
            onChange={() => onSelect()}
            onClick={(e) => e.stopPropagation()}
          />}
          <Logo size={40} img={interaction?.supplier_image_url} />
          <Box>
            <Box
              color={(theme) => theme.palette.text.main}
              sx={{
                lineHeight: "150%",
              }}
              fontSize={(theme) => theme.typography.subtitle1}
            >
              {interaction?.supplier_name ? interaction.supplier_name : currentBundle.external_domain}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {interaction?.uid && <Box
                color={(theme) => theme.palette.text.medium}
                fontSize={(theme) => theme.typography.caption}
              >
                ID: {interaction.uid}
              </Box>}
            </Box>
          </Box>
        </Box>
        <Stack
          width={"60%"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.body1}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: "330px",
            }}
          >
            <Stack width={"100%"} flexDirection={"row"} justifyContent={"space-between"}>
              {reviewStatus === "Reviewed" &&
                <Stack>
                  {currentBundle.supplier_manual === 1 &&
                    (<Stack flexDirection={"row"} alignItems={"center"} gap={"5px"}>
                      <StyledIcon component={People} color={"icon_warning"} />
                      <Box color={"#D0A52E"}>
                        Supplier deal
                      </Box>
                    </Stack>)
                  }
                  {currentBundle.supplier_manual === 0 &&
                    (<Stack flexDirection={"row"} alignItems={"center"} gap={"5px"}>
                      <StyledIcon component={NotInterested} color={"icon_error"} />
                      Not a supplier deal
                    </Stack>)
                  }
                  {currentBundle.supplier_manual === 2 &&
                    (<Stack flexDirection={"row"} alignItems={"center"} gap={"5px"}>
                      <StyledIcon component={HighlightAlt} color={"icon_neutral"} />
                      <Box color={"#666"}>
                        Don't know
                      </Box>
                    </Stack>)
                  }
                </Stack>
              }

              {interaction && interaction.status == "published" &&
                <Stack flexDirection={"row"} alignItems={"center"} gap={"5px"}>
                  <StyledIcon component={CheckCircleRounded} color={"icon_success"} />Published
                </Stack>}
            </Stack>
            {Boolean(interaction && interaction.has_contract) &&
              <Stack flexDirection={"row"} alignItems={"center"} gap={"5px"}>
                <StyledIcon component={ActiveContract} color={"icon_success"} />Has contract
              </Stack>}
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "end", width: "120px" }}
          >
            <IconButton
              type="button"
              sx={{ padding: "8px" }}
            >
              <StyledIcon component={ArrowForwardRounded} color={"icon"} />
            </IconButton>
          </Box>
        </Stack>
        <Box
          display="flex"
          color={(theme) => theme.palette.text.medium}
          fontSize={(theme) => theme.typography.caption}
          paddingTop={"16px"}
        >
          Organization: {currentBundle.organization} (id: {currentBundle.organization_id})
        </Box>
      </Box>
      <Box sx={{ padding: "16px", display: "flex" }}>
        <Box sx={{ width: "40%" }}>
          <Box
            color={(theme) => theme.palette.text.main}
            sx={{
              marginBottom: "8px",
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <StyledIcon component={MailOutlineRounded} color={"icon"} />
            {interaction?.num_emails ? (
              <Box>
                {interaction.num_emails} messages over {interaction.num_days} days
              </Box>
            ) : (
              <Box>
                {currentBundle.num_emails} messages
              </Box>
            )}
          </Box>
          <Box fontSize={(theme) => theme.typography.body2}>
            <Box
              component={"span"}
              color={(theme) => theme.palette.text.medium}
            >

            </Box>
            {interaction?.start_time && <Box component={"span"} color={(theme) => theme.palette.text.main}>
              {interaction.start_time && moment(interaction.start_time).format("MMM DD, YYYY")}
              &nbsp;&mdash;&nbsp;
              {interaction.end_time && moment(interaction.end_time).format("MMM DD, YYYY")}
            </Box>}
            {!interaction?.start_time && <Box component={"span"} color={(theme) => theme.palette.text.main}>
              {currentBundle.last_email_sent_at && moment(currentBundle.last_email_sent_at).format("MMM DD, YYYY")}
              &nbsp;&mdash;&nbsp;
              {currentBundle.updated_at && moment(currentBundle.updated_at).format("MMM DD, YYYY")}
            </Box>}
          </Box>
        </Box>

        <Box sx={{ width: "40%" }}>
          <Box
            color={(theme) => theme.palette.text.main}
            sx={{
              marginBottom: "8px",
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <StyledIcon component={PeopleOutline} color={"icon"} />
            <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
              {(currentBundle?.internal_stakeholders.length || 0) + (currentBundle?.external_stakeholders.length || 0)} people involved
            </Box>
          </Box>
          <Box
            color={(theme) => theme.palette.text.medium}
            fontSize={(theme) => theme.typography.body2}
          >
            Most active:{" "}
            <Box component={"span"} sx={{ mr: 4 }}>
              <Box
                component={"span"}
                color={(theme) => theme.palette.text.main}
              >
                {mostActive}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{
          width: "20%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            sx={{
              marginBottom: "8px",
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <StyledIcon component={Grading} color={"icon"} />
            <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
              Review status
            </Box>
          </Box>

          <Chip
            label={reviewStatus}
            size={"small"}
            sx={{
              ...stageVariant(reviewStatus),
            }}
          />
        </Box>
      </Box>
    </CustomCard>
  );
};

export default BundleItem;