import { CloseRounded, LockOutlined } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, IconButton, Link, Stack, Tab, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InteractionItem from '../../common/InteractionItem';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import Logo from '../../common/Logo';
import StyledIcon from '../../common/StyledIcon';
import DetailsNavigation from '../../common/DetailsNavigation';
import { ReactComponent as CloseFullscreen } from '../../common/icons/closeFullscreen.svg';
import { ReactComponent as Teams } from '../../common/icons/teams.svg';
import { appSlice } from '../../store/appSlice';
import { supplierResponseTime } from '../../utils/functions';
import InteractionEmployeeContacts from '../interactionDetails/InteractionEmployeeContacts';
import SupplierContracts from './SupplierContracts';
import SupplierDetailsContacts from './SupplierDetailsContacts';
import ShareLink from '../../common/ShareLink';
import Header from '../Header';
import SupplierBadges from '../../common/SupplierBadges';
import NoResultCard from '../../common/NoResultCard';
import SupplierDetailsCard from '../../common/SupplierDetailsCard';

const SupplierDetailsFullscreen = ({ close }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { previousSearch } = useSelector((state) => state.appSlice);

  const {
    addPreviousDetailsNavigation,
    setNextDetailsNavigation,
    setPage,
    setSearch,
    setPreviousSearch,
    toggleDetailsFullscreen,
  } = appSlice.actions;

  const [supplier, setSupplier] = useState();
  const [responseTime, setRepsonseTime] = useState();
  const [interactions, setInteractions] = useState([]);
  const [combinedInteraction, setCombinedInteraction] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [supplierDetailsTab, setSupplierDetailsTab] = useState('1');

  const handleDetailsTabChange = (_, newValue) => {
    setSupplierDetailsTab(newValue);
  };

  const handleDetailsNavigation = () => {
    dispatch(addPreviousDetailsNavigation(location.pathname));
    dispatch(setNextDetailsNavigation([]));
  };

  useEffect(() => {
    setIsLoading(true);
    getData().then((res) => {
      if (res !== 'error') {
        setSupplier(res);
        setRepsonseTime(supplierResponseTime(res));
        res.interactions?.map((interaction) => {
          getInteraction(interaction.id).then((res) => {
            if (res !== 'error')
              setInteractions([
                ...interactions,
                {
                  ...res,
                  internal_stakeholders: filterInternalStakeholders(res),
                },
              ]);
          });
        });
      }
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    setCombinedInteraction({ ...interactions[0] });
  }, [interactions]);

  const getData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/suppliers/${id}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const getInteraction = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/interactions/${id}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const allDepartmentsInvolved = supplier?.interactions
    ?.map((interaction) => interaction.departments_involved)
    .flat();
  const departmentsInvolved = [...new Set(allDepartmentsInvolved)].sort(
    (a, b) => a.localeCompare(b),
  );

  const filterInternalStakeholders = (res) => {
    return res.departments_involved
      .map((item) => ({
        type: item,
        items: res.internal_stakeholders.filter(
          (internal) => internal.department === item,
        ),
      }))
      .filter((item) => item.items.length !== 0);
  };

  const hasCategoryChips = () => {
    return Boolean(supplier?.categories && supplier.categories.length !== 0);
  };

  const hidePoweredBy = () => {
    return Boolean(
      !hasCategoryChips() &&
        !supplier.foundation_year &&
        !supplier.min_revenue_estimative &&
        !supplier.num_employees_rang &&
        !supplier.website &&
        !supplier.headquarters &&
        (!supplier.diversity_spotlights ||
          supplier.diversity_spotlights.length === 0),
    );
  };

  const interactionCount = supplier?.interactions?.length || 0;
  const resultText = interactionCount === 1 ? 'interaction' : 'interactions';

  const tabStyles = {
    textTransform: 'none',
    minHeight: 0,
    minWidth: 0,
    padding: 0,
    marginRight: '16px',
    paddingBottom: '10px',
    fontSize: '16px',
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.60)',
      '>.MuiTab-iconWrapper': {
        marginRight: 0,
      },
    },
  };

  const sectionStyles = {
    border: '1px solid #DEDBEC',
    borderRadius: '12px',
    padding: '16px',
  };

  const linkMouseover = { cursor: 'pointer' };

  return (
    <Box>
      {!supplier || isLoading ? (
        <LoadingSkeleton variant={'supplierDetailsFullscreen'} />
      ) : (
        <Box>
          <Box>
            <Header handleOnClick={close} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Box display={'flex'} flexDirection={'column'}>
              <Stack
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{
                  borderBottom: '1.5px solid #694FA31A',
                  padding: '16px 24px 16px 40px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                  }}
                >
                  <DetailsNavigation handleClose={close} />
                  <Box
                    color={(theme) => theme.palette.text.main}
                    fontSize={(theme) => theme.typography.subtitle1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <Link
                      color={(theme) => theme.palette.text.main}
                      fontSize={(theme) => theme.typography.subtitle1}
                      sx={{ cursor: 'pointer', fontWeight: '500' }}
                      underline="none"
                      onClick={() => {
                        dispatch(setPage('suppliers'));
                        window.scrollTo(0, 0);
                        close();
                        navigate('/suppliers');
                      }}
                    >
                      Suppliers
                    </Link>
                    <Box color={(theme) => theme.palette.text.secondary}>
                      {' '}
                      / {supplier.name}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '24px' }}>
                  <ShareLink />
                  <IconButton
                    type="button"
                    sx={{ padding: '0' }}
                    onClick={() => {
                      dispatch(setSearch(previousSearch));
                      dispatch(setPreviousSearch(''));
                      dispatch(toggleDetailsFullscreen());
                    }}
                  >
                    <StyledIcon component={CloseFullscreen} color={'icon'} />
                  </IconButton>
                  <IconButton
                    type="button"
                    sx={{ padding: '8px' }}
                    onClick={close}
                  >
                    <CloseRounded sx={{ color: '#2F236D73', fontSize: 24 }} />
                  </IconButton>
                </Box>
              </Stack>
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'10px'}
                padding={'16px 24px 16px 40px'}
              >
                <Stack flexDirection={'row'} gap={'16px'} alignItems={'center'}>
                  <Logo size={44} img={supplier.image_url} />

                  <Box
                    color={(theme) => theme.palette.text.black}
                    sx={{
                      lineHeight: '150%',
                    }}
                    fontSize={(theme) => theme.typography.h6}
                  >
                    {supplier.name}
                  </Box>
                </Stack>
                {supplier.short_description && (
                  <Box
                    fontSize={(theme) => theme.typography.body1}
                    color={(theme) => theme.palette.text.caption}
                  >
                    {supplier.short_description}
                  </Box>
                )}
                <Box
                  color={(theme) => theme.palette.text.main}
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                    {supplier?.categories?.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          border: '1px solid #0288D180',
                          borderRadius: '100px',
                          padding: '3px 10px',
                          fontSize: '13px',
                          color: '#0288D1',
                        }}
                      >
                        {item}
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box padding={'6px 0'}>
                  <SupplierBadges supplier={supplier} />
                </Box>
                <TabContext value={supplierDetailsTab}>
                  <Box
                    sx={{
                      marginBottom: '6px',
                      borderBottom: 0,
                      borderBottom: '1px solid rgba(101, 88, 177, 0.1)',
                    }}
                  >
                    <TabList
                      onChange={handleDetailsTabChange}
                      textColor="secondary"
                      indicatorColor="secondary"
                      aria-label="supplier details tabs"
                      sx={{ minHeight: '0' }}
                    >
                      <Tab sx={tabStyles} label="Summary" value="1" />
                      <Tab sx={tabStyles} label="People" value="2" />
                      <Tab sx={tabStyles} label="Interactions" value="3" />
                      <Tab sx={tabStyles} label="Contracts" value="4" />
                      <Tab
                        sx={tabStyles}
                        icon={<LockOutlined color={'icon'} />}
                        iconPosition="start"
                        label="Alternatives"
                        disabled
                      />
                      <Tab
                        sx={tabStyles}
                        icon={<LockOutlined color={'icon'} />}
                        iconPosition="start"
                        label="Structure & spend"
                        disabled
                      />
                    </TabList>
                  </Box>
                  <TabPanel sx={{ padding: 0 }} value="1">
                    <Box
                      display={'flex'}
                      gap={'16px'}
                      paddingBottom={'32px'}
                      color={(theme) => theme.palette.text.main}
                    >
                      <Stack width={'66%'} gap={'16px'}>
                        {(!Boolean(hidePoweredBy()) ||
                          supplier.annotated_perplexity) && (
                          <SupplierDetailsCard supplier={supplier} />
                        )}
                        {Boolean(interactions.length) && (
                          <SupplierDetailsContacts
                            supplier={supplier}
                            responseTime={responseTime}
                            miniView={() => {
                              handleDetailsTabChange('', '2');
                            }}
                          />
                        )}
                        {Boolean(interactions.length) && (
                          <Box>
                            <Box sx={{ paddingBottom: '16px' }}>
                              <Typography variant="h6">Interactions</Typography>
                            </Box>
                            <Box
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  gap: '24px',
                                  width: '100%',
                                }}
                              >
                                {supplier.interactions
                                  ?.slice(0, 2)
                                  .map((item) => (
                                    <Box
                                      width={'100%'}
                                      key={item.id}
                                      onClick={() => handleDetailsNavigation()}
                                    >
                                      <InteractionItem
                                        interaction={item}
                                        variant={'DetailsFullscreen'}
                                      />
                                    </Box>
                                  ))}
                                <Link
                                  sx={linkMouseover}
                                  underline="hover"
                                  fontSize={(theme) =>
                                    theme.typography.subtitle2
                                  }
                                  color="secondary"
                                  onClick={() => {
                                    handleDetailsTabChange('', '3');
                                  }}
                                >
                                  See all interactions
                                </Link>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Stack>
                      <Stack width={'33%'} gap={'16px'}>
                        {Boolean(interactions.length) && (
                          <Box sx={sectionStyles} gap={'24px'}>
                            <Box sx={{ paddingBottom: '16px' }}>
                              <Typography variant="h6">Teams</Typography>
                            </Box>
                            <Box
                              display={'flex'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                            >
                              <Stack flexDirection={'row'} gap={'4px'}>
                                <Teams />
                                <Box
                                  fontSize={(theme) => theme.typography.body2}
                                >
                                  {departmentsInvolved.join(', ')}
                                </Box>
                              </Stack>
                              <Link
                                sx={linkMouseover}
                                color="secondary"
                                underline="hover"
                                fontSize={(theme) => theme.typography.subtitle2}
                                onClick={() => {
                                  handleDetailsTabChange('', '2');
                                }}
                              >
                                See all people
                              </Link>
                            </Box>
                          </Box>
                        )}
                        <Box sx={sectionStyles} gap={'24px'}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              paddingBottom: '16px',
                            }}
                          >
                            <Typography variant="h6">Alternatives</Typography>
                            <LockOutlined color="icon" />
                          </Box>
                          <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            <img
                              src="/comingSoonBlur.png"
                              width="100%"
                              height="100%"
                            />
                          </Box>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            gap={'2px'}
                          >
                            <Typography variant="body1" color="caption">
                              Coming soon.
                            </Typography>
                            <Link
                              fontSize={(theme) => theme.typography.body2}
                              color="secondary"
                              underline="hover"
                              href="mailto:support@partnerelement.com"
                            >
                              Contact us.
                            </Link>
                          </Box>
                        </Box>
                      </Stack>
                    </Box>
                  </TabPanel>
                  <TabPanel sx={{ padding: 0 }} value="2">
                    {Boolean(interactions.length) ? (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'66%'}
                      >
                        {combinedInteraction?.id && (
                          <InteractionEmployeeContacts
                            interaction={combinedInteraction}
                          />
                        )}
                        <SupplierDetailsContacts
                          supplier={supplier}
                          responseTime={responseTime}
                        />
                      </Box>
                    ) : (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'66%'}
                      >
                        <NoResultCard variant="supplier-people" />
                      </Box>
                    )}
                  </TabPanel>
                  <TabPanel sx={{ padding: 0 }} value="3">
                    <Box width={'66%'}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '16px',
                        }}
                      >
                        {interactionCount ? (
                          <Box
                            fontSize={(theme) => theme.typography.body1}
                            color={(theme) => theme.palette.text.caption}
                          >
                            {interactionCount} {resultText} found.
                          </Box>
                        ) : (
                          <NoResultCard variant="supplier-interactions" />
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '24px',
                        }}
                      >
                        {supplier.interactions?.map((item) => (
                          <Box
                            width={'100%'}
                            key={item.id}
                            onClick={() => handleDetailsNavigation()}
                          >
                            <InteractionItem
                              interaction={item}
                              variant={'DetailsFullscreen'}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </TabPanel>
                  <TabPanel sx={{ padding: 0 }} value="4">
                    <Box width={'66%'}>
                      <SupplierContracts supplierId={supplier.id} />
                    </Box>
                  </TabPanel>
                </TabContext>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SupplierDetailsFullscreen;
