import React, { useEffect, useState } from "react";
import { Box, Stack, Button } from "@mui/material";
import CustomCard from "../../common/CustomCard";
import StyledIcon from "../../common/StyledIcon";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import AutomaticOutreach from "./Automatic";
import ManualOutreach from "./Manual";
import { ForwardToInboxOutlined } from "@mui/icons-material";
import { ReactComponent as Integrations } from "../../common/icons/integration.svg";

const Outreach = () => {
    const {
        user,
    } = useSelector((state) => state.appSlice);

    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.title = 'PartnerElement | Outreach';
      }, []);

    const outreachPages = [
        { text: "Automatic", path: "/outreach", icon: Integrations },
        { text: "Manual", path: "/outreach/manual", icon: ForwardToInboxOutlined },
    ];

    const renderMenuItem = (item, index) => {
        return (
            <Button
                key={index}
                onClick={() => {
                    setSelectedPage(item.text);
                    navigate(item.path);
                }}
                color="secondary"
                variant="text"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    justifyContent: "start",
                    background: selectedPage === item.text ? "#694FA30A" : "transparent",
                    textTransform: "none",
                    borderRadius: "10px",
                    padding: "10px 16px",
                    ml: item.isChild ? 4 : 0,
                    fontSize: theme.typography.subtitle2,
                }}
            >
                {item?.icon &&
                    <StyledIcon component={item.icon} opacity={selectedPage === item.text ? 1 : 0.45} color={selectedPage === item.text ? "secondary" : "icon"} />
                }
                {item.text}
            </Button>
        );
    };

    const initialTab = outreachPages.find(item => item.path == location.pathname);

    const [selectedPage, setSelectedPage] = useState(initialTab?.text || "Automatic");

    const viewPage = (props) => {
        return (
            <Routes>
                <Route path="/*" element={<AutomaticOutreach {...props} />} />
                <Route path="manual/*" element={<ManualOutreach {...props} />} />
            </Routes>
        );
    };

    return (
        <Stack
            flexDirection={"row"}
            gap={"24px"}
            sx={{
                background: "#FDFDFD",
                minHeight: "calc(100vh - 68px)",
                padding: "24px",
            }}
        >
            <Box
                sx={{
                    width: "fit-content",
                    height: "fit-content",
                    minWidth: "288px",
                    maxWidth: "288px",
                }}
            >
                <CustomCard>
                    <Box sx={{ padding: "16px" }}>
                        <Stack flexDirection={"column"} gap={"24px"} marginBottom={"24px"}>
                            <Stack flexDirection="column" gap="4px">
                                {outreachPages.map(renderMenuItem)}
                            </Stack>
                        </Stack>
                    </Box>
                </CustomCard>
            </Box>

            <>
                {viewPage({ role: user && user.roles[0], user })}
            </>
        </Stack>
    );
};

export default Outreach;
