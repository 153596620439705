import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  Popover,
  Stack,
  TextField,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

import StyledIcon from "../../common/StyledIcon";
import TimeLine from "../../common/TImeLine";

const formatSavingsAmount = (value) => {
  // Remove any non-numeric characters except dot
  let numericValue = value.replace(/[^0-9.]/g, "");

  // Format the numeric value as a savings amount
  const parts = numericValue.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  numericValue = parts.join(".");

  return numericValue;
};

const EditDealClosedModal = ({
  open,
  handleClose,
  updateInteraction,
  interaction,
}) => {
  const amountRef = useRef(null);
  const [isOpenProcurement, setIsOpenProcurement] = useState(false);
  const [isOpenDealClose, setIsOpenDealClose] = useState(false);
  const [timeProcurement, setTimeProcurement] = useState(
    moment(interaction?.procurement_involved_at)
  );
  const [timeDealClosed, setTimeDealClosed] = useState(
    moment(interaction?.deal_closed_at)
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      percentage: `${interaction?.savings_percentage}`.toLocaleString("en-US"),
      amount: formatSavingsAmount(`${interaction?.savings_amount}`),
      notes: interaction?.deal_closure_notes,
    },
  });

  const handleAmountChange = (event) => {
    const input = event.target;
    const previousCursorPosition = input.selectionStart;
    const formattedValue = formatSavingsAmount(input.value);
    const hasCommaAdded = formattedValue.length > input.value.length;

    input.value = formattedValue;

    // Restore cursor position
    let cursorPositionOffset = formattedValue.length - input.value.length;
    if (hasCommaAdded) {
      cursorPositionOffset += 1;
    }
    input.setSelectionRange(
      previousCursorPosition + cursorPositionOffset,
      previousCursorPosition + cursorPositionOffset
    );
  };

  useEffect(() => {
    setTimeProcurement(moment(interaction?.procurement_involved_at));
    setTimeDealClosed(moment(interaction?.deal_closed_at));
    setValue(
      "percentage",
      `${interaction?.savings_percentage}`.toLocaleString("en-US")
    );
    setValue("amount", formatSavingsAmount(`${interaction?.savings_amount}`));
    setValue("notes", interaction?.deal_closure_notes);
  }, [interaction]);

  const onSubmit = (data) => {
    handleClose();
    updateInteraction(
      {
        procurement_involved_at: interaction.procurement_involved
          ? new Date(timeProcurement.hour(12)) // setting time to noon to avoid timezone issues
          : null,
        deal_closed_at: new Date(timeDealClosed.hour(12)), // setting time to noon to avoid timezone issues
        deal_closure_notes: data.notes,
        savings_amount: parseFloat(data.amount.replace(/,/g, "")),
        savings_percentage: parseFloat(data.percentage.replace(/,/g, "")),
      },
      interaction?.id
    ).catch();
  };

  if (!interaction) {
    return <></>;
  }

  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorPosition={{
        left: window.innerWidth / 2,
        top: 24,
      }}
      PaperProps={{
        sx: {
          width: "676px",
          padding: "24px",
          borderRadius: "12px",
          maxHeight: "844px",
          "@media (max-height: 844px)": {
            maxHeight: "700px",
          },
          "@media (max-height: 700px)": {
            maxHeight: "500px",
          },
          "@media (max-height: 500px)": {
            maxHeight: "300px",
          },
        },
      }}
      anchorReference={"anchorPosition"}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      <Box>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"start"}
          marginBottom={"24px"}
        >
          <Box
            fontSize={(theme) => theme.typography.h6}
            color={(theme) => theme.palette.text.main}
          >
            Closed deal information
          </Box>
          <IconButton onClick={handleClose} sx={{ padding: 0 }}>
            <StyledIcon component={CloseRounded} color={"icon"} />
          </IconButton>
        </Stack>
        <Box
          sx={{
            height: "1.5px",
            width: "100%",
            background: "#BCB8C5",
            opacity: "0.2",
            marginBottom: "24px",
          }}
        />
        <Box marginBottom={"24px"}>
          <TimeLine
            interaction={interaction}
            timeDealClosed={timeDealClosed}
            isOpenDealClose={isOpenDealClose}
            setIsOpenDealClose={setIsOpenDealClose}
            setTimeDealClosed={setTimeDealClosed}
            isOpenProcurement={isOpenProcurement}
            setIsOpenProcurement={setIsOpenProcurement}
            setTimeProcurement={setTimeProcurement}
            timeProcurement={timeProcurement}
          />
        </Box>
        <Box
          sx={{
            height: "1.5px",
            width: "100%",
            background: "#BCB8C5",
            opacity: "0.2",
            marginBottom: "24px",
          }}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row" gap={2} marginBottom={3}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel
                htmlFor="amount"
                color={errors?.amount?.message ? "input_error" : "secondary"}
              >
                Savings amount
              </InputLabel>
              <OutlinedInput
                {...register("amount", {
                  required: true,
                  validate: (value) => {
                    const numericValue = parseFloat(
                      value.replace(/[^0-9.]/g, "")
                    );
                    return (
                      numericValue <= 1000000000 ||
                      "Cannot be bigger than 1000000000 (1B)"
                    );
                  },
                  pattern: {
                    value: /^(?!,)[0-9]+([,.][0-9]{3})*(\.[0-9]+)?$/,
                    message: "Invalid format",
                  },
                })}
                id="amount"
                placeholder="Enter savings amount"
                label={"Savings amount"}
                color={errors?.amount?.message ? "input_error" : "secondary"}
                sx={{ borderRadius: "10px" }}
                onChange={handleAmountChange}
                inputRef={amountRef}
              />
              {errors?.amount?.message && (
                <FormHelperText sx={{ color: "#EF5350" }}>
                  {errors?.amount?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel
                htmlFor="percentage"
                color={
                  errors?.percentage?.message ? "input_error" : "secondary"
                }
              >
                Saving percentage
              </InputLabel>
              <OutlinedInput
                {...register("percentage", {
                  required: true,
                  min: {
                    value: 0,
                    message: "Cannot be bigger than 100 or less than 0",
                  },
                  max: {
                    value: 100,
                    message: "Cannot be bigger than 100 or less than 0",
                  },
                  pattern: {
                    value: /^(?!,)[0-9]+(,[0-9]{3})*(\.[0-9]+)?$/,
                    message: "Invalid format",
                  },
                })}
                id="percentage"
                placeholder="Enter savings percentage share"
                label={"Saving percentage"}
                color={
                  errors?.percentage?.message ? "input_error" : "secondary"
                }
                sx={{ borderRadius: "10px" }}
              />
              {errors?.percentage?.message && (
                <FormHelperText sx={{ color: "#EF5350" }}>
                  {errors?.percentage?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>
          <FormControl sx={{ width: "100%", marginBottom: "24px" }}>
            <TextField
              sx={{ fieldset: { borderRadius: "10px" } }}
              minRows={6}
              multiline
              id="text"
              label="Notes"
              placeholder="How did you calculate the savings?"
              color="secondary"
              {...register("notes")}
            />
          </FormControl>
          <Stack direction={"row"} justifyContent={"end"}>
            <Button
              type={"submit"}
              color={"secondary"}
              variant={"contained"}
              sx={{ textTransform: "none", borderRadius: "10px" }}
            >
              Submit
            </Button>
          </Stack>
        </form>
      </Box>
    </Popover>
  );
};

export default EditDealClosedModal;
