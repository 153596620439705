import React from 'react';
import {
    Box,
    Modal,
    IconButton,
    Stack,
    Typography,
    Avatar,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import InteractionStageGraph from './InteractionStageGraph';
import StyledIcon from '../../common/StyledIcon';
import { getInitials } from '../../utils/functions';

const InteractionsByMembersModal = ({ open, handleClose, people }) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    width: 'fit-content',
                    minWidth: '830px',
                    maxWidth: '1080px',
                    maxHeight: '640px',
                    overflow: 'auto',
                    background: 'white',
                    position: 'absolute',
                    marginTop: '35px',
                    top: '0',
                    left: 'calc(50% - 415px)',
                    borderRadius: '12px',
                    padding: '24px',
                }}
            >
                <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} marginBottom={"4px"}>
                    <Typography variant="h6">Interactions by procurement members</Typography>
                    <IconButton onClick={handleClose}>
                        <StyledIcon component={CloseRounded} color={"icon"} />
                    </IconButton>
                </Stack>
                <Box>
                    {people.map((person, index) => (
                        <Box key={index} marginBottom={"4px"}>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={"8px"}>
                                <Avatar sx={{ bgcolor: '#e3e1ea', color: '#514689', marginRight: '4px', fontSize: "13px", width: "24px", height: "24px" }}>
                                    {getInitials(person.name)}
                                </Avatar>
                                <Typography variant="body1">{person.name}</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} alignItems={"center"}>
                                <InteractionStageGraph item={person.stages} />
                                <Typography marginLeft={"24px"} sx={{ whiteSpace: "nowrap" }}>
                                    {person.stages.total_interactions} interactions
                                </Typography>
                            </Stack>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Modal>
    );
};

export default InteractionsByMembersModal;
