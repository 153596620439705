import React, { useState, useEffect } from 'react';
import { appSlice } from '../../store/appSlice';
import { useDispatch } from 'react-redux';
import { Box, Stack } from '@mui/material';
import CustomCard from '../../common/CustomCard';
import { useSelector } from 'react-redux';
import CustomChip from '../../common/CustomChip';
import Interactions from './Interactions';
import OrganizationDropdown from './OrganizationDropdown';
import SupplierDropdown from './SupplierDropdown';
import { updateFiltersBundles } from '../../store/localStorage';
import {
  FormControl,
  Select,
  InputBase,
  InputLabel,
  IconButton,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { CloseRounded, SearchRounded } from '@mui/icons-material';
import { contractTypes, defaultFiltersBundles } from '../../utils/constants';
import ClearFiltersButton from '../../common/ClearFilters';
import StyledIcon from '../../common/StyledIcon';
import moment from 'moment';
import useDebounce from '../../hooks/useDebounce';
import Contracts from './Contracts/Contracts';
import { labelButtonConfigs } from './Contracts/ContractManualLabel';

const reviewStatus = [
  { label: 'New', value: 'new' },
  { label: 'Reviewed', value: 'reviewed' },
];

const supplierDeal = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'Unknown', value: 'unknown' },
];

const Backoffice = () => {
  const dispatch = useDispatch();
  const { filtersBundles, user } = useSelector((state) => state.appSlice);

  const { setFiltersBundles } = appSlice.actions;

  const [filters, setFilters] = useState(defaultFiltersBundles);
  const [searchQuery, setSearchQuery] = useState(
    defaultFiltersBundles.search_query,
  );
  const [lastSync, setLastSync] = useState(null);
  const [isInteraction, setIsInteraction] = useState(true);
  const [tab, setTab] = useState('interactions');

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    setFilters(filtersBundles);
    setSearchQuery(filtersBundles.search_query);
  }, [filtersBundles]);

  useEffect(() => {
    if (filtersBundles.search_query !== debouncedSearchQuery) {
      handleFilters({
        ...filtersBundles,
        search_query: debouncedSearchQuery,
      });
    }
  }, [debouncedSearchQuery]);

  const handleFilters = (newFilters) => {
    updateFiltersBundles(newFilters);
    dispatch(setFiltersBundles(newFilters));
  };

  const handleOrgChange = (value) => {
    setLastSync(value?.updated_at || null);

    handleFilters({
      ...filters,
      organizationId: value?.id,
    });
  };

  const handleSupplierChange = (value) => {
    setLastSync(value?.updated_at || null);

    handleFilters({
      ...filters,
      supplierId: value?.id,
    });
  };

  const handleTabChange = (_, value) => {
    setIsInteraction(value === 'interactions');
    setTab(value);
  };

  const backofficePage = (props) => {
    if (props.tab === 'contracts') return <Contracts {...props} />;
    return <Interactions {...props} />;
  };

  return (
    <Stack
      flexDirection={'row'}
      gap={'24px'}
      sx={{
        background: '#FDFDFD',
        minHeight: 'calc(100vh - 68px)',
        padding: '24px',
      }}
    >
      <Box
        sx={{
          width: 'fit-content',
          height: 'fit-content',
          minWidth: '288px',
          maxWidth: '288px',
        }}
      >
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Stack flexDirection={'column'} gap={'24px'} marginBottom={'24px'}>
              <Stack flexDirection={'row'} alignItems={'center'} gap={'8px'}>
                <Box
                  color={(theme) => theme.palette.text.main}
                  fontSize={(theme) => theme.typography.h6}
                >
                  Filters
                </Box>
              </Stack>

              <Box>
                <OrganizationDropdown
                  value={filters.organizationId}
                  onChange={(value) => handleOrgChange(value)}
                />
              </Box>

              <Box
                sx={{
                  width: '100%',
                  height: '1.5px',
                  background: '#694FA380',
                  opacity: '0.2',
                }}
              />
            </Stack>

            {tab !== 'contracts' && (
              <>
                <Stack
                  direction={'row'}
                  bgcolor={'#F5F5F5'}
                  alignItems={'center'}
                  borderRadius={'28px'}
                  padding={'5px 20px'}
                  height={'34px'}
                >
                  <InputBase
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value)}
                    sx={{
                      flex: 1,
                      background: '#F5F5F5',
                    }}
                    placeholder="Search"
                  />
                  {filtersBundles.search_query && (
                    <IconButton
                      type="button"
                      sx={{ padding: '0', marginRight: '4px' }}
                      onClick={() => {
                        setSearchQuery('');
                      }}
                    >
                      <StyledIcon component={CloseRounded} color="icon" />
                    </IconButton>
                  )}
                  <IconButton type="button" sx={{ padding: '0' }}>
                    <StyledIcon component={SearchRounded} color="icon" />
                  </IconButton>
                </Stack>

                <Box marginTop={'20px'}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel htmlFor="timeframe" color={'secondary'}>
                      Include messages from:
                    </InputLabel>
                    <Select
                      sx={{ borderRadius: '10px', height: '40px' }}
                      id={'timeframe'}
                      color={'secondary'}
                      label={'Include messages from:'}
                      value={filters.timeframe}
                      onChange={(event) =>
                        handleFilters({
                          ...filters,
                          timeframe: event.target.value,
                        })
                      }
                    >
                      <MenuItem value={7}>Last 7 days</MenuItem>
                      <MenuItem value={30}>Last 30 days</MenuItem>
                      <MenuItem value={60}>Last 60 days</MenuItem>
                      <MenuItem value={90}>Last 90 days</MenuItem>
                      <MenuItem value={180}>Last 180 days</MenuItem>
                      <MenuItem value={365}>Last 365 days</MenuItem>
                      <MenuItem value={'alltime'}>All time</MenuItem>
                    </Select>
                  </FormControl>

                  {lastSync && (
                    <Box
                      fontSize={(theme) => theme.typography.body2}
                      color={(theme) => theme.palette.text.medium}
                      marginTop={'8px'}
                    >
                      Last sync {moment(lastSync).format('MMMM DD, YYYY')}
                    </Box>
                  )}
                </Box>
              </>
            )}
            {tab !== 'contracts' && (
              <>
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                  marginTop={'20px'}
                  marginBottom={'8px'}
                >
                  Review status
                </Box>
                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                  {reviewStatus.map((item) => (
                    <CustomChip
                      key={item.label}
                      label={item.label}
                      isActive={filters.reviewStatus.includes(item.value)}
                      onClick={() => {
                        filters.reviewStatus.includes(item.value)
                          ? handleFilters({
                              ...filters,
                              reviewStatus: filters.reviewStatus.filter(
                                (prevItem) => prevItem !== item.value,
                              ),
                            })
                          : handleFilters({
                              ...filters,
                              reviewStatus: [
                                ...filters.reviewStatus,
                                item.value,
                              ],
                            });
                      }}
                    />
                  ))}
                </Box>

                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                  marginTop={'20px'}
                  marginBottom={'8px'}
                >
                  Supplier deal
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    flexWrap: 'wrap',
                    marginBottom: '20px',
                  }}
                >
                  {supplierDeal.map((item) => (
                    <CustomChip
                      key={item.label}
                      label={item.label}
                      isActive={filters.supplierDeal.includes(item.value)}
                      onClick={() => {
                        filters.supplierDeal.includes(item.value)
                          ? handleFilters({
                              ...filters,
                              supplierDeal: filters.supplierDeal.filter(
                                (prevItem) => prevItem !== item.value,
                              ),
                            })
                          : handleFilters({
                              ...filters,
                              supplierDeal: [
                                ...filters.supplierDeal,
                                item.value,
                              ],
                            });
                      }}
                    />
                  ))}
                </Box>
              </>
            )}

            {isInteraction && (
              <Box>
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                >
                  Published
                </Box>
                <Box marginBottom={'16px'}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={filters.published}
                      onChange={(event) =>
                        handleFilters({
                          ...filters,
                          published: event.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value={'published'}
                        control={<Radio color={'secondary'} />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={'unpublished'}
                        control={<Radio color={'secondary'} />}
                        label="No"
                      />{' '}
                      <FormControlLabel
                        value={'unknown'}
                        control={<Radio color={'secondary'} />}
                        label="Both"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            )}

            {isInteraction && (
              <Box>
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                >
                  Has contract
                </Box>
                <Box marginBottom={'16px'}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={filters.hasContract || ''}
                      onChange={(event) =>
                        handleFilters({
                          ...filters,
                          hasContract: event.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value={'yes'}
                        control={<Radio color={'secondary'} />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={'no'}
                        control={<Radio color={'secondary'} />}
                        label="No"
                      />{' '}
                      <FormControlLabel
                        value={''}
                        control={<Radio color={'secondary'} />}
                        label="Both"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            )}

            {tab === 'contracts' && (
              <>
                <Box>
                  <SupplierDropdown
                    value={filters.supplierId}
                    onChange={(value) => handleSupplierChange(value)}
                  />
                </Box>
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                  marginTop={'16px'}
                >
                  Contract Tags
                </Box>
                <Box marginBottom={'16px'}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={filters.contractTypesTagged || ''}
                      onChange={(event) =>
                        handleFilters({
                          ...filters,
                          contractTypesTagged: event.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value={'true'}
                        control={<Radio color={'secondary'} />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={'false'}
                        control={<Radio color={'secondary'} />}
                        label="No"
                      />{' '}
                      <FormControlLabel
                        value={''}
                        control={<Radio color={'secondary'} />}
                        label="Both"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                  marginTop={'20px'}
                  marginBottom={'8px'}
                >
                  Contract type
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    flexWrap: 'wrap',
                    marginBottom: '20px',
                  }}
                >
                  {contractTypes.map((item) => (
                    <CustomChip
                      key={item.label}
                      label={item.label}
                      isActive={filters.contractTypes?.includes(item.value)}
                      onClick={() => {
                        filters.contractTypes?.includes(item.value)
                          ? handleFilters({
                              ...filters,
                              contractTypes: filters.contractTypes.filter(
                                (prevItem) => prevItem !== item.value,
                              ),
                            })
                          : handleFilters({
                              ...filters,
                              contractTypes: [
                                ...(filters?.contractTypes || []),
                                item.value,
                              ],
                            });
                      }}
                    />
                  ))}
                </Box>
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                  marginTop={'20px'}
                  marginBottom={'8px'}
                >
                  Labeled
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    flexWrap: 'wrap',
                    marginBottom: '20px',
                  }}
                >
                  <CustomChip
                    label={'Unlabeled'}
                    isActive={filters.contractTypeManual?.includes(-1)}
                    onClick={() => {
                      filters.contractTypeManual?.includes(-1)
                        ? handleFilters({
                            ...filters,
                            contractTypeManual:
                              filters.contractTypeManual.filter(
                                (prevItem) => prevItem !== -1,
                              ),
                          })
                        : handleFilters({
                            ...filters,
                            contractTypeManual: [
                              ...(filters?.contractTypeManual || []),
                              -1,
                            ],
                          });
                    }}
                  />
                  {labelButtonConfigs.map((item) => (
                    <CustomChip
                      key={item.label}
                      label={item.label}
                      isActive={filters.contractTypeManual?.includes(
                        item.value,
                      )}
                      onClick={() => {
                        filters.contractTypeManual?.includes(item.value)
                          ? handleFilters({
                              ...filters,
                              contractTypeManual:
                                filters.contractTypeManual.filter(
                                  (prevItem) => prevItem !== item.value,
                                ),
                            })
                          : handleFilters({
                              ...filters,
                              contractTypeManual: [
                                ...(filters?.contractTypeManual || []),
                                item.value,
                              ],
                            });
                      }}
                    />
                  ))}
                </Box>
              </>
            )}

            <Box>
              <ClearFiltersButton
                type={'big'}
                variant={'filtersBundles'}
              ></ClearFiltersButton>
            </Box>
          </Box>
        </CustomCard>
      </Box>

      <>
        {backofficePage({
          role: user && user.roles[0],
          user,
          tab,
          onTabChange: handleTabChange,
        })}
      </>
    </Stack>
  );
};

export default Backoffice;
