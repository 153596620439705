import React, { useState } from "react";
import { Box, Button, IconButton, Stack, InputBase } from "@mui/material";
import CustomCard from "../../common/CustomCard";
import StyledIcon from "../../common/StyledIcon";
import {
    ArrowBackRounded,
    SettingsOutlined,
    SupervisorAccountOutlined,
    SearchRounded
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Route, Routes, useNavigate } from "react-router-dom";
import FAQ from "./Faq";
import Glossary from "./Glossary";

const Help = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.appSlice);

    const [selectedSettings, setSelectedSettings] = useState("FAQ");

    const selectedSettingsArray = [
        { text: "FAQ", path: "/help", icon: SettingsOutlined },
        {
            text: "Glossary",
            path: "/help/glossary",
            icon: SupervisorAccountOutlined,
        },
    ];

    const viewPage = (props) => {
        return (
            <Routes>
                <Route path="/" element={<FAQ {...props} />} />
                <Route path="/glossary" element={<Glossary {...props} />} />
            </Routes>
        );
    };

    return (
        <Stack
            flexDirection={"row"}
            gap={"24px"}
            sx={{
                background: "#FDFDFD",
                minHeight: "calc(100vh - 68px)",
                padding: "24px",
            }}
        >
            <Box
                sx={{
                    width: "fit-content",
                    height: "fit-content",
                    minWidth: "288px",
                    maxWidth: "288px",
                }}
            >
                <CustomCard>
                    <Box sx={{ padding: "16px" }}>
                        <Stack flexDirection={"column"} gap={"24px"} marginBottom={"24px"}>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={"8px"}>
                                <IconButton
                                    type="button"
                                    sx={{ padding: "0" }}
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                >
                                    <StyledIcon component={ArrowBackRounded} color={"icon"} />
                                </IconButton>
                                <Box
                                    color={(theme) => theme.palette.text.main}
                                    fontSize={(theme) => theme.typography.h6}
                                >
                                    Help
                                </Box>
                            </Stack>


                            <Box
                                sx={{
                                    width: "100%",
                                    height: "1.5px",
                                    background: "#694FA380",
                                    opacity: "0.2",
                                }}
                            />
                        </Stack>
                        <Stack flexDirection={"column"} gap={"4px"}>
                            {selectedSettingsArray.map((item) => (
                                <Button
                                    onClick={() => {
                                        setSelectedSettings(item.text);
                                        navigate(`${item.path}`);
                                    }}
                                    key={item.text}
                                    color={"secondary"}
                                    variant={"text"}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "4px",
                                        justifyContent: "start",
                                        background: selectedSettings === item.text && "#694FA30A",
                                        textTransform: "none",
                                        borderRadius: "10px",
                                        padding: "10px 16px",
                                    }}
                                >
                                    <StyledIcon
                                        component={item.icon}
                                        color={
                                            selectedSettings === item.text ? "secondary" : "icon"
                                        }
                                    />
                                    <Box
                                        color={(theme) =>
                                            selectedSettings === item.text
                                                ? theme.palette.secondary
                                                : theme.palette.text.main
                                        }
                                        fontSize={(theme) => theme.typography.subtitle2}
                                        component={"span"}
                                    >
                                        {item.text}
                                    </Box>
                                </Button>
                            ))}
                        </Stack>
                        <Box
                            sx={{
                                width: "100%",
                                height: "1.5px",
                                background: "#694FA380",
                                opacity: "0.2",
                                marginY: "24px",
                            }}
                        />

                        <Box
                            fontSize={(theme) => theme.typography.subtitle2}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                            }}
                        >
                            <Box
                                component={"a"}
                                href={"mailto:support@partnerelement.com?subject=Assistance"}
                                sx={{
                                    color: "secondary.main",
                                    textDecoration: "none",
                                    cursor: "pointer",
                                }}
                            >
                                Need assistance?
                            </Box>
                            <Box
                                component={"a"}
                                href={"https://partnerelement.com/privacy"}
                                sx={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    ":hover": { textDecoration: "underline" },
                                }}
                                color={(theme) => theme.palette.text.main}
                            >
                                Privacy policy
                            </Box>
                            <Box
                                component={"a"}
                                href={"https://partnerelement.com/security"}
                                sx={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    ":hover": { textDecoration: "underline" },
                                }}
                                color={(theme) => theme.palette.text.main}
                                >
                                Security policy
                            </Box>
                            <Box
                                component={"a"}
                                href={"https://partnerelement.com/terms"}
                                sx={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    ":hover": { textDecoration: "underline" },
                                }}
                                color={(theme) => theme.palette.text.main}
                            >
                                Terms & Conditions
                            </Box>
                        </Box>
                    </Box>
                </CustomCard>
            </Box>
            {viewPage({ role: user && user.roles[0], user })}
        </Stack>
    );
};

export default Help;
