import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Chip } from "@mui/material";
import React from "react";
import { stageNames } from "../utils/constants";
import StyledIcon from "./StyledIcon";
export const stageVariant = (engagementStage) => {
  switch (engagementStage) {
    case stageNames[0].label:
      return { background: "#C9F7D5", color: "#1B5E20" };
    case stageNames[1].label:
      return { background: "#FFE7B3", color: "#765A00" };
    case stageNames[2].label:
      return { background: "#FFDBD2", color: "#B32A00" };
    case 'New':
      return { background: "#ef5350", color: "#fff", fontWeight: 'bold' };
    case 'Reviewed':
      return { background: "#4caf50", color: "#fff", fontWeight: 'bold' };
    default:
      return { color: "#2F236D73" };
  }
};
const CustomChip = ({ isActive, label, onClick }) => {
  return (
    <Chip
      label={label}
      variant={isActive ? "filled" : "outlined"}
      clickable={true}
      onClick={onClick}
      icon={
        isActive ? (
          <StyledIcon
            component={CheckRoundedIcon}
            sx={{ color: "#000000", fontSize: 18 }}
          />
        ) : (
          <></>
        )
      }
      sx={{
        transition: "all 150ms",
        border: "1px solid #0000001F",
        background: isActive && "#E8DEF8",
        ":active": {
          background: "#1D192B1F",
        },
        ":hover": {
          background: "#E8DEF8",
          boxShadow:
            isActive &&
            "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
        },
      }}
    />
  );
};

export default CustomChip;
