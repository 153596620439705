import React from 'react';
import { Box } from '@mui/material';
import CustomChip from './CustomChip';

const DealValueRange = ({ breakpoints, selectedRanges, onRangeSelect }) => {
    const formatNumber = (num) => num.toLocaleString('en-US');

    const parseNumber = (formattedStr) => parseInt(formattedStr.replace(/,/g, ''), 10);

    let rangeStrings = ['Undetected', `< $${formatNumber(breakpoints[0])}`];

    breakpoints.forEach((breakpoint, index, array) => {
        if (index < array.length - 1) {
            rangeStrings.push(`$${formatNumber(breakpoint)} - $${formatNumber(array[index + 1])}`);
        } else {
            rangeStrings.push(`≥ $${formatNumber(breakpoint)}`);
        }
    });

    const handleChipClick = (label) => {
        const rangeValue = getRangeValue(label);
        const isSelected = selectedRanges.includes(rangeValue);
        onRangeSelect(isSelected ? selectedRanges.filter(item => item !== rangeValue) : [...selectedRanges, rangeValue]);
    };

    const getRangeValue = (label) => {
        if (label === 'Undetected') {
            return 'null';
        }
        if (label.startsWith('< $')) {
            return `,${parseNumber(label.slice(3))}`;
        } else if (label.startsWith('≥ $')) {
            return `${parseNumber(label.slice(3))},`;
        } else {
            const parts = label.split(' - ').map(part => parseNumber(part.slice(1)));
            return `${parts[0]},${parts[1]}`;
        }
    };

    const isLabelSelected = (label) => {
        const rangeString = getRangeValue(label);
        return selectedRanges.includes(rangeString);
    };

    return (
        <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
            {rangeStrings.map((label, index) => (
                <CustomChip
                    key={index}
                    label={label}
                    variant="outlined"
                    onClick={() => handleChipClick(label)}
                    isActive={isLabelSelected(label)}
                />
            ))}
        </Box>
    );
};

export default DealValueRange;
