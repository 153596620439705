import React, { forwardRef } from 'react';
import { Box, Chip, Tooltip } from '@mui/material';
import moment from 'moment';

const BundleMessage = forwardRef(function ({ message }, ref) {
    const processMessageBody = (body, keywords) => {
        if (message.inbound) {
            const regex = new RegExp(`(${Object.keys(keywords).join('|')})`, 'gi');
            const bodyParts = body.split(regex);

            return bodyParts.map((part, index) => {
                if (keywords[part.toLowerCase()]) {
                    const strength = keywords[part.toLowerCase()];
                    return (
                        <Tooltip key={index} title={`Strength: ${strength}`} arrow>
                            <span style={{ backgroundColor: '#FFFF00' }}>{part}</span>
                        </Tooltip>
                    );
                }
                return part;
            });
        } else {
            return body;
        }
    };

    const flatKeywords = Object.entries(message.keywords).reduce((acc, [category, { strength, scan_results }]) => {
        Object.keys(scan_results).forEach(keyword => {
            acc[keyword.toLowerCase()] = strength;
        });
        return acc;
    }, {});

    const processedBody = processMessageBody(message.body, flatKeywords);

    return (
        <Box ref={ref} key={message.id} paddingBottom={"10px"} border={"1px solid lightgray"} padding={"10px"} sx={{ borderRadius: '8px' }}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={"12px"}>
                    <Chip
                        label={message.inbound ? 'Inbound' : 'Outbound'}
                        size={"small"}
                        sx={{
                            background: message.inbound ? "#C9F7D5" : "#FFE7B3",
                            color: message.inbound ? "#1B5E20" : "#765A00",
                            fontWeight: 'bold'
                        }}
                    />
                    <Box color={"#999"}>
                        {message?.subject}
                    </Box>
                </Box>
                <Box>
                    {moment(message.sent_at).format("MMM DD, YYYY")}
                </Box>
            </Box>
            <Box sx={{ paddingTop: "20px", whiteSpace: "pre-wrap" }}>
                {processedBody}
            </Box>
        </Box>
    );
});

export default BundleMessage;
