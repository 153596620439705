import React from "react";

import { Box, IconButton, Modal, Stack } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

import StyledIcon from "../../common/StyledIcon";
import Logo from "../../common/Logo";

import { calculateInteractionsForStats } from "../../utils/functions";

const SupplierModal = ({ total, open, handleClose, items }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "fit-content",
          minWidth: "720px",
          maxWidth: "1080px",
          background: "white",
          position: "absolute",
          marginTop: "35px",
          top: "0",
          left: "calc(50% - 360px)",
          borderRadius: "12px",
          padding: "24px",
        }}
      >
        <Box sx={{ maxHeight: "600px", overflow: "auto" }}>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            marginBottom={"4px"}
          >
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.h6}
            >
              Most active suppliers
            </Box>
            <IconButton padding={"8px"} onClick={handleClose}>
              <StyledIcon component={CloseRounded} color={"icon"} />
            </IconButton>
          </Stack>
          <Box
            marginBottom={"24px"}
            sx={{ width: "100%" }}
            color={(theme) => theme.palette.text.medium}
          >
            {total} suppliers total
          </Box>
          <Box>
            <Stack width={"100%"}>
              {items?.map((item, index) => (
                <Stack
                  key={index}
                  width={"calc(100% - 32px)"}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{
                    borderTop: "1.5px solid rgba(105, 79, 163, 0.2)",
                    padding: "10px 16px",
                  }}
                >
                  <Stack direction={"row"} alignItems={"center"} gap={"16px"}>
                    <Logo
                      size={28}
                      img={item.supplier_image_url}
                      iconSize={20}
                    />
                    <Box
                      color={(theme) => theme.palette.text.main}
                      fontSize={(theme) => theme.typography.body2}
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {item?.supplier_name}
                    </Box>
                  </Stack>
                  <Box>
                    <Box
                      color={(theme) => theme.palette.text.main}
                      fontSize={(theme) => theme.typography.body2}
                      sx={{ whiteSpace: "nowrap", marginBottom: "2px" }}
                    >
                      {item?.num_emails} messages
                    </Box>
                    <Box
                      color={(theme) => theme.palette.text.medium}
                      fontSize={(theme) => theme.typography.caption}
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {calculateInteractionsForStats(item)} interactions
                    </Box>
                  </Box>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SupplierModal;
