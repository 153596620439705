import React from 'react';
import {
  Box,
  Grid,
  Paper,
  Switch,
  Typography,
  Stack,
  Chip,
} from '@mui/material';
import StyledIcon from '../../common/StyledIcon';
import { Visibility } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const EngagementOption = ({
  title,
  description,
  imageUrl,
  isActive,
  onToggle,
  onClick,
}) => {
  const handleToggleClick = (event) => {
    event.stopPropagation();
    onToggle();
  };

  return (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 2,
        borderRadius: 6,
        border: '1px solid #DEDBEC',
        boxShadow: 'none',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <img
        src={imageUrl}
        alt={title}
        style={{ width: '183px', margin: '16px 0' }}
      />
      <Box width={'100%'}>
        <Typography
          sx={{
            marginBottom: '8px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '1',
          }}
          fontSize={(theme) => theme.typography.subtitle1}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            marginBottom: '16px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '2',
          }}
          color={(theme) => theme.palette.text.medium}
          fontSize={(theme) => theme.typography.body2}
          minHeight={'41px'}
        >
          {description}
        </Typography>
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <StyledIcon component={Visibility} color="icon" />
          </Box>
          <Switch
            checked={isActive}
            color={'secondary'}
            onClick={handleToggleClick}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              '& .MuiSwitch-track': {
                backgroundColor: isActive ? 'secondary' : 'grey.300',
              },
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: 'secondary',
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                opacity: 0.5,
              },
              marginRight: '-10px',
            }}
          />
        </Stack>
      </Box>
    </Paper>
  );
};

const EngagementGrid = ({
  configs,
  onToggleOption,
  onOpenDialog,
  onCloseDialog,
  currentOption,
  open,
}) => {
  const handleDialogToggle = () => {
    const index = configs.findIndex(
      (option) => option?.id === currentOption?.id,
    );
    if (index !== -1) {
      onToggleOption(index);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {configs.map((option, index) => (
          <Grid item xs={12} sm={6} md={4} key={`${option.title}-${index}`}>
            <EngagementOption
              title={option.label}
              description={option.description || 'No description'}
              imageUrl={option.image_url || '/engagements/en1.png'}
              isActive={option.active}
              onToggle={() => onToggleOption(index)}
              onClick={() => onOpenDialog(option)}
            />
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={open}
        onClose={onCloseDialog}
        fullWidth
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '676px',
              maxWidth: '100%',
            },
          },
          '& a, a:visited': {
            color: '#0095E9DE',
          },
        }}
      >
        <DialogTitle>
          {currentOption?.label}
          <Switch
            checked={currentOption?.active || false}
            color={'secondary'}
            onChange={handleDialogToggle}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              '& .MuiSwitch-track': {
                backgroundColor: true ? 'secondary' : 'grey.300',
              },
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: 'secondary',
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                opacity: 0.5,
              },
            }}
          />

          <IconButton
            aria-label="close"
            onClick={onCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <Typography
              component="div"
              fontSize={(theme) => theme.typography.body1}
              fontWeight={'500'}
              color={'black'}
              marginBottom={'4px'}
            >
              When these conditions met
            </Typography>

            <Box
              display={'flex'}
              gap={'10px'}
              flexWrap={'wrap'}
              marginBottom={'12px'}
            >
              {(currentOption?.condition_labels || []).map((chip, index) => (
                <Chip
                  size={'small'}
                  key={`cc${index}`}
                  variant={'filled'}
                  sx={{
                    span: { padding: 0 },
                    padding: '4px 10px',
                    color: '#000000DE',
                    fontSize: '13px',
                  }}
                  label={chip}
                />
              ))}
            </Box>

            <Typography
              component="div"
              fontSize={(theme) => theme.typography.body1}
              fontWeight={'500'}
              color={'black'}
              marginBottom={'4px'}
            >
              Notification preview
            </Typography>

            <Box
              sx={{
                margin: '-36px',
                paddingTop: '6px',
                paddingBottom: '16px',
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: currentOption?.ui_preview_html || '',
                }}
              ></div>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EngagementGrid;
