import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { Edit as EditIcon, Close as CloseIcon } from '@mui/icons-material';
import { contractTypes } from '../../../utils/constants';

const ContractTypeEditor = ({ contract, onSave }) => {
  const [editableType, setEditableType] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setEditableType(formatTagInput());
  }, [contract]);

  useEffect(() => {
    if (!isEditing && isUpdating) {
      setIsUpdating(false);
      updateContract(editableType, contract.id).then((res) => {
        if (res !== 'error') {
          onSave(res);
        }
      });
    }
  }, [isEditing]);

  const toggleIsEditing = (event) => {
    event?.stopPropagation();
    setIsEditing(!isEditing);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setEditableType(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleSave = (event) => {
    setIsUpdating(true);
    toggleIsEditing(event);
  };

  const handleCancel = () => {
    setEditableType(formatTagInput());
    toggleIsEditing();
  };

  const formatTagInput = () => {
    if (contract.contract_type_tags_manual.length)
      return contract.contract_type_tags_manual;
    else if (contract.contract_type_tags.length)
      return contract.contract_type_tags;
    return [contract.contract_type];
  };

  const updateContract = async (contract_type_tags_manual, id) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/contracts/${id}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({ contract_type_tags_manual }),
      },
    );

    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {isEditing ? (
        <Box
          sx={{
            display: 'flex',
            border: '1px solid #c4c4c4',
            borderRadius: '10px',
            alignItems: 'center',
            gap: '8px',
            padding: '2px',
            paddingLeft: '4px',
            width: '100%',
          }}
          onClick={(event) => event.stopPropagation()}
        >
          <FormControl fullWidth>
            <InputLabel>Contract Types</InputLabel>
            <Select
              value={editableType}
              label="Contract Types"
              onChange={handleChange}
              type="text"
              size="small"
              variant="standard"
              multiple
              renderValue={(selected) => selected.join(', ')}
              InputProps={{
                disableUnderline: true,
              }}
              sx={{ fontSize: '16px', flex: 1 }}
              inputProps={{
                style: { padding: 0 },
              }}
            >
              {contractTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  <Checkbox checked={editableType.indexOf(type.value) > -1} />
                  <ListItemText primary={type.value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="text"
            color="secondary"
            onClick={handleSave}
            sx={{
              padding: '6px 16px',
              fontSize: '0.875rem',
              textTransform: 'none',
            }}
          >
            Save
          </Button>
          <IconButton size="small" onClick={handleCancel}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Box component={'span'}>{editableType?.join(', ')}</Box>
          <IconButton
            variant="text"
            size="small"
            color="secondary"
            sx={{ fontSize: '14px', fontFamily: 'Roboto' }}
            onClick={toggleIsEditing}
          >
            <EditIcon fontSize="small" />
            Edit type
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ContractTypeEditor;
